import { useQuery } from '~/components'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from '~/components'
import COMMENTS_QUERY_USERS from '~/components/comments/query-organization-users.gql'
import { OrganizationUsersQuery, TasksLatestQuery, TasksLatestQueryVariables, TaskTarget } from '~/models'
import LATEST_TASKS_QUERY from '~/tasks/query-tasks-latest.gql'
import { useSites } from './use-sites'
import { useTimeline } from './use-timeline'
import { useViewState } from './view-state'
import { Config } from '~/config'

export type LatestTask = TasksLatestQuery['tasksLatest'][0]

export function useTasks(
  view: ReturnType<typeof useViewState>,
  sites: ReturnType<typeof useSites>,
  timeline: ReturnType<typeof useTimeline>
) {
  const [visible, setVisible] = useState<boolean>(false)
  const [targetType, setTargetType] = useState<TaskTarget>(TaskTarget.Org)
  const [targetID, setTargetID] = useState<string | undefined>()
  const location = useLocation()
  const [allTasks, setAllTasks] = useState<LatestTask[]>()

  const tasksQuery = useQuery<TasksLatestQuery, TasksLatestQueryVariables>(LATEST_TASKS_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-and-network',
  })
  const loadUsersQuery = useQuery<OrganizationUsersQuery>(COMMENTS_QUERY_USERS, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (!view.showingTasks) {
      if (visible) {
        setVisible(false)
      }
      return
    }

    if (!visible) {
      setVisible(true)
    }

    if (!view.siteID) {
      setTargetType(TaskTarget.Org)
      setTargetID('')
    } else if (view.assetID) {
      setTargetType(TaskTarget.Asset)
      setTargetID(view.assetID)
    } else if (view.siteID) {
      setTargetType(TaskTarget.Site)
      setTargetID(view.siteID)
    }
  }, [view.showingTasks, view.siteID, view.assetID])

  useEffect(() => {
    if(!Config.UseTasks) {
      return
    }

    loadUsersQuery.refetch()

    if (Array.isArray(allTasks)) {
      return
    }

    tasksQuery.refetch().then((res)=>{
      if(res.data?.tasksLatest) {
        setAllTasks([...res.data.tasksLatest])
      }
    })
  }, [])

  const tasks = useMemo(() => {
    if (!allTasks) {
      return []
    }

    const tasksToUse = allTasks.sort((a, b) => {
      if (!a.lastScheduled || !b.lastScheduled) {
        return a.currentBlueprint.createdAt > b.currentBlueprint.createdAt ? -1 : 1
      }

      if (a.lastScheduled.dueBy === b.lastScheduled.dueBy) {
        if (a.currentBlueprint.createdAt === b.currentBlueprint.createdAt) {
          return a.currentBlueprint.name > b.currentBlueprint.name ? 1 : -1
        }

        return a.currentBlueprint.createdAt > b.currentBlueprint.createdAt ? -1 : 1
      }
      return a.lastScheduled.dueBy > b.lastScheduled.dueBy ? 1 : -1
    })

    if (view.assetID) {
      return tasksToUse.filter(
        (x) =>
          x.currentBlueprint.target === TaskTarget.Asset &&
          x.currentBlueprint.assignments.findIndex((y) => y.targetID === view.assetID) !== -1
      )
    } else if (view.siteID) {
      // const site = sites?.data?.sites.find((s) => s.id === view.siteID)
      //  || (site && site.assetIDs.findIndex((a) => a.id === y.targetID) !== -1)
    
      return tasksToUse.filter(
        (x) =>
          x.currentBlueprint.assignments.findIndex(
            (y) => y.targetID === view.siteID
          ) !== -1
      )
    }
    return tasksToUse
  }, [allTasks, view])

  return {
    visible,
    adding: view.taskID !== undefined && view.taskID === 'add',
    editing: view.taskID !== undefined && view.taskOperation === 'update',
    targetType,
    targetID,
    tasks: tasks,
    currentTask: tasks.find((t) => t.id === view.taskID),
    users: loadUsersQuery?.data?.organizationUsers || [],
    loaded: !tasksQuery.loading && !loadUsersQuery.loading,
    reload: tasksQuery.refetch,
    toggle: () => {
      if (!view.siteID) {
        if (view.showingTasks) {
          location.setLocation(`/`)
        } else {
          location.setLocation(`/tasks`)
        }
      } else if (view.assetID) {
        if (!view.showingTasks) {
          location.setLocation(`/${view.siteID}/${view.surveyID}/assets/${view.assetID}/tasks`)
        } else if (view.taskID) {
          location.setLocation(`/${view.siteID}/${view.surveyID}/assets/${view.assetID}`)
        } 
      } else {
        if (!view.showingTasks) {
          location.setLocation(`/${view.siteID}/${view.surveyID}/tasks`)
        } else if (view.taskID || view.showingTasks) {
          location.setLocation(`/${view.siteID}/${view.surveyID}`)
        }
      }
    },
    closeDetailed: () => {
      if (!view.siteID) {
        location.setLocation(`/tasks`)
      } else if (view.assetID) {
        location.setLocation(`/${view.siteID}/${view.surveyID}/assets/${view.assetID}/tasks`)
      } else {
        location.setLocation(`/${view.siteID}/${view.surveyID}/tasks`)
      }
    },
    goTo: (id: string) => {
      if (!view.siteID) {
        location.setLocation(`/tasks/${id}`)
      } else if (view.assetID) {
        location.setLocation(`/${view.siteID}/${view.surveyID}/assets/${view.assetID}/tasks/${id}`)
      } else {
        location.setLocation(`/${view.siteID}/${view.surveyID}/tasks/${id}`)
      }
    },
    goToAdd: () => {
      if (!view.siteID) {
        location.setLocation(`/tasks/add`)
      } else if (view.assetID) {
        location.setLocation(`/${view.siteID}/${view.surveyID}/assets/${view.assetID}/tasks/add`)
      } else {
        location.setLocation(`/${view.siteID}/${view.surveyID}/tasks/add`)
      }
    },
    goToUpdate: (id: string) => {
      if (!view.siteID) {
        location.setLocation(`/tasks/${id}/update`)
      } else if (view.assetID) {
        location.setLocation(`/${view.siteID}/${view.surveyID}/assets/${view.assetID}/tasks/${id}/update`)
      } else {
        location.setLocation(`/${view.siteID}/${view.surveyID}/tasks/${id}/update`)
      }
    },
    goToTasks: () => {
      if (!view.siteID) {
        location.setLocation(`/tasks`)
      } else if (view.assetID) {
        location.setLocation(`/${view.siteID}/${view.surveyID}/assets/${view.assetID}/tasks`)
      } else {
        location.setLocation(`/${view.siteID}/${view.surveyID}/tasks`)
      }
    },
  }
}
