import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useAppState } from '~/state'
import { SiteQueryImageTimelineItem, SiteQueryObservation, SiteQuerySatelliteTimelineItem, SiteQuerySurvey } from '~/models'
import { classes } from '../helpers'
import { TimelineContent } from './timeline-content'
import { TimelineDates } from './timeline-dates'
import { getDateFromClick } from './utils'

export enum TimelineTooltipType {
  Survey,
  Images,
}

interface SurveyItemTooltipProps {
  survey: SiteQuerySurvey
  months: Dayjs[]
}

const PADDING_OFFSET = 20

const SurveyItemTooltip = (props: SurveyItemTooltipProps) => {
  const start = dayjs(props.survey.startDate / 1000 / 1000)
  const end = dayjs(props.survey.endDate / 1000 / 1000)

  let title = start.format('DD MMMM YYYY')
  if (props.survey.startDate !== props.survey.endDate) {
    title += ' - ' + end.format('DD MMMM YYYY')
  }

  const reconsExist = props.survey.reconstructions.length !== 0
  const orthoComplete = props.survey.reconstructions.findIndex((r) => r.ortho?.state !== 'Processed') === -1

  return (
    <div className='timeline-content-survey-tooltip'>
      <div className='timeline-content-survey-tooltip-title'>Survey</div>
      <ul className='timeline-content-survey-tooltip-points'>
        <li>{title}</li>
        {reconsExist && <li>3D Reconstruction: {reconsExist ? 'Complete' : 'None'}</li>}
        {reconsExist && (
          <li>2D Reconstruction: {reconsExist ? (orthoComplete ? 'Complete' : 'Processing') : 'None'}</li>
        )}
      </ul>
    </div>
  )
}

interface ImageItemTooltipProps {
  image: SiteQueryImageTimelineItem
}

const ImageItemTooltip = (props: ImageItemTooltipProps) => {
  const start = dayjs(props.image.day)
  const title = start.format('DD MMMM YYYY')
  return (
    <li>
      {title}: {props.image.numImages}
    </li>
  )
}

interface ObservationtemTooltipProps {
  observation: SiteQueryObservation
}

const ObservationtemTooltip = (props: ObservationtemTooltipProps) => {
  // const start = dayjs(props.observation.date / 1000000)
  // const title = start.format('DD MMMM YYYY')
  return (
    <li>
      {props.observation.insights.length} insight{props.observation.insights.length === 1 ? '' : 's'}
    </li>
  )
}

export const Timeline = () => {
  const { timeline, drawer, imageTagger } = useAppState()
  const [adjustmentX, setAdjustmentX] = useState<number>(0)
  const [leftMouseDown, setLeftMouseDown] = useState<boolean>(false)
  const [leftMouseStartPosition, setLeftMouseStartPosition] = useState<number>(0)
  const [surveyItems, setSurveyItems] = useState<SiteQuerySurvey[]>([])
  const [imageItems, setImageItems] = useState<SiteQueryImageTimelineItem[]>([])
  const [satelliteItems, setSatelliteItems] = useState<SiteQuerySatelliteTimelineItem[]>([])
  const [observationItems, setObservationItems] = useState<SiteQueryObservation[]>([])

  // useEffect(() => {
  //   function mouseMove(e: MouseEvent) {
  //     if (!leftMouseDown) {
  //       return
  //     }
  //     e.preventDefault()

  //     const deltaX = e.screenX - leftMouseStartPosition
  //     const totalAdjustment = adjustmentX + deltaX
  //     if (totalAdjustment > 0) {
  //       if (adjustmentX !== 0) {
  //         setAdjustmentX(0)
  //       }
  //     } else {
  //       const elem = document.getElementById('timeline-content-entries')
  //       const width = elem.offsetWidth
  //       const timelineWidth = timeline.months.length * timeline.entrySize
  //       const threshold = timelineWidth < width ? 0 : -timelineWidth + width
  //       if (totalAdjustment < threshold) {
  //         if (adjustmentX !== threshold) {
  //           setAdjustmentX(threshold)
  //         }
  //       } else {
  //         setAdjustmentX(totalAdjustment)
  //       }
  //     }
  //   }

  //   function mouseUp(e: MouseEvent) {
  //     if (e.which !== 1) {
  //       return
  //     }

  //     setLeftMouseDown(false)
  //   }

  //   function keyPressed(e: KeyboardEvent) {
  //     const tlElement = document.getElementsByClassName('timeline')[0]
  //     if(!tlElement) {
  //       return
  //     }

  //     const hovered = tlElement.parentElement.querySelector(':hover') === tlElement
  //     if(!hovered) {
  //       return
  //     }
  //     if (e.key === '+') {
  //       timeline.setEntrySize(Math.min(timeline.entrySize + 20, 500))
  //     } else if (e.key === '-') {
  //       timeline.setEntrySize(Math.max(timeline.entrySize - 20, 60))
  //     }
  //   }

  //   window.addEventListener('mousemove', mouseMove)
  //   window.addEventListener('mouseup', mouseUp)
  //   window.addEventListener('keydown', keyPressed)

  //   return () => {
  //     window.removeEventListener('mousemove', mouseMove)
  //     window.removeEventListener('mouseup', mouseUp)
  //     window.removeEventListener('keydown', keyPressed)
  //   }
  // }, [leftMouseDown, timeline.entrySize])

  useEffect(() => {
    if (timeline.visible) {
      const elem = document.getElementById('timeline-content-entries')
      const width = elem.offsetWidth
      const timelineWidth = timeline.months.length * timeline.entrySize
      const threshold = timelineWidth < width ? 0 : -timelineWidth + width
      setAdjustmentX(threshold)
    } else {
      setAdjustmentX(0)
    }
  }, [timeline.visible])

  if (!timeline.visible) {
    return <></>
  }

  function showTooltip(e: React.MouseEvent) {
    const positionElem = document.getElementById('timeline-content-hover-date')
    const tooltipElem = document.getElementById('timeline-content-hover-date-tooltip')
    const tooltipDateElem = document.getElementById('timeline-content-hover-date-tooltip-date')
    const rect = e.currentTarget.getBoundingClientRect()
    const x = e.clientX - rect.left
    const date = getDateFromClick(timeline.months, e, x - adjustmentX - PADDING_OFFSET, timeline.entrySize)

    if (date) {
      positionElem.style.left = x - adjustmentX - PADDING_OFFSET + 'px'
      positionElem.style.display = 'inline-block'
      tooltipDateElem.innerText = date.format('DD MMMM YYYY')
      const left = e.pageX - tooltipElem.offsetWidth / 2
      tooltipElem.style.display = 'inline-block'

      if (left < 260) {
        tooltipElem.style.left = '260px'
      } else if (left > document.body.clientWidth - 350) {
        tooltipElem.style.left = document.body.clientWidth - 350 + 'px'
      } else {
        tooltipElem.style.left = left + 'px'
      }
    } else {
      positionElem.style.display = 'none'
      tooltipElem.style.display = 'none '
    }
  }

  function hideTooltip() {
    const positionElem = document.getElementById('timeline-content-hover-date')
    const tooltipElem = document.getElementById('timeline-content-hover-date-tooltip')
    positionElem.style.display = 'none'
    tooltipElem.style.display = 'none'

    setImageItems([])
    setSurveyItems([])
  }

  const haveTooltipItems = surveyItems.length > 0 || imageItems.length > 0

  return (
    <div
      className={classes({
        timeline: true,
        'left-expanded': drawer.leftExpanded,
        'film-strip-expanded': imageTagger.filmStripOpen,
        'right-expanded': drawer.rightExpanded,
      })}
    >
      <div
        className={classes({
          'timeline-container': true,
          hovered: leftMouseDown,
        })}
      >
        <div
          className={classes({
            'timeline-container-content': true,
            hovered: leftMouseDown,
          })}
          onMouseMove={(e) => {
            if (leftMouseDown) {
              hideTooltip()
              return
            }

            showTooltip(e)
          }}
          onClick={(e) => {
            if (e.isDefaultPrevented() || Math.abs(e.clientX - leftMouseStartPosition) > 10) {
              return
            }
            const rect = e.currentTarget.getBoundingClientRect()
            const x = e.clientX - rect.left
            const date = getDateFromClick(timeline.months, e, x - adjustmentX - PADDING_OFFSET, timeline.entrySize)
            if (date) {
              timeline.setActiveDate(date)
            }
          }}
          onMouseDown={(e) => {
            if (e.nativeEvent.which !== 1) {
              return
            }
            setLeftMouseDown(true)
            setLeftMouseStartPosition(e.clientX)
          }}
          onMouseUp={(e) => {
            if (e.nativeEvent.which !== 1) {
              return
            }
            showTooltip(e)
          }}
        >
          <TimelineDates months={timeline.months} adjustmentX={adjustmentX} leftMouseDown={leftMouseDown} />
          <TimelineContent
            months={timeline.months}
            adjustmentX={adjustmentX}
            surveys={timeline.surveys}
            observations={timeline.observations}
            imageTimeline={timeline.imageTimeline}
            satelliteTimeline={timeline.satelliteTimeline}
            activeImages={timeline.activeImages || []}
            activeSurvey={timeline.activeSurvey}
            addSurveyTooltip={(item: SiteQuerySurvey) => {
              if (surveyItems.findIndex((s) => s.id === item.id) === -1) {
                setSurveyItems([...surveyItems, item])
              }
            }}
            removeSurveyTooltip={(item: SiteQuerySurvey) => {
              setSurveyItems([...surveyItems.filter((i) => i.id !== item.id)])
            }}
            addImageTooltip={(item: SiteQueryImageTimelineItem) => {
              const exists = imageItems.findIndex((x) => x.day === item.day) !== -1
              if (!exists) {
                setImageItems([...imageItems, item])
              }
            }}
            removeImageTooltip={(item: SiteQueryImageTimelineItem) => {
              setImageItems([...imageItems.filter((i) => i.day !== item.day)])
            }}
            addSatelliteTooltip={(item: SiteQuerySatelliteTimelineItem) => {
              const exists = satelliteItems.findIndex((x) => x.satelliteID === item.satelliteID) !== -1
              if (!exists) {
                setSatelliteItems([...satelliteItems, item])
              }
            }}
            removeSatelliteTooltip={(item: SiteQuerySatelliteTimelineItem) => {
              setSatelliteItems([...satelliteItems.filter((i) => i.satelliteID !== item.satelliteID)])
            }}
            addObservationTooltip={(item: SiteQueryObservation) => {
              const exists = observationItems.findIndex((x) => x.id === item.id) !== -1
              if (!exists) {
                setObservationItems([...observationItems, item])
              }
            }}
            removeObservationTooltip={(item: SiteQueryObservation) => {
              setObservationItems([...observationItems.filter((i) => i.id !== item.id)])
            }}
          />
        </div>
        <div id='timeline-content-hover-date-tooltip' className='timeline-content-hover-date-tooltip'>
          <div
            id='timeline-content-hover-date-tooltip-date'
            className={classes({
              'timeline-content-hover-date-tooltip-date': true,
              'proper-title': haveTooltipItems,
            })}
          ></div>
          {imageItems.length > 0 && (
            <div className='timeline-content-image-upload-tooltip'>
              <div className='timeline-content-image-upload-tooltip-title'>Images</div>
              <ul className='timeline-content-image-upload-tooltip-points'>
                {...imageItems.map((i) => <ImageItemTooltip key={i.day} image={i} />)}
              </ul>
            </div>
          )}
          {observationItems.length > 0 && (
            <div className='timeline-content-image-upload-tooltip'>
              <div className='timeline-content-image-upload-tooltip-title'>Observation</div>
              <ul className='timeline-content-image-upload-tooltip-points'>
                {...observationItems.map((i) => <ObservationtemTooltip key={i.date} observation={i} />)}
              </ul>
            </div>
          )}
          {/* {satelliteItems.length > 0 && (
            <div className='timeline-content-image-upload-tooltip'>
              <div className='timeline-content-image-upload-tooltip-title'>Satellite</div>
              <ul className='timeline-content-image-upload-tooltip-points'>
                {...satelliteItems.map((i) => <SatelliteItemTooltip key={i.satelliteID} image={i} />)}
              </ul>
            </div>
          )} */}
          {surveyItems.length > 0 &&
            surveyItems.map((s) => <SurveyItemTooltip key={s.id} survey={s} months={timeline.months} />)}
        </div>
      </div>
    </div>
  )
}
