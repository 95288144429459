import React from 'react'
import { classes } from '../helpers'

export enum DrawerState {
  Normal,
  Expanded,
  Hidden,
}

export enum DrawerPosition {
  Left,
  Right
}

interface DrawerProps {
  children?: any
  state: DrawerState
  position: DrawerPosition
  transparent?: boolean
}

export const Drawer = (props: DrawerProps) => {
  return <div className={classes({
    'drawer': true,
    'expanded': props.state === DrawerState.Expanded,
    'hidden': props.state === DrawerState.Hidden && !props.transparent,
    'left': props.position === DrawerPosition.Left,
    'right': props.position === DrawerPosition.Right,
    'transparent': props.transparent,
  })}>{props.children}</div>
}
