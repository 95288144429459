import React from 'react'
import { useMutation } from '~/components'
import { getTracking } from '~/components'
import { useAppState } from '~/state'
import REMOVE_INSIGHT from './mutation-insight-remove.gql'
import { InsightRemoveMutation, InsightRemoveMutationVariables } from '~/models'
import { useUser } from '~/base'

interface InsightRemoveProps {
  onRemove: () => void
  hide: () => void
}

export const InsightRemove = (props: InsightRemoveProps) => {
  const { view } = useAppState()
  const user = useUser()

  const [removeInsight] = useMutation<InsightRemoveMutation, InsightRemoveMutationVariables>(REMOVE_INSIGHT)

  const doRemoveInsight = () =>
    removeInsight({
      variables: {
        input: {
          siteID: view.siteID,
          insightID: view.insightID,
          observationID: view.observationID,
          orgID: user.org.id,
        },
      },
    }).then(props.onRemove)

  return (
    <div className='site-delete'>
      <div className='site-delete-confirm'>
        <h6>Delete this insight?</h6>
        <button
          className='site-delete-confirm-delete btn'
          onClick={() => {
            doRemoveInsight()
            getTracking().event({
              category: 'Button',
              action: `User removed a insight`,
              label: 'Insight',
            })
          }}
        >
          Yes, delete
        </button>
        <button
          className='site-delete-confirm-cancel btn'
          onClick={() => {
            props.hide()
            getTracking().event({
              category: 'Button',
              action: `User canceled remove insight`,
              label: 'Insight',
            })
          }}
        >
          Cancel
        </button>
        <p>Caution: This cannot be undone.</p>
      </div>
    </div>
  )
}
