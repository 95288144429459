import React, { useEffect, useState } from 'react'
import { EpsgCodesQuery, EpsgCodesQueryVariables, SiteQueryBoundary } from '~/models'
import { useQuery } from '../data'
import QUERY_EPSG from './query-epsg.gql'
import { Select } from '../forms'

interface EPSG {
  id: string
  code: number
  description: string
}

interface BoundaryDownloadDXFModalProps {
  boundary: SiteQueryBoundary
  complete: () => void
  cancel: () => void
}

export const BoundaryDownloadDXFModal = (props: BoundaryDownloadDXFModalProps) => {

  const query = useQuery<EpsgCodesQuery, EpsgCodesQueryVariables>(QUERY_EPSG)
  const [epsg, setEpsg] = useState<EPSG[]>()
  const [selectedDatum, setSelectedDatum] = useState<EPSG>()

  useEffect(() => {
    if (!Array.isArray(query?.data?.epsgCodes)) {
      return
    }

    setEpsg(
      query.data.epsgCodes.map((e) => {
        return {
          id: e.code + '',
          code: e.code,
          description: e.description,
        }
      })
    )
  }, [query?.data])

  return (
    <div className='boundaries-draw'>
      <div className='drawer-panel'>
        <nav aria-label='breadcrumb'>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item'>
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  props.cancel()
                }}
              >
                Site
              </a>
            </li>
            <li className='breadcrumb-item active' aria-current='page'>
              Boundary Marker
            </li>
          </ol>
        </nav>
        <div className='drawer-panel-title-container'>
          <h6 className='drawer-panel-title'>Download DXF</h6>
        </div>
        <form autoComplete='off' className='drawer-panel-form-container' onSubmit={() => {}}>
          <Select<EPSG>
            id='epsg'
            label='Datum'
            placeholder='Select datum'
            onChange={(selected) => {
              setSelectedDatum(selected)
            }}
            canSelectGroup
            canSelectNone
            selectedValue={selectedDatum}
            options={epsg ? epsg.map((e) => {
              return {
                id: e.id,
                name: e.description,
                value: e,
                items: [],
              }
            }) : []}
          />
          <div className='drawer-panel-form-container-action-container'>
            <button type='submit' className='btn submit mb-2' disabled={!epsg}>
              {false ? 'Downloading...' : 'Download'}
            </button>
            <button className='btn cancel' onClick={() => props.cancel()}>
              Cancel
            </button>
          </div>
        </form>
      </div>
      <div></div>
    </div>
  )
}
