import { Config } from '~/config'

// Returns the path to the Asseti website.
export function getWebsitePath() {
  return 'https://www.asseti.co'
}

// Returns the path to the Asseti help website.
export function getHelpWebsitePath() {
  return 'https://help.asseti.co'
}

// Returns the path to the Asseti help website.
export function getRegionalSubdomain() {
  if(Config.RegionalSubdomain === '') {
    return 'app'
  }

  return Config.RegionalSubdomain
}

// Returns the subdomain of the special domain for accessing suppliers.
export function getSupplierSubdomain() {
  return 'supplier'
}

// Returns the required base host name for this deployment.
// .asseti.co for no regional deployment.
// .region.asseti.co for a regional deployment.
export function getRequiredHost() {
  const regionalDomain = Config.RegionalDomain
  const regionalSubdomain = Config.RegionalSubdomain
  if(regionalSubdomain !== '') {
    return `.${regionalSubdomain}.${regionalDomain}`
  }
  return '.' + regionalDomain
}

// Returns true if were coming from localhost.
export function isOnLocalhost() {
  return window.location.host.startsWith('localhost')
}


// Returns true if the current host name is the required host name.
export function isOnCorrectHost(domainName: string) {
  // // When on localhost, the host is always correct.
  // if(isOnLocalhost()) {
  //   return true
  // }

  // Compared the required host to the current host.
  // If they match, we're in the right place.
  const requiredHost = `${domainName}${getRequiredHost()}`
  const currentHost = window.location.host
  return currentHost === requiredHost
}

// getCorrestHostURL returns the correct host URL with the specified path.
export function getCorrestHostURL(domainName: string, path: string) {
  if(isOnLocalhost()) {
    return `${window.location.href}${path}`
  }

  const finalPath = `https://${domainName}${getRequiredHost()}/${path.startsWith('/') ? path.substring(1) : path}`
  return finalPath
}

// redirectToCorrectHost redirects the browser to the correct host name.
export function redirectToCorrectHost(domainName: string, path: string) {
  window.location.href = getCorrestHostURL(domainName, path)
}
