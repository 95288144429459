import OpenSeadragon from 'openseadragon'
import 'openseadragon-filtering'
import React, { useEffect, useRef, useState } from 'react'
import { useAppState, useRefState } from '~/state'
import {
  ActivityIndicator,
  classes,
  formalDate,
  fullDate,
  getDownloadImageURL,
  getLargeImageURL,
  getMediumImageURL,
  PolygonColorRed,
  Switch,
} from '~/components'
import { CompassSVG } from '~/components/compass/compass-svg'
import { PolygonShape } from './image-tagger-shape'
import { ExpandedPanel } from '~/state/use-drawer-state'
import { CoordinateT, Photo, SiteQueryIssue } from '~/models'
import { Offset, Point } from '~/issues/components/screen-state'

interface ImageTaggerImplProps {
  isVisible: boolean
  photo: Photo
  addingIssue: boolean
  updatingIssue: boolean
  fullscreen: boolean
  canFullscreen: boolean
  setFullScreen: (b: boolean) => void
  canAddIssue: boolean
  setAddingIssue: (b: boolean) => void
  canHide: boolean
  hide: () => void
  setAddingPoints: (ps: CoordinateT[]) => void
  viewingIssue: boolean
  existingIssues: SiteQueryIssue[]
  currentIssue?: SiteQueryIssue
  previousIssue?: SiteQueryIssue
  isBulk?: boolean
  showCompass?: boolean
  noZIndex?: boolean
}

export const ImageTaggerImpl = (props: ImageTaggerImplProps) => {
  const { drawer, site } = useAppState()
  const [viewer, setViewer] = useRefState<OpenSeadragon.Viewer>()
  const [useHighRes, setUseHighRes] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [brightness, setBrightness] = useState(10)
  const [contrast, setContrast] = useState(1.5)
  const [imageSize, setImageSize] = useState<{
    width: number
    height: number
  }>()

  // Load the image.
  useEffect(() => {
    if (!props.isVisible || !props.photo) {
      return null
    }

    setLoading(true)
    setImageSize(undefined)
    let v = OpenSeadragon({
      id: 'imageTaggerNew',
      tileSources: {
        type: 'image',
        url: useHighRes ? getLargeImageURL(props.photo, site.site.orgID) : getMediumImageURL(props.photo, site.site.orgID),
      },
      showNavigationControl: false,
      homeFillsViewer: true,
      minZoomImageRatio: 0.5,
      maxZoomPixelRatio: 2,
      crossOriginPolicy: 'Anonymous',
    })

    v.addHandler('open', () => {
      var tiledImage = v.world.getItemAt(0)
      if (tiledImage.getFullyLoaded()) {
        const imageSize = v.world.getItemAt(0).getContentSize()
        setLoading(false)
        setImageSize({
          width: imageSize.x,
          height: imageSize.y,
        })
      } else {
        tiledImage.addOnceHandler('fully-loaded-change', () => {
          const imageSize = v.world.getItemAt(0).getContentSize()
          setLoading(false)
          setImageSize({
            width: imageSize.x,
            height: imageSize.y,
          })
        })
      }
    })

    v.initSelection()

    setViewer(v)

    return () => {
      v.destroy()
      v = undefined
      setViewer(undefined)
    }
  }, [props.photo, props.isVisible, useHighRes])

  // Image filters
  useEffect(() => {
    if (!props.isVisible || !viewer.current) {
      return
    }

    const filters = []
    if (showFilters) {
      if (brightness !== 0) {
        filters.push((OpenSeadragon as any).Filters.BRIGHTNESS(brightness))
      }
      if (contrast !== 1) {
        filters.push((OpenSeadragon as any).Filters.CONTRAST(contrast))
      }
    }

    ;(viewer.current as any).setFilterOptions({
      filters: {
        processors: filters,
      },
    })
  }, [viewer.current, brightness, contrast, props.isVisible, showFilters])

  // Drawing when adding an issue.
  useEffect(() => {
    if (!props.addingIssue || !viewer.current || !!props.currentIssue) {
      return
    }

    let selection = viewer.current.selection({
      onSelection: () => {},
      keep: true,
    })
    selection.enable((_, shape) => {
      const imageSize = viewer.current.world.getItemAt(0).getContentSize()
      const poly = shape as PolygonShape
      const points = poly.points.map((p) => {
        return {
          x: p.x / imageSize.x,
          y: p.y / imageSize.y,
        }
      })
      props.setAddingPoints(points)
    })

    return () => {
      selection.disable()
      selection = null
      viewer.current?.selectionHandler.clear()
    }
  }, [props.addingIssue, props.updatingIssue, props.photo, viewer.current, props.currentIssue])

  // Drawing when bulk tagging/moving an issue.
  useEffect(() => {
    if (!viewer.current || !!!props.currentIssue || loading || !imageSize) {
      return
    }

    let selection = viewer.current.selection({
      onSelection: () => {},
      keep: true,
    })
    const coords = props.currentIssue.coordinates || []
    if (coords.length > 0) {
      const points = coords.map((c) => {
        let x = c.x
        let y = c.y
        if (x <= 1) {
          x *= imageSize.width
          y *= imageSize.height
        }
        return new OpenSeadragon.Point(x, y)
      })
      selection.enableWithPoints(
        (_, shape) => {
          const imageSize = viewer.current.world.getItemAt(0).getContentSize()
          const poly = shape as PolygonShape
          const points = poly.points.map((p) => {
            return {
              x: p.x / imageSize.x,
              y: p.y / imageSize.y,
            }
          })
          props.setAddingPoints(points)
        },
        points,
        viewer.current
      )
    } else {
      selection.enable((_, shape) => {
        const imageSize = viewer.current.world.getItemAt(0).getContentSize()
        const poly = shape as PolygonShape
        const points = poly.points.map((p) => {
          return {
            x: p.x / imageSize.x,
            y: p.y / imageSize.y,
          }
        })
        props.setAddingPoints(points)
      })
    }

    return () => {
      selection.disable()
      selection = null
    }
  }, [props.photo, viewer.current, props.currentIssue, loading, imageSize])

  // Drawing existing issues
  useEffect(() => {
    if (!props.isVisible || !viewer.current || loading || !imageSize) {
      return
    }
    for (const issue of (props.existingIssues || []).filter((i) => i.imageID === props.photo.imageID)) {
      const shape = new PolygonShape(viewer.current, () => {})
      shape.setPoints(
        issue.coordinates.map((c) => {
          let x = c.x
          let y = c.y
          if (x <= 1) {
            x *= imageSize.width
            y *= imageSize.height
          }
          return new OpenSeadragon.Point(x, y)
        })
      )
      shape.setViewing()
      viewer.current.selectionHandler.addShape(shape)
    }
  }, [props.photo, props.existingIssues, props.isVisible, viewer.current, loading, imageSize, props.addingIssue])

  if (!props.isVisible) {
    return null
  }
  const photo = props.photo || { yaw: 0, pitch: 0 }
  const hpr = Cesium.HeadingPitchRoll.fromDegrees(photo.yaw, photo.pitch, 0)
  return (
    <>
      <div
        className={classes({
          'image-tagger-container': true,
          fullscreen: props.fullscreen,
          'show-left-bar':
            props.fullscreen &&
            (!!props.viewingIssue || props.addingIssue || drawer.expandedPanel === ExpandedPanel.Photos),
          'left-closed':
            (drawer.leftClosed || !!props.viewingIssue || drawer.expandedPanel === ExpandedPanel.None) &&
            !props.fullscreen,
          'right-closed': drawer.rightClosed,
          'issues-open': drawer.expandedPanel === ExpandedPanel.Issues,
          bulk: props.isBulk,
          'no-z-index': props.noZIndex,
        })}
      >
        <div id='imageTaggerNew'></div>
        {loading && <ActivityIndicator />}
        <div className='date'>{fullDate(props.photo?.dateTaken || 0)}</div>
        {props.showCompass && (
          <div className='compass'>
            <div className='outer-ring' style={{ transform: `rotate(-${hpr.heading}rad)` }}>
              <CompassSVG />
            </div>
          </div>
        )}
        <div className='image-tagger-right-controls'>
          {!props.addingIssue && (
            <>
              <Switch
                className='image-tagger-switch'
                name='use-high-res'
                label='High Resolution'
                checked={useHighRes}
                onChange={() => setUseHighRes(!useHighRes)}
              />

              <div
                className='image-tagger-download'
                onClick={() => {
                  const url = getDownloadImageURL(props.photo, site.site.orgID)
                  const link = document.createElement('a')
                  link.id = 'download-image'
                  link.setAttribute('href', url)
                  link.setAttribute(
                    'download',
                    `${formalDate(props.photo.dateTaken)}-${site.site.name}-${props.photo.hash}.jpg`
                  )
                  document.body.appendChild(link)
                  document.getElementById('download-image').click()
                  document.body.removeChild(link)
                }}
              >
                <i className='material-icons'>download</i>
              </div>
              {props.canFullscreen && (
                <div
                  className={classes({
                    'image-tagger-download': true,
                    active: props.fullscreen,
                  })}
                  onClick={() => {
                    props.setFullScreen(!props.fullscreen)
                  }}
                >
                  <i className='material-icons'>fullscreen</i>
                </div>
              )}
              <div
                className={classes({
                  'image-tagger-download': true,
                  active: showFilters,
                })}
                onClick={() => {
                  setShowFilters(!showFilters)
                }}
              >
                <i className='material-icons'>filter_alt</i>
              </div>
            </>
          )}
          {props.canHide && !props.addingIssue && (
            <div
              className='image-tagger-download'
              onClick={() => {
                props.hide()
              }}
            >
              <i className='material-icons'>close</i>
            </div>
          )}
          {props.canAddIssue && !props.viewingIssue && !props.addingIssue && (
            <div
              className='add-issue'
              onClick={() => {
                props.setAddingIssue(true)
              }}
            >
              Add Issue
            </div>
          )}
          {props.addingIssue && <div className='add-issue adding'>Adding Issue</div>}
        </div>
        {showFilters && (
          <div className='image-tagger-right-filters'>
            <div className='image-tagger-right-filter'>
              <div className='image-tagger-right-filter-text'>Brightness</div>
              <div
                className='image-tagger-right-filter-lower'
                onClick={() => {
                  setBrightness(Math.max(brightness - 10, -100))
                }}
              >
                -
              </div>
              <div className='image-tagger-right-filter-value'>{brightness}</div>
              <div
                className='image-tagger-right-filter-higher'
                onClick={() => {
                  setBrightness(Math.min(brightness + 10, 100))
                }}
              >
                +
              </div>
            </div>

            <div className='image-tagger-right-filter'>
              <div className='image-tagger-right-filter-text'>Contrast</div>
              <div
                className='image-tagger-right-filter-lower'
                onClick={() => {
                  setContrast(Math.max(contrast - 0.25, 0.25))
                }}
              >
                -
              </div>
              <div className='image-tagger-right-filter-value'>{Math.round(contrast * 100) / 100}</div>
              <div
                className='image-tagger-right-filter-higher'
                onClick={() => {
                  setContrast(Math.min(contrast + 0.25, 4))
                }}
              >
                +
              </div>
            </div>
          </div>
        )}
        {props.previousIssue && <IssueItemImage orgID={site.site.orgID} issue={props.previousIssue} />}
      </div>
    </>
  )
}

interface IssueItemImageProps {
  orgID: string
  issue: SiteQueryIssue
}

export function IssueItemImage(props: IssueItemImageProps) {
  const canvas = useRef<HTMLCanvasElement>()
  const [imageUrl, setImageUrl] = useState<string>()
  const [haveCanvas, setHaveCanvas] = useState(false)

  // Load the image.
  useEffect(() => {
    if (!haveCanvas || !props.issue) {
      setImageUrl(undefined)
      return
    }

    const mediumImg = new Image()
    mediumImg.setAttribute('crossorigin', 'anonymous')

    mediumImg.onload = () => {
      if (!canvas.current) {
        return
      }

      const c = canvas.current
      const ctx = c.getContext('2d')

      ctx.canvas.width = mediumImg.width
      ctx.canvas.height = mediumImg.height

      const coords = props.issue.coordinates.map((c) => {
        if (c.x > 1) {
          return {
            ...c,
          }
        }
        return {
          x: c.x * mediumImg.width,
          y: c.y * mediumImg.height,
        }
      })

      const minX = coords.reduce((a, b) => (a < b.x ? a : b.x), Number.MAX_VALUE)
      const minY = coords.reduce((a, b) => (a < b.y ? a : b.y), Number.MAX_VALUE)
      const maxX = coords.reduce((a, b) => (a > b.x ? a : b.x), Number.MIN_VALUE)
      const maxY = coords.reduce((a, b) => (a > b.y ? a : b.y), Number.MIN_VALUE)

      ctx.clearRect(0, 0, c.width, c.height)
      const width = maxX - minX
      const height = maxY - minY

      const cx = minX + width / 2
      const cy = minY + height / 2
      let x = cx - width * 0.75
      let y = cy - width * 0.75
      let w = width * 1.5
      let h = w

      if (width > height) {
        // Above calculations...
      } else if (width < height) {
        x = cx - height * 0.75
        y = cy - height * 0.75
        h = height * 1.5
        w = h
      }

      if (x < 0) {
        x = 0
        w = mediumImg.width
      }
      if (y < 0) {
        y = 0
        h = mediumImg.height
      }
      if (w > mediumImg.width) {
        x = 0
        w = mediumImg.width
      }
      if (h > mediumImg.height) {
        y = 0
        h = mediumImg.height
      }

      const getCanvasPosition = (p: Point): Offset => {
        return {
          left: ((p.x - x) / w) * c.width,
          top: ((p.y - y) / h) * c.height,
        }
      }

      if (Array.isArray(coords) && coords.length > 0) {
        ctx.drawImage(mediumImg, x, y, w, h, 0, 0, c.width, c.height)
        const coordsWithInitial = [...coords, coords[0]]

        ctx.save()
        ctx.fillStyle = PolygonColorRed
        ctx.strokeStyle = PolygonColorRed
        ctx.lineWidth = 8

        for (let i = 1; i < coordsWithInitial.length; i++) {
          const previousPosition = getCanvasPosition(coordsWithInitial[i - 1])
          const currentPosition = getCanvasPosition(coordsWithInitial[i])
          ctx.beginPath()
          ctx.moveTo(previousPosition.left, previousPosition.top)
          ctx.lineTo(currentPosition.left, currentPosition.top)
          ctx.closePath()
          ctx.stroke()
        }

        ctx.restore()
      } else {
        ctx.drawImage(mediumImg, 0, 0, mediumImg.width, mediumImg.height, 0, 0, c.width, c.height)
      }

      const dataUrl = canvas.current.toDataURL()
      setImageUrl(dataUrl)
    }

    mediumImg.src = getMediumImageURL(
      {
        hash: props.issue.imageHash,
      },
      props.orgID
    )
  }, [haveCanvas, props.issue?.id])

  useEffect(() => {
    if(canvas.current) {
      setHaveCanvas(true)
    }
  }, [canvas.current])

  return (
    <>
      {imageUrl && <img className='image-tagger-previous-issue-image' src={imageUrl} />}
      <canvas className='image-tagger-previous-issue-image' ref={canvas}></canvas>
    </>
  )
}
