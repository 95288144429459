query Notifications {
  notifications {
    notifications {
      id
      data {
        type
        value {
          ... on NotificationDataCommentTaggedT {
            commentID
            parentCommentID
            commentText
            taggedByUserID
            taggedByUserName
            siteID
            assetID
            issueID
            siteName
            assetName
            issueName
          }
          ... on NotificationDataCommentRepliedT {
            commentID
            parentCommentID
            commentText
            repliedByUserID
            repliedByUserName
            siteID
            assetID
            issueID
            siteName
            assetName
            issueName
          }
          ... on NotificationDataTaskScheduledAndAssignedT {
            taskID
            taskName
            blueprintID
            scheduledTaskID
          }
          ... on NotificationDataInsightCreatedT {
            siteID
            assetID
            surveyID
            insightID
            siteName
            assetName
            surveyID
            observationDate
            observationID
          }
        }
      }
      createdAt
      read
    }
  }
}