import React, { useState, useEffect } from 'react'
import { useLocation, classes, Select, DrawerPopoutList, DrawerPopoutListCard } from '~/components'
import { RiskTypeSelectItem, RISK_TYPE_OPTIONS } from '~/issues/selects'
import { RiskType, SiteQueryInsight } from '~/models'
import { useAppState } from '~/state'

export const InsightFullList = () => {
  const { timeline } = useAppState()

  const [filteredInsights, setFilteredInsights] = useState<SiteQueryInsight[]>([])
  const [selectedRiskFilter, setSelectedRiskFilter] = useState<RiskType | string>('All Risks')

  useEffect(() => {
    let insightsToUse = [...(timeline.activeObservation?.insights || [])]

    if (selectedRiskFilter !== 'All Risks') {
      insightsToUse = insightsToUse.filter((i) => i.riskType === selectedRiskFilter)
    }

    const riskMap = {
      [RiskType.Low]: 0,
      [RiskType.Medium]: 1,
      [RiskType.High]: 2,
    }

    setFilteredInsights(
      insightsToUse.sort((a, b) => {
        if (riskMap[a.riskType] > riskMap[b.riskType]) {
          return -1
        }

        return a.defectType > b.defectType ? -1 : 1
      })
    )
  }, [selectedRiskFilter, timeline.activeObservation?.insights])

  const Controls = (
    <div className='issues-card-filters'>
      <Select<RiskTypeSelectItem>
        id='risk'
        tiny
        options={[
          {
            name: 'All Risks',
            id: 'All Risks',
            value: {
              id: 'All Risks',
              value: 'All Risks' as RiskType,
            },
            items: [],
          },
          ...RISK_TYPE_OPTIONS,
        ]}
        selectedValue={{
          id: selectedRiskFilter,
          value: selectedRiskFilter as RiskType,
        }}
        onChange={(s) => {
          setSelectedRiskFilter(s.value)
        }}
      />
    </div>
  )

  return (
    <DrawerPopoutList
      width={360}
      title='Insights'
      total={(timeline.activeObservation?.insights || []).length}
      count={filteredInsights.length}
      emptyText='There are no insights to display.'
      controls={Controls}
    >
      {filteredInsights.map((insight) => {
        return <InsightCard insight={insight} />
      })}
    </DrawerPopoutList>
  )
}

interface InsightCardProps {
  insight: SiteQueryInsight
}

function InsightCard(props: InsightCardProps) {
  const { view, timeline } = useAppState()
  const location = useLocation()
  const insight = props.insight

  const showInsightDetails = () => {
    if (view.assetID) {
      location.setLocation(
        `/${view.siteID}/${timeline.activeSurvey.id}/assets/${view.assetID}/observation/${timeline.activeObservation.id}/insight/${insight.id}`
      )
    } else {
      location.setLocation(
        `/${view.siteID}/${timeline.activeSurvey.id}/observation/${timeline.activeObservation.id}/insight/${insight.id}`
      )
    }
  }

  return (
    <DrawerPopoutListCard
      key={insight.surveyID + insight.id}
      active={insight.id === view.insightID}
      className={classes({
        'insights-card': true,
        [insight.riskType.toLowerCase()]: true,
      })}
      onClick={showInsightDetails}
    >
      <div className='insights-card-right'>
        <div className='insights-card-right-upper'>
          {insight.defectType}
          {insight.defectSubType ? ' - ' + insight.defectSubType : ''}
          <div
            className={classes({
              'insights-card-risk': true,
              [insight.riskType.toLowerCase()]: true,
            })}
          >
            {insight.riskType}
          </div>
        </div>
        <div className='insights-card-right-lower'>
          {insight.description}
        </div>
      </div>
    </DrawerPopoutListCard>
  )
}
