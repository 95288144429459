import React from 'react'
import { DrawerListContent } from '~/components'
import { useAppState } from '~/state'
import { useInsightLayers } from './use-insight-visualizations'
import { ExpandedPanel } from '~/state/use-drawer-state'
import { InsightDetail } from './insight-details'
import { InsightFullList } from './insights-full-list'

export const InsightsDrawerList = () => {
  const { timeline, drawer, view } = useAppState()
  useInsightLayers()
  const insights = timeline.activeObservation?.insights || []

  if (insights.length === 0) {
    return null
  }

  return (
    <>
      <DrawerListContent
        canAdd={false}
        loading={false}
        numEntities={insights.length}
        panelType={ExpandedPanel.Insights}
        plural='insights'
        singular='insight'
        title='Insights'
      />
      {drawer.expandedPanel === ExpandedPanel.Insights && <InsightFullList />}
      {view.insightID && <InsightDetail />}
    </>
  )
}
