import { ActivityIndicator, post } from '~/components'
import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useUser } from '~/base'
import { Config } from '~/config'

interface FileUploadModalProps {
  show: boolean
  close: () => void
  reload: () => void
  loading: boolean
  changeLoading: (change?: boolean) => void
}

enum FileUploadModalViewType {
  ADD_URLS,
  CONFIRM_URLS,
  CONFIRMED,
}

export const FileUploadModal = (props: FileUploadModalProps) => {
  const user = useUser()
  const [step, setStep] = useState<FileUploadModalViewType>(FileUploadModalViewType.ADD_URLS)
  const [urls, setURLs] = useState<
    {
      url: string
      valid: boolean
    }[]
  >([])
  const [urlString, setURLString] = useState('')

  const handleURLChange = (urls: string) => {
    const urlArray = urls.split('\n').map((u) => u.trim())

    const items = []
    for (const url of urlArray) {
      const urlRegex =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gm
      items.push({
        url,
        valid: urlRegex.test(url),
      })
    }

    setURLs(items)
    setURLString(urls)
  }

  useEffect(() => {
    setStep(FileUploadModalViewType.ADD_URLS)
  }, [])

  const execute = () => {
    props.changeLoading(true)

    return post<any, string>(
      {
        url: Config.BaseUploadUrl + '/url-upload',
        data: JSON.stringify({
          orgId: user.org.id,
          urls: urls.map((x) => x.url),
        }),
      },
      user.token,
    )
      .then(() => {
        setStep(FileUploadModalViewType.CONFIRMED)
      })
      .catch(() => {})
      .finally(() => {
        props.changeLoading(false)
      })
  }

  const closeModal = () => {
    setURLs([])
    setURLString('')
    setStep(FileUploadModalViewType.ADD_URLS)
    props.close()
  }

  return createPortal(
    <div className='modal team-invite-modal' tabIndex={-1} style={{ display: props.show ? 'block' : 'none' }}>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            {step === 1 && (
              <div
                className='back'
                aria-label='Back'
                onClick={() => {
                  setStep(FileUploadModalViewType.ADD_URLS)
                }}
              >
                <span className='material-icons'>keyboard_backspace</span>
              </div>
            )}
            <div
              className='close'
              aria-label='Close'
              onClick={() => {
                props.close()
                setURLs([])
                setStep(FileUploadModalViewType.ADD_URLS)
              }}
            >
              <span className='material-icons'>close</span>
            </div>
          </div>
          <div className='modal-body wide'>
            {props.loading ? (
              <ActivityIndicator />
            ) : (
              <>
                {step === FileUploadModalViewType.ADD_URLS && (
                  <>
                    <h5>Import data from URLs</h5>
                    <form>
                      <textarea
                        placeholder='https://url.com&#10;https://url2.com&#10;https://url3.com&#10;...'
                        onChange={(e) => handleURLChange(e.target.value)}
                      >{urlString}</textarea>
                    </form>
                    <button
                      type='button'
                      className='modal-btn btn'
                      onClick={() => setStep(FileUploadModalViewType.CONFIRM_URLS)}
                      disabled={urls.length == 0 ? true : false}
                    >
                      Next
                    </button>
                  </>
                )}
                {step === FileUploadModalViewType.CONFIRM_URLS && (
                  <>
                    <h5>
                      Import {urls.length} {urls.length === 1 ? 'URL' : 'URLs'} to Asseti
                    </h5>
                    <ul className='team-invite-list wide'>
                      {urls.map((e) => {
                        return (
                          <li className='team-invite-list-item' key={e.url}>
                            <div className='team-invite-list-item-email'>
                              {e.url}
                              {e.valid && <i className='material-icons'>check</i>}
                              {!e.valid && <i className='material-icons'>clear</i>}
                            </div>
                          </li>
                        )
                      })}
                    </ul>

                    <button
                      type='button'
                      className='modal-btn btn'
                      onClick={execute}
                      disabled={!!urls.find((x) => !x.valid)}
                    >
                      {props.loading ? 'Loading...' : 'Import'}
                    </button>
                  </>
                )}
                {step === FileUploadModalViewType.CONFIRMED && (
                  <>
                    <h5>
                      Successfully scheduled the import of {urls.length} {urls.length === 1 ? 'URL' : 'URLs'} to Asseti
                    </h5>
                    <p>Images will appear in Asseti once the download completes.</p>
                    <button type='button' className='modal-btn btn' onClick={closeModal}>
                      Ok
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('modal')
  )
}
