import React, { useEffect, useMemo, useState } from 'react'
import { SiteGroupCreate } from './site-groups-create'
import { Marker, MarkerIconType, useAppState, useRefState } from '~/state'
import { ClientPermission, useUser } from '~/base'
import { OverviewStatsCard, PolygonColorRed, useLocation, useToasts } from '~/components'
import { SitesHeader } from '../sites-header'
import { SitesListViewType } from '../sites-list-types'
import { SitesQuerySiteWithExtra, SitesQuerySiteGroupWithExtra, AnnotationType } from '~/models'
import { PolygonRenderer3DView } from '~/components/polygon-renderer-3d'
import { getOffsetPolygon } from '~/components/helpers/get-offset-polygon'

interface SitesListGroupsProps {
  sites: SitesQuerySiteWithExtra[]
  groups: SitesQuerySiteGroupWithExtra[]
}

export const SitesListGroups = (props: SitesListGroupsProps) => {
  const { sites, map, search } = useAppState()
  const toasts = useToasts()
  const user = useUser()
  const location = useLocation()
  const canListIssues = user.hasPermission(ClientPermission.IssuesList)
  const [showCreate, setShowCreate] = useState(false)
  const [markers, setMarkers] = useRefState<Marker[]>([])
  const [polygonRenderers, setPolygonRenderers] = useRefState<PolygonRenderer3DView[]>([])

  const groups = useMemo(() => {
    if (search.search === '') {
      return props.groups
    }

    return props.groups.filter((g) => g.group.name.toLocaleLowerCase().includes(search.search.toLocaleLowerCase()))
  }, [props.groups, search.search])

  useEffect(() => {
    const markers: Marker[] = []
    const polys: PolygonRenderer3DView[] = []

    for (const group of groups) {
      if (group.sites.length === 0 || !group.center) {
        continue
      }
      const carto = Cesium.Cartographic.fromCartesian(group.center)
      const marker = map.addMarker(
        group.group.id,
        [Cesium.Math.toDegrees(carto.longitude), Cesium.Math.toDegrees(carto.latitude)],
        () => {
          location.setLocation('/groups/' + group.group.id)
        },
        group.group.name,
        32,
        '#1F1F1F',
        MarkerIconType.None,
        new Cesium.NearFarScalar(1e1, 1, 1e3, 1)
      )
      markers.push(marker)

      if (group.hull) {
        const points = getOffsetPolygon(
          group.hull.map((p) => {
            const carto = Cesium.Cartographic.fromCartesian(p)
            return {
              longitude: Cesium.Math.toDegrees(carto.longitude),
              latitude: Cesium.Math.toDegrees(carto.latitude),
              altitude: 0,
            }
          }),
          25000,
          true
        )

        const poly = new PolygonRenderer3DView({
          id: group.group.id + '-group',
          points: points.map((p) => {
            return { ...p, altitude: 0 }
          }),
          map,
          renderPoints: false,
          color: PolygonColorRed,
          scaleByDistance: new Cesium.NearFarScalar(10, 0.8, 40, 0.8),
          translucencyByDistance: new Cesium.NearFarScalar(120, 1, 180, 0),
          annotationType: AnnotationType.Area,
          fill: false,
          onClick: () => {},
          onRightClick: (pos) => {
            return false
          },
        })
        poly.setVisible(false)
        polys.push(poly)
      }
    }

    setPolygonRenderers(polys)
    setMarkers(markers)

    return () => {
      markers.forEach((m) => map.removeMarker(m))
      polygonRenderers.current.forEach((p) => p.destroy())
      setPolygonRenderers([])
    }
  }, [groups])

  const haveError = sites.error
  const haveSites = !haveError && sites.data && sites.data?.sites.length > 0
  const showEmpty = !haveError && sites.data && sites.data?.siteGroups.length === 0
  const showFull = !haveError && sites.data && sites.data?.siteGroups.length > 0

  return (
    <>
      <SitesHeader
        haveSites={haveSites}
        onCreateSite={() => {
          setShowCreate(true)
        }}
        filterSites={(value: string) => {
          search.setSearch(value || '')
        }}
        siteResults={props.sites}
        viewType={SitesListViewType.Groups}
        extraLinks={(sites.groups || []).map((g) => {
          return {
            name: g.group.name,
            description: `${g.group.siteIDs.length} sites`,
            url: '/groups/' + g.group.id,
          }
        })}
        permission={ClientPermission.ManageSiteGroups}
      />
      <div className='sites-list' id='sites-list'>
        {showFull && (
          <>
            <div
              className='sites-card small'
              onClick={() => {
                location.setLocation('/')
              }}
            >
              <div className='sites-card-content'>View all Sites</div>
              {/* <OverviewStatsCard
                lastUploadTime={sg.lastUploadTime}
                numSites={sg.sites.length}
                numAssets={sg.numAssets}
                numHighIssues={sg.numHighIssues}
                numMediumIssues={sg.numMediumIssues}
                numLowIssues={sg.numLowIssues}
                canListIssues={canListIssues}
              /> */}
            </div>
            {groups.map((sg) => {
              return (
                <div
                  key={sg.group.id}
                  className='sites-card'
                  onClick={() => {
                    location.setLocation('/groups/' + sg.group.id)
                  }}
                  onMouseEnter={() => {
                    const poly = polygonRenderers.current.find((r) => r.id.startsWith(sg.group.id))
                    if (poly) {
                      poly.setVisible(true)
                      poly.setFill(true)
                    }

                    const marker = markers.current.find((m) => m.id === sg.group.id)
                    if (marker) {
                      marker.label.backgroundColor = Cesium.Color.fromCssColorString(PolygonColorRed)
                    }
                  }}
                  onMouseLeave={() => {
                    const poly = polygonRenderers.current.find((r) => r.id.startsWith(sg.group.id))
                    if (poly) {
                      poly.setVisible(false)
                      poly.setFill(false)
                    }

                    const marker = markers.current.find((m) => m.id === sg.group.id)
                    if (marker) {
                      marker.label.backgroundColor = Cesium.Color.fromCssColorString('#1F1F1F')
                    }
                  }}
                >
                  <div className='sites-card-content mb-1'>{sg.group.name}</div>
                  <OverviewStatsCard
                    lastUploadTime={sg.lastUploadTime}
                    numSites={sg.sites.length}
                    numAssets={sg.numAssets}
                    numHighIssues={sg.numHighIssues}
                    numMediumIssues={sg.numMediumIssues}
                    numLowIssues={sg.numLowIssues}
                    canListIssues={canListIssues}
                  />
                </div>
              )
            })}
          </>
        )}
        {showEmpty && (
          <div className='sites-list-container-empty'>
            <div className='body2 mb-4'>You don't have any groups yet</div>
            <div
              className='sites-list-add-site'
              onClick={() => {
                setShowCreate(true)
              }}
            >
              <span className='sites-list-add-site-title'>Add a group</span>
              <i className='material-icons'>add</i>
            </div>
          </div>
        )}
        {showCreate && Array.isArray(sites.data?.sites) && sites.summaryQuery?.data && (
          <SiteGroupCreate
            updating={false}
            onComplete={(created) => {
              if (created) {
                sites.refetch().then(() => {
                  toasts.addTopLeft('The site group was successfully created')
                  setShowCreate(false)
                })
              } else {
                setShowCreate(false)
              }
            }}
          />
        )}
      </div>
    </>
  )
}
