import 'regenerator-runtime/runtime'
import { Home } from './home'
import { AppStateProvider } from '~/state'
import { UserStateProvider, useAuthStatus, useUser } from './base'
import { Route, Switch, BrowserRouter } from 'react-router-dom'

import { ApolloProvider } from '@apollo/client'
import { AuthSwitch } from './auth/auth-switch'
import { ErrorBoundaryWithAppState, Helmet } from '~/components'
import { MobileNotification } from './auth/auth-mobile-notification'
import { NotFoundPage } from './404'
import React, { useEffect, useState } from 'react'
import { SystemRole } from './models'
import dayjs from 'dayjs'
import { initClient } from './apollo'
import relativeTime from 'dayjs/plugin/relativeTime'
import { render } from 'react-dom'
import { ErrorBoundary, useLocation } from './components'
import { AuthAcceptInvite } from './auth/auth-accept-invite'
import { AuthEmailConfirmationSuccess } from './auth/auth-email-confirmation-success'
import { AuthPreConfirmation } from './auth/auth-pre-confirmation'
import { AuthResendConfirmation } from './auth/auth-resend-confirmation'
import { AuthResetPassword } from './auth/auth-reset-password'
import { AuthSignIn } from './auth/auth-sign-in'
import { AuthSignUp } from './auth/auth-sign-up'
import { AuthSignedOut } from './auth/auth-signed-out'
import { AuthUpdatePassword } from './auth/auth-update-password'
import { AuthSwitchOrg } from './auth/auth-switch-org'
import { AuthCompleteSignIn } from './auth/auth-complete-sign-in'
import { PhotoDownload } from './account/photo-download'
import { MatterportAuthSuccess } from './account/matterport-auth-success'
import { AuthSignUpSupplier } from './auth/auth-sign-up-supplier'
import { AuthAcceptSupplierInvite } from './auth/auth-accept-supplier-invite'
import { AuthReactivate } from './auth/auth-reactivate'

dayjs.extend(relativeTime)

let AdminImpl: React.FC = undefined

const Admin = () => {
  useAuthStatus()
  const [loading, setLoading] = useState(AdminImpl === undefined)

  useEffect(() => {
    if (loading) {
      import('./admin/admin').then((res) => {
        AdminImpl = res.default
        setLoading(false)
      })
    }
  }, [])

  if (AdminImpl) {
    return <AdminImpl />
  }

  return <div></div>
}

let DasboardsImpl: React.FC = undefined

const Dashboards = () => {
  useAuthStatus()
  const [loading, setLoading] = useState(DasboardsImpl === undefined)

  useEffect(() => {
    if (loading) {
      import('./dashboards/dashboards').then((res) => {
        DasboardsImpl = res.default
        setLoading(false)
      })
    }
  }, [])

  if (DasboardsImpl) {
    return <DasboardsImpl />
  }

  return <div></div>
}

let SupplierImpl: React.FC = undefined

const Supplier = () => {
  useAuthStatus()
  const [loading, setLoading] = useState(SupplierImpl === undefined)

  useEffect(() => {
    if (loading) {
      import('./supplier/supplier').then((res) => {
        SupplierImpl = res.default
        setLoading(false)
      })
    }
  }, [])

  if (SupplierImpl) {
    return <SupplierImpl />
  }

  return <div></div>
}

const Main = () => {
  const user = useUser()
  const urlParams = new URLSearchParams(window.location.search)
  const switchingToken = urlParams.get('st')
  const authToken = urlParams.get('t')
  const orgId = urlParams.get('id')
  const supplierId = urlParams.get('sid')

  if (window.innerWidth < 650) {
    return <MobileNotification />
  } else {
    if ((orgId || supplierId) && typeof switchingToken === 'string') {
      return <AuthSwitch switchingToken={switchingToken} orgId={orgId} supplierId={supplierId} />
    }

    if ((orgId || supplierId) && typeof authToken === 'string' && window.location.href.includes('/auth')) {
      return <AuthSwitchOrg token={authToken} orgId={orgId} supplierId={supplierId} />
    }

    if (!user.isLoggedIn) {
      const validRoutes = [
        '/signin',
        '/complete-sign-in',
        '/signup',
        '/reset-password',
        '/resend-confirmation',
        '/email-confirmation',
        '/email-confirmation-success',
        '/update-password',
        '/signed-out',
        '/accept-invite',
        '/404',
      ]

      if (!validRoutes.find((x) => window.location.pathname.startsWith(x))) {
        if (window.location.pathname !== '/') {
          window.location.href = '/signin?r=' + window.location.pathname
        } else {
          window.location.pathname = '/signin'
        }
      }
      return null
    }

    if (window.location.href.includes('/dashboards')) {
      return <Dashboards />
    }

    if (!user.loaded) {
      return null
    }

    if (user.systemRole === SystemRole.Staff && !user.currentOrgId && !user.currentSupplierID) {
      return <Admin />
    }

    if (user.currentSupplierID) {
      return <Supplier />
    }

    if ((!Array.isArray(user.policy) || user.policy.length !== 4 || user.policy === undefined) && !!user.currentOrgId) {
      window.location.pathname = '/signed-out'
      return null
    }

    return (
      <AppStateProvider>
        <ErrorBoundaryWithAppState>
          <Home />
        </ErrorBoundaryWithAppState>
      </AppStateProvider>
    )
  }
}

const Root = () => {
  const location = useLocation()
  useEffect(() => {
    if (!window.ga) {
      return
    }

    window.ga('set', 'page', window.location.pathname)
    ga('send', 'pageview')
  }, [location.location])

  useEffect(() => {
    removeLoader()
  }, [])

  return (
    <BrowserRouter>
      <Helmet defaultTitle='Asseti | Asset intelligence at scale' titleTemplate='%s | Asseti' />
      <ApolloProvider client={initClient()}>
        <UserStateProvider>
          <ErrorBoundary>
            <Switch>
              <Route path='/signin' component={AuthSignIn} key='signin' />
              <Route path='/signup' component={AuthSignUp} key='signup' />
              <Route path='/signup-supplier' component={AuthSignUpSupplier} key='signupassupplier' />
              <Route path='/reset-password' component={AuthResetPassword} key='reset-password' />
              <Route path='/resend-confirmation' component={AuthResendConfirmation} key='resend-confirmation' />
              <Route path='/email-confirmation' component={AuthPreConfirmation} key='email-confirmation' />
              <Route
                path='/email-confirmation-success'
                component={AuthEmailConfirmationSuccess}
                key='email-confirmation-success'
              />
              <Route path='/update-password' component={AuthUpdatePassword} key='update-password' />
              <Route path='/signed-out' component={AuthSignedOut} key='signed-out' />
              <Route path='/accept-invite' component={AuthAcceptInvite} key='accept-invite' />
              <Route path='/accept-supplier-invite' component={AuthAcceptSupplierInvite} key='accept-supplier-invite' />
              <Route path='/complete-sign-in' component={AuthCompleteSignIn} />
              <Route path='/reactivate' component={AuthReactivate} />
              <Route path='/404' component={NotFoundPage} />
              <Route path='/photo' component={PhotoDownload} />
              <Route path='/matterport-auth-success' component={MatterportAuthSuccess} />
              <Route component={Main} />
            </Switch>
          </ErrorBoundary>
        </UserStateProvider>
      </ApolloProvider>
    </BrowserRouter>
  )
}

;(window as any).allowHubspot = true
const root = document.getElementById('root')
render(<Root />, root)

window.__assetiWorker = window.Worker

function removeLoader() {
  const loader = document.getElementById('full-page-loader')
  loader.remove()
  setTimeout(() => {
    root.style.opacity = '1'
  }, 0)
}



