import React from 'react'
import { useLocation, SimpleBar } from '~/components'
import { InstantAssessmentSummaryAllSitesQuery, SitesQuerySiteWithExtra } from '~/models'
import { useAppState } from '~/state'

type IASite = InstantAssessmentSummaryAllSitesQuery['instantAssessmentSummaryAllSites']['sites'][0]

interface SitesListIAProps {
  filter: string[]
  onCreateSite: () => void
  sites: SitesQuerySiteWithExtra[]
  isLoading: boolean
  showEmpty: boolean
  haveSites: boolean
  showFullList: boolean
}

export const SitesListIA = (props: SitesListIAProps) => {
  const { sites } = useAppState()
  const summary = sites.iaQuery?.data?.instantAssessmentSummaryAllSites
  const haveSummary = !!summary

  return (
    <div className='sites-list-ia'>
      {/* <div className='sites-list-ia-header'>Instant Assess Sites</div> */}
      <div className='sites-list-ia-list'>
        <SimpleBar>
          {haveSummary && summary.sites.map((s) => <SitesListIASite key={s.siteName} site={s} />)}
          {haveSummary && (
            <div className='sites-list-ia-list-extra'>
              + {summary.numSitesTotal - summary.numSites} other sites identified
            </div>
          )}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </SimpleBar>
      </div>
      {/* <div className='sites-list-ia-footer'>
        <button className='btn btn-auth'>Book a walkthrough</button>
        <button className='btn btn-auth'>Request an upgrade</button>
      </div> */}
    </div>
  )
}

interface SitesListIASiteProps {
  site: IASite
}

const SitesListIASite = (props: SitesListIASiteProps) => {
  const { sites } = useAppState()
  const location = useLocation()

  return (
    <div
      className='sites-list-ia-site'
      onClick={() => {
        const site = sites.sites.find((s) => s.s.name === props.site.siteName)
        location.setLocation('/' + site.s.id + '/' + site.s.lastSurveyID)
      }}
    >
      <div className='sites-list-ia-site-title'>{props.site.siteName}</div>
      <div className='sites-list-ia-site-summary'>
        <ProgressBar percent={props.site.conditionPercent} condition={props.site.conditionString} />
      </div>
    </div>
  )
}

interface ProgressBarProps {
  percent: number
  condition: string
}

const ProgressBar = (props: ProgressBarProps) => {
  const score = Math.floor(props.percent)
  let color = '#FF0000'
  if (score > 90) {
    color = '#00BF63'
  } else if (score >= 80) {
    color = '#3DB856'
  } else if (score >= 70) {
    color = '#79B048'
  } else if (score >= 60) {
    color = '#BCB751'
  } else if (score >= 50) {
    color = '#FFBD59'
  } else if (score >= 40) {
    color = '#FEA249'
  } else if (score >= 30) {
    color = '#FC8738'
  } else if (score >= 20) {
    color = '#F85117'
  } else if (score >= 10) {
    color = '#FC290C'
  } else if (score >= 0) {
    color = '#FF0000'
  }

  return (
    <div className='sites-list-ia-site-bar'>
      <div className='sites-list-ia-site-bar-left'>
        <div className='sites-list-ia-site-bar-background'></div>
        <div
          className='sites-list-ia-site-bar-foreground'
          style={{
            backgroundColor: color,
            width: (score / 100) * 140 + 'px',
          }}
        ></div>
        <div
          className='sites-list-ia-site-bar-percent'
          style={{
            left: (score / 100) * 140 + 2 + 'px',
          }}
        >
          {Math.floor(props.percent) + '%'}
        </div>
      </div>
      <div
        className='sites-list-ia-site-bar-right'
        style={{
          color: color,
        }}
      >
        {props.condition.replace('Average', 'AVG').toLocaleUpperCase()}
      </div>
    </div>
  )
}
