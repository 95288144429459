import React, { useState } from 'react'
import { string, object } from '~/components'
import {
  FloatingInput,
  useForm,
  getTracking,
  OperationTypeSelect,
  DrawerForm,
  DrawerFormBreadcrumbItem,
  DrawerFormBreadcrumbs,
  DrawerFormButtons,
  DrawerFormCancelButton,
  DrawerFormContent,
  DrawerFormHeading,
  DrawerFormSubmitButton,
} from '~/components'
import { UpdateAssetMutation, AssetUpdateRequestT, SiteQueryAsset, AssetTypeDtoT, SurveyBasedOperation } from '~/models'
import { Helmet } from '~/components'
import UPDATE_ASSET_MUTATION from './mutation-asset-update.gql'
import { AssetTypeSelect } from './asset-types-select'
import { useAppState } from '~/state'

interface AssetUpdateProps {
  asset: SiteQueryAsset
  onComplete: (arg0: boolean) => void
}

export const AssetUpdate = (props: AssetUpdateProps) => {
  const { view, timeline } = useAppState()

  const [selectedAssetType, setSelectedAssetType] = useState<AssetTypeDtoT>({
    id: props.asset?.assetType?.id,
    name: props.asset?.assetType?.name,
  } as AssetTypeDtoT)

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, error, setFieldValue } =
    useForm<UpdateAssetMutation, AssetUpdateRequestT>({
      enableReinitialize: true,
      initialValues: {
        assetID: props?.asset?.id,
        name: props?.asset?.name,
        assetTypeID: props?.asset?.assetType?.id,
        operationType: SurveyBasedOperation.GoingForward,
        siteID: view.siteID,
        surveyID: timeline.activeSurvey?.id,
      },
      validationSchema: object().shape({
        name: string().required('Required'),
      }),
      mutation: UPDATE_ASSET_MUTATION,
      onSuccess: () => {
        getTracking().event({
          category: 'Form',
          action: `User updated a asset`,
          label: 'Asset',
        })
        props.onComplete(true)
      },
    })

  if (!timeline.activeSurvey) {
    props.onComplete(false)
    return null
  }

  return (
    <DrawerForm>
      <Helmet title='Update asset' />
      <DrawerFormBreadcrumbs>
        <DrawerFormBreadcrumbItem title='Asset' onClick={() => props.onComplete(false)} />
        <DrawerFormBreadcrumbItem title={'Update ' + props.asset?.name} />
      </DrawerFormBreadcrumbs>
      <DrawerFormHeading title='Update asset' />
      <DrawerFormContent>
        <form autoComplete='off' onSubmit={handleSubmit} id='asset-update-form'>
          <FloatingInput
            label='Name'
            id='name'
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.name ? errors.name : ''}
            error={touched.name && Boolean(errors.name)}
          />
          <AssetTypeSelect
            onChange={(s) => {
              setSelectedAssetType(s)
              setFieldValue('assetTypeID', s?.id || '')
            }}
            assetType={selectedAssetType}
            helperText={touched.assetTypeID ? errors.assetTypeID : undefined}
          />
          <OperationTypeSelect
            name='operationType'
            selectedValue={values.operationType}
            onChange={handleChange}
            survey={timeline.activeSurvey}
          />
        </form>
      </DrawerFormContent>
      <DrawerFormButtons>
        {error && <div className='alert alert-warning ml-3 mr-3'>{error}</div>}
        <DrawerFormSubmitButton form='asset-update-form' disabled={isSubmitting}>
          {isSubmitting ? 'Saving...' : 'Save'}
        </DrawerFormSubmitButton>
        <DrawerFormCancelButton onClick={() => props.onComplete(false)}>Cancel</DrawerFormCancelButton>
      </DrawerFormButtons>
    </DrawerForm>
  )
}
