import { useEffect, useState } from 'react'
import { useAppState } from '~/state'
import { SiteQueryInsight, AnnotationType } from '~/models'
import { PolygonRenderer3DView } from '~/components/polygon-renderer-3d'

export function useInsightLayers() {
  const { map, timeline, insights:insightState } = useAppState()
  const [polygonRenderers, setPolygonRenderers] = useState<PolygonRenderer3DView[]>([])
  const [insights, setInsights] = useState<SiteQueryInsight[]>(timeline.activeObservation?.insights || [])

  // Draw initial polygons.
  useEffect(() => {
    if (map.morphing || insights.length === 0 || insightState.insightToUpdate !== undefined) {
      setPolygonRenderers([])
      return
    }

    const newPolygonRenderers: PolygonRenderer3DView[] = []

    for (const insight of insights) {
      const haveChildren = false
      const pr = new PolygonRenderer3DView({
        id: insight.id,
        label: undefined,
        points: insight.aoi.map((p) => ({ ...p, altitude: 0 })),
        map,
        renderPoints: false,
        color: '#f00',//#057cb8',
        scaleByDistance: new Cesium.NearFarScalar(10, haveChildren ? 0.8 : 0.8, 40, 0.8),
        translucencyByDistance: new Cesium.NearFarScalar(120, 1, 180, 0),
        annotationType: AnnotationType.Area,
        fill: true,
        onClick: () => {
          insightState.setSelectedInsight(insight)
        },
        clamp: true,
      })

      pr.setVisible(insightState.draw)
      newPolygonRenderers.push(pr)
    }

    setPolygonRenderers(newPolygonRenderers)

    return () => {
      newPolygonRenderers.forEach((p) => p.destroy())
      setPolygonRenderers([])
    }
  }, [insights, insightState.insightToUpdate?.id, map.morphing])

  // Draw selected polygon
  useEffect(() => {
    if (map.morphing || !insightState.selectedInsight || insightState.insightToUpdate !== undefined) {
      return
    }

    const pr = new PolygonRenderer3DView({
      id: insightState.selectedInsight.id + '-selected',
      label: undefined,
      points: insightState.selectedInsight.aoi.map((p) => ({ ...p, altitude: 0 })),
      map,
      renderPoints: false,
      color: '#057cb8',
      annotationType: AnnotationType.Area,
      fill: false,
      onClick: () => {
        insightState.setSelectedInsight(undefined)
      },
    })

    const existingPrIndex = polygonRenderers.findIndex((p) => p.id === insightState.selectedInsight.id)
    if (existingPrIndex !== -1) {
      polygonRenderers[existingPrIndex].setVisible(false)
    }

    return () => {
      pr.destroy()
      if (existingPrIndex !== -1) {
        polygonRenderers[existingPrIndex].setVisible(true)
      }
    }
  }, [insightState.selectedInsight?.id, insightState.insightToUpdate?.id, map.morphing])

  useEffect(() => {
    if(map.morphing) {
      return
    }
    for (const p of polygonRenderers) {
      if (p.id !== insightState.selectedInsight?.id) {
        p.setVisible(insightState.draw)
        p.setFill(insightState.draw)
      }
    }
  }, [polygonRenderers, insightState.draw, insightState.selectedInsight, map.morphing])

  useEffect(() => {
    if(!Array.isArray(timeline.activeObservation?.insights)) {
      setInsights([])
    } else {
      if (timeline.activeObservation.insights.length === 0) {
        setInsights([])
      } else {
        setInsights(timeline.activeObservation.insights)
      }
    }

  }, [timeline.activeObservation?.insights])
}
