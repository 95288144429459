import React, { useState } from 'react'
import { SiteQueryAsset, SiteQuerySite } from '~/models'
import { ComponentsCreate } from './components-create'
import { ComponentsEdit } from './components-edit'
import { ClientPermission, useUser } from '~/base'
import { DrawerListContent, ItemTag, Transition } from '~/components'
import { ComponentFullList } from './component-full-list'
import { useAppState } from '~/state'
import { sortComponents } from './components-utils'
import { ExpandedPanel } from '~/state/use-drawer-state'
import { ComponentRemove } from './component-remove'

interface ComponentsProps {
  site?: SiteQuerySite
  asset?: SiteQueryAsset
  refetchAsset: () => Promise<any>
}

export const Components = (props: ComponentsProps) => {
  const user = useUser()
  const { drawer, components: componentState, timeline, view, site } = useAppState()
  const loaded = view.assetID ? !!props.asset : !!timeline.activeSurvey
  const components = loaded
    ? (view.assetID
        ? [...props.asset.components]
        : timeline.activeSurvey.assets.reduce((a, b) => {
            return [...a, ...b.components]
          }, [])
      ).sort(sortComponents)
    : []
  const haveComponents = components.length > 0
  const [show, setShow] = useState<boolean>(false)

  const isDemoSite = props.site?.isDemoSite
  const canViewComponents = user.hasPermission(ClientPermission.ComponentsRead)
  const canAddComponents = !isDemoSite && user.hasPermission(ClientPermission.ComponentsCreate)
  const haveAssetType = view.assetID && !!props.asset && typeof props.asset?.assetType?.id === 'string'

  if (!canViewComponents) {
    return null
  }

  return (
    <>
      <DrawerListContent
        canAdd={canAddComponents && haveAssetType}
        loading={loaded}
        numEntities={components.length}
        panelType={ExpandedPanel.Components}
        plural='components'
        singular='component'
        title='Components'
        onAdd={() => {
          setShow(true)
        }}
        emptyButCanAddText='Add a component'
        emptyButCantAddText={haveAssetType ? 'No components have been added' : 'Please update the asset type'}
      >
        <Transition width={120} in={loaded}>
          {haveComponents &&
            components
              .slice(0, 3)
              .map((c) => (
                <ItemTag
                  key={c.id}
                  title={(c.name || c.componentTypeName) + (c.children.length > 0 ? ` (${c.children.length})` : '')}
                  tagType='component'
                />
              ))}
        </Transition>
      </DrawerListContent>

      {show && (
        <ComponentsCreate
          site={props.site}
          asset={props.asset}
          onComplete={(component, changed, hide) => {
            // const p = changed ? props.refetchAsset() : Promise.resolve()
            // p.then(() => {
            //   if (hide) {
            //     setShow(false)
            //   }
            // })

            if (!changed) {
              if (hide) {
                setShow(false)
              }
              return
            }

            const newSite = { ...site.site }
            newSite.surveys = [...newSite.surveys]
            const activeSurveyIndex = newSite.surveys.findIndex((s) => s.id === view.surveyID)
            newSite.surveys[activeSurveyIndex] = { ...newSite.surveys[activeSurveyIndex] }
            newSite.surveys[activeSurveyIndex].assets = [...newSite.surveys[activeSurveyIndex].assets]
            const activeAssetIndex = newSite.surveys[activeSurveyIndex].assets.findIndex(
              (s) => s.id === component.assetID
            )
            newSite.surveys[activeSurveyIndex].assets[activeAssetIndex] = {
              ...newSite.surveys[activeSurveyIndex].assets[activeAssetIndex],
            }
            newSite.surveys[activeSurveyIndex].assets[activeAssetIndex].components = [
              ...newSite.surveys[activeSurveyIndex].assets[activeAssetIndex].components,
              component,
            ]

            site.setSite(newSite)

            if (hide) {
              setShow(false)
            }
          }}
        />
      )}
      {componentState.componentToUpdate && (
        <ComponentsEdit
          asset={timeline.activeSurvey.assets.find((a) => a.id === componentState.componentToUpdate.assetID)}
          component={componentState.componentToUpdate}
          onComplete={(changed) => {
            const p = changed ? props.refetchAsset() : Promise.resolve()
            p.then(() => {
              setShow(false)
              componentState.setComponentToUpdate(undefined)
            })
          }}
        />
      )}
      {componentState.componentToDelete && (
        <ComponentRemove
          component={componentState.componentToDelete}
          refetch={() => {
            return site.refetch().then(() => {
              if (components.length === 1) {
                drawer.closeExpandedPanel()
              }
            })
          }}
          close={() => {
            componentState.setComponentToDelete(undefined)
          }}
        />
      )}
      {drawer.expandedPanel === ExpandedPanel.Components && <ComponentFullList />}
    </>
  )
}
