import { useState, useEffect } from 'react'
import { useLocation, useRoute } from '../components/helpers'

interface ViewState {
  siteID?: string
  assetID?: string
  issueID?: string
  boundaryID?: string
  surveyID?: string
  observationID?: string
  insightID?: string
  taskID?: string
  taskOperation?: string
  parentCommentID?: string
  commentID?: string
  showingComments: boolean
  showingTasks: boolean
  lidarHash?: string
  groupID?: string
  showingGroups: boolean
}

function computeViewState(route: any): ViewState {
  const a = route.params['a']
  const b = route.params['b']
  const c = route.params['c']
  const d = route.params['d']
  const e = route.params['e']
  const f = route.params['f']
  const g = route.params['g']

  let siteID = ''
  let surveyID = ''
  let assetID = ''
  let issueID = ''
  let boundaryID = ''
  let taskID = ''
  let taskOperation = ''
  let parentCommentID = ''
  let commentID = ''
  let showingComments = b === 'comments' || c === 'comments' || e === 'comments' || g === 'comments'
  let showingTasks = false
  let showingGroups = false
  let observationID = ''
  let insightID = ''
  let lidarHash = ''
  let groupID = ''

  if (!a) {
    // Use defaults. This means we're viewing all sites.
  } else if (a === 'tasks') {
    taskID = b
    taskOperation = c
    showingTasks = true
  } else if (a === 'groups') {
    groupID = b
    showingGroups = true
  } else if (a === '_reports') {
    siteID = c
    surveyID = d
    assetID = e
  } else {
    // Viewing a site.
    siteID = a

    if (b === 'lidar') {
      lidarHash = c
    } else {
      surveyID = b

      if (c === 'assets') {
        assetID = d

        if (e === 'issue') {
          issueID = f
        }

        if (e === 'boundary') {
          boundaryID = f
        }

        if (e === 'tasks') {
          showingTasks = true
          taskID = f
          taskOperation = g
        }
      } else if (c === 'issue') {
        issueID = d
      } else if (c === 'boundary') {
        boundaryID = d
      } else if (c === 'comments') {
        commentID = d
      } else if (c === 'tasks') {
        showingTasks = true
        taskID = d
        taskOperation = e
      } else if (c === 'observation') {
        observationID = d
        if (e === 'insight') {
          insightID = f
        }
      }
    }
  }

  // console.trace({
  //   siteID,
  //   surveyID,
  //   assetID,
  //   issueID,
  //   commentID,
  //   parentCommentID,
  //   taskID,
  //   taskOperation,
  //   showingTasks,
  //   showingComments,
  // })

  return {
    siteID,
    surveyID,
    assetID,
    issueID,
    boundaryID,
    commentID,
    parentCommentID,
    taskID,
    taskOperation,
    showingTasks,
    showingComments,
    observationID,
    insightID,
    lidarHash,
    groupID,
    showingGroups,
  }
}

export function useViewState() {
  const location = useLocation()
  const [currentLocation, setCurrentLocation] = useState(location.location)

  const route = useRoute<{
    siteID?: string
    surveyID?: string
    entityType?: string
    entityID?: string
    entitySubType?: string
    entitySubID?: string
    operation?: string
  }>('/:a/:b?/:c?/:d?/:e?/:f?/:g?')

  const [currentState, setCurrentState] = useState<ViewState>(computeViewState(route))

  useEffect(() => {
    if (currentLocation !== location.location) {
      setCurrentLocation(location.location)
      setCurrentState(computeViewState(route))
    }
  }, [location.location])

  return currentState
}
