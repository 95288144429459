import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { search } from './search'
import { usePrevious } from './usePrevious'
import { FloatingInput } from '../floating-input/floating-input'
import { InputProps } from '../input'

interface GeoInputProps {
  inputValue?: string
  endpoint?: string
  source?: string
  inputPlaceholder?: string
  inputLabel?: string
  proximity?: { longitude: number; latitude: number }
  country?: string
  bbox?: number[]
  types?: string
  limit?: number
  autocomplete?: boolean
  language?: string
  focusOnMount?: boolean
  onSelect: (param: any) => void
  onSuggest?: (results: any[]) => void
  onInputBlur?: (event: any) => void
  onInputFocus?: (event: any) => void
  inputTextFieldProps?: InputProps
  helperText?: string
  className?: string
  zIndex?: number
}

export const GeoInput = ({
  helperText,
  endpoint = 'https://api.mapbox.com',
  inputPlaceholder = 'Search',
  source = 'mapbox.places',
  inputLabel,
  onSuggest,
  focusOnMount = false,
  inputValue = '',
  proximity,
  country,
  bbox,
  types,
  limit,
  autocomplete,
  language,
  onSelect,
  onInputFocus,
  onInputBlur,
  className,
  zIndex,
}: GeoInputProps) => {
  const [results, setResults] = useState<any[]>([])
  const [focused, setFocused] = useState<boolean>(false)
  const [searchTime, setSearchTime] = useState<Date>(new Date())
  const [value, setValue] = useState<string>(inputValue)
  const autoSuggestRef = useRef<Autosuggest>(null)
  const prevValue = usePrevious<string>(value)

  const focusInput = useCallback(() => {
    const { input = null } = autoSuggestRef.current || {}
    input && input.focus()
  }, [])

  useEffect(() => {
    setValue(inputValue)
  }, [inputValue])

  useEffect(() => {
    if (focusOnMount) {
      focusInput()
    }
  }, [focusOnMount, focusInput])

  useEffect(() => {
    onSuggest && onSuggest(results)
  }, [results, onSuggest])

  const renderInput = (renderInputProps: any) => {
    return React.forwardRef(() => <FloatingInput {...renderInputProps} />)
  }

  const focusInputHandler = useCallback(
    (e) => {
      onInputFocus && onInputFocus(e)
      setFocused(true)
    },
    [onInputFocus]
  )

  const blurInputHandler = useCallback(
    (e) => {
      onInputBlur && onInputBlur(e)
      setFocused(false)
    },
    [onInputBlur]
  )

  const renderSuggestionsContainer = useCallback((options) => {
    const { containerProps, children } = options
    return <div {...containerProps}>{children}</div>
  }, [])

  const onResult = useCallback(
    (err: any, fc: any, st: Date) => {
      /**
       * searchTime compares the last search to set the state
       * to ensure that a slow xhr response does not interfere
       * with the sequence of autocomplete display
       * and provides an auto-completing interface for finding locations.
       */
      if (!err && fc && fc.features && searchTime <= st) {
        setSearchTime(st)
        setResults(
          fc.features
            .map((feature: any) => ({
              feature: feature,
              label: feature.place_name,
            }))
            .filter((feature: any) => feature.label)
        )
      }
    },
    [searchTime]
  )

  const handleSuggestionsFetchRequested = useCallback(
    ({ value }) => {
      if (value === '') {
        setResults([])
      } else if (prevValue !== value) {
        search(endpoint, source, value, onResult, proximity, country, bbox, types, limit, autocomplete, language)
      }
    },
    [bbox, country, endpoint, limit, language, autocomplete, source, proximity, prevValue, onResult, types]
  )

  const handleSuggestionSelected = useCallback(
    (_event, { suggestion }) => {
      onSelect && onSelect(suggestion.feature)
      /**
       * focus on the input after click to maintain key traversal
       * this.inputRef.current && this.inputRef.current.focus()
       */
      return false
    },
    [onSelect]
  )

  const handleSuggestionsClearRequested = useCallback(() => {
    setResults([])
  }, [])

  const handleChange = useCallback((_event, { newValue }) => {
    setValue(newValue)
  }, [])

  const renderSuggestion = useCallback((suggestion, { query }) => {
    const matches = match(suggestion.label, query)
    const parts = parse(suggestion.label, matches)

    return (
      <div>
        <div className='body2'>
          {parts.map((part: { highlight: boolean; text: string }, index: number) => {
            return part.highlight ? (
              <span key={String(index)} style={{ fontWeight: 500 }}>
                {part.text}
              </span>
            ) : (
              <strong key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            )
          })}
        </div>
      </div>
    )
  }, [])

  const getResultValue = useCallback((result: any) => result.label, [])

  const autoSuggestEl = useMemo(
    () => (
      <div style={{ zIndex: zIndex || 3 }} className={className}>
        <Autosuggest
          ref={autoSuggestRef}
          renderInputComponent={(props) => {
            const newProps = { ...props }
            delete newProps['ref']
            return <FloatingInput {...(newProps as any)} inputRef={props.ref} />
          }}
          suggestions={results}
          onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={handleSuggestionsClearRequested}
          onSuggestionSelected={handleSuggestionSelected}
          renderSuggestionsContainer={renderSuggestionsContainer}
          getSuggestionValue={getResultValue}
          renderSuggestion={renderSuggestion}
          inputProps={
            {
              placeholder: inputPlaceholder || 'Search Address',
              label: focused ? inputLabel || 'Search Address' : '',
              value: value,
              onChange: handleChange,
              onFocus: focusInputHandler,
              onBlur: blurInputHandler,
              className: 'form-field-input',
              id: 'address',
            } as any
          }
        />
        {helperText && <small className='geo-input-helper-text'>{helperText}</small>}
      </div>
    ),
    [
      blurInputHandler,
      handleChange,
      handleSuggestionSelected,
      focusInputHandler,
      handleSuggestionsFetchRequested,
      handleSuggestionsClearRequested,
      renderInput,
      inputPlaceholder,
      renderSuggestionsContainer,
      renderSuggestion,
      results,
      value,
      getResultValue,
    ]
  )
  return autoSuggestEl
}
