import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useMutation } from '~/components'
import { Avatar, classes, fullDate, SimpleBar, timeSince, useLocation } from '~/components'
import {
  NotificationData,
  NotificationDataCommentRepliedT,
  NotificationDataCommentTaggedT,
  NotificationDataInsightCreatedT,
  NotificationDataTaskScheduledAndAssignedT,
  NotificationMarkReadMutation,
  NotificationMarkReadMutationVariables,
  NotificationsQuery,
} from '~/models'
import { useAppState } from '~/state'
import NOTIFICATION_MARK_READ_MUTATION from './mutation-notifications-mark-read.gql'

let MarkdownViewerImpl: React.FC<{ text: string }> = undefined

const MarkdownViewer = ({ text }: { text: string }) => {
  const [loading, setLoading] = useState(MarkdownViewerImpl === undefined)

  useEffect(() => {
    if (loading) {
      import('../components/markdown/markdown-viewer').then((res) => {
        MarkdownViewerImpl = res.default
        setLoading(false)
      })
    }
  }, [])

  if (MarkdownViewerImpl) {
    return <MarkdownViewerImpl text={text} />
  }

  return <div></div>
}

interface NotificationCardCommentTaggedProps {
  n: NotificationDataCommentTaggedT
}

const NotificationCardCommentTagged = (props: NotificationCardCommentTaggedProps) => {
  const location = useLocation()
  const { timeline } = useAppState()

  const goToSite = () => {
    location.setLocation(`/${props.n.siteID}/${timeline.activeSurvey.id}`)
  }

  const goToAsset = () => {
    location.setLocation(`/${props.n.siteID}/${timeline.activeSurvey.id}/assets/${props.n.assetID}`)
  }

  const goToIssue = () => {
    if (props.n.assetID) {
      location.setLocation(
        `/${props.n.siteID}/${timeline.activeSurvey.id}/assets/${props.n.assetID}/issue/${props.n.issueID}`
      )
    } else {
      location.setLocation(`/${props.n.siteID}/${timeline.activeSurvey.id}/issue/${props.n.issueID}`)
    }
  }

  const goToComment = () => {
    if (props.n.issueID) {
      if (props.n.assetID) {
        location.setLocation(
          `/${props.n.siteID}/${timeline.activeSurvey.id}/assets/${props.n.assetID}/issue/${props.n.issueID}/comments`
        )
      } else {
        location.setLocation(`/${props.n.siteID}/${timeline.activeSurvey.id}/issue/${props.n.issueID}/comments`)
      }
    } else if (props.n.assetID) {
      location.setLocation(`/${props.n.siteID}/${timeline.activeSurvey.id}/assets/${props.n.assetID}/comments`)
    } else {
      if (props.n.parentCommentID) {
        location.setLocation(
          `/${props.n.siteID}/${timeline.activeSurvey.id}/comments/${props.n.parentCommentID}/${props.n.commentID}`
        )
      } else {
        location.setLocation(`/${props.n.siteID}/${timeline.activeSurvey.id}/comments/${props.n.commentID}`)
      }
    }
  }

  return (
    <p>
      {props.n.taggedByUserName} tagged you in a{' '}
      <span onClick={goToComment} className='activity-card-link'>
        comment
      </span>{' '}
      for the{' '}
      {props.n.issueName ? (
        <>
          issue{' '}
          <span onClick={goToIssue} className='activity-card-link'>
            #{props.n.issueName}
          </span>
        </>
      ) : props.n.assetName ? (
        <>
          asset{' '}
          <span onClick={goToAsset} className='activity-card-link'>
            {props.n.assetName}
          </span>{' '}
          at the site{' '}
          <span onClick={goToSite} className='activity-card-link'>
            {props.n.siteName}
          </span>
        </>
      ) : (
        <>
          site{' '}
          <span onClick={goToSite} className='activity-card-link'>
            {props.n.siteName}
          </span>{' '}
        </>
      )}
      {props.n.commentText ? (
        <div className='notifications-card-sub-message'>
          <MarkdownViewer text={props.n.commentText} />
        </div>
      ) : null}
    </p>
  )
}

interface NotificationCardCommentRepliedProps {
  n: NotificationDataCommentRepliedT
}

const NotificationCardCommentReplied = (props: NotificationCardCommentRepliedProps) => {
  const location = useLocation()
  const { timeline } = useAppState()

  const goToSite = () => {
    location.setLocation(`/${props.n.siteID}/${timeline.activeSurvey.id}`)
  }

  const goToAsset = () => {
    location.setLocation(`/${props.n.siteID}/${timeline.activeSurvey.id}/assets/${props.n.assetID}`)
  }

  const goToIssue = () => {
    if (props.n.assetID) {
      location.setLocation(
        `/${props.n.siteID}/${timeline.activeSurvey.id}/assets/${props.n.assetID}/issue/${props.n.issueID}`
      )
    } else {
      location.setLocation(`/${props.n.siteID}/${timeline.activeSurvey.id}/issue/${props.n.issueID}`)
    }
  }

  const goToComment = () => {
    if (props.n.issueID) {
      if (props.n.assetID) {
        location.setLocation(
          `/${props.n.siteID}/${timeline.activeSurvey.id}/assets/${props.n.assetID}/issue/${props.n.issueID}/comments`
        )
      } else {
        location.setLocation(`/${props.n.siteID}/${timeline.activeSurvey.id}/issue/${props.n.issueID}/comments`)
      }
    } else if (props.n.assetID) {
      location.setLocation(`/${props.n.siteID}/${timeline.activeSurvey.id}/assets/${props.n.assetID}/comments`)
    } else {
      if (props.n.parentCommentID) {
        location.setLocation(
          `/${props.n.siteID}/${timeline.activeSurvey.id}/comments/${props.n.parentCommentID}/${props.n.commentID}`
        )
      } else {
        location.setLocation(`/${props.n.siteID}/${timeline.activeSurvey.id}/comments/${props.n.commentID}`)
      }
    }
  }

  return (
    <p>
      {props.n.repliedByUserName} replied to your comment{' '}
      <span onClick={goToComment} className='activity-card-link'>
        comment
      </span>{' '}
      for the{' '}
      {props.n.issueName ? (
        <>
          issue{' '}
          <span onClick={goToIssue} className='activity-card-link'>
            #{props.n.issueName}
          </span>
        </>
      ) : props.n.assetName ? (
        <>
          asset{' '}
          <span onClick={goToAsset} className='activity-card-link'>
            {props.n.assetName}
          </span>{' '}
          at the site{' '}
          <span onClick={goToSite} className='activity-card-link'>
            {props.n.siteName}
          </span>
        </>
      ) : (
        <>
          site{' '}
          <span onClick={goToSite} className='activity-card-link'>
            {props.n.siteName}
          </span>{' '}
        </>
      )}
      {props.n.commentText ? (
        <div className='notifications-card-sub-message'>
          <MarkdownViewer text={props.n.commentText} />
        </div>
      ) : null}
    </p>
  )
}

interface NotificationCardTaskScheduledProps {
  n: NotificationDataTaskScheduledAndAssignedT
}

const NotificationCardTaskScheduled = (props: NotificationCardTaskScheduledProps) => {
  const location = useLocation()

  const goToTask = () => {
    location.setLocation(`/tasks/${props.n.taskID}`)
  }

  return (
    <p>
      New items for task{' '}
      <span onClick={goToTask} className='activity-card-link'>
        {props.n.taskName}
      </span>{' '}
      were assigned to you
    </p>
  )
}

interface NotificationCardInsightCreatedProps {
  n: NotificationDataInsightCreatedT
}

const NotificationCardInsightCreated = (props: NotificationCardInsightCreatedProps) => {
  const location = useLocation()

  const goToInsight = () => {
    location.setLocation(
      `/${props.n.siteID}/${props.n.surveyID}/observation/${props.n.observationID}/insight/${props.n.insightID}`
    )
  }
  const goToAsset = () => {
    location.setLocation(`/${props.n.siteID}/${props.n.surveyID}/assets/${props.n.assetID}`)
  }
  const goToSite = () => {
    location.setLocation(`/${props.n.siteID}/${props.n.surveyID}`)
  }

  if (props.n.assetID !== '') {
    return (
      <p>
        An{' '}
        <span onClick={goToInsight} className='activity-card-link'>
          insight
        </span>{' '}
        was created for the asset{' '}
        <span onClick={goToAsset} className='activity-card-link'>
          {props.n.assetName}
        </span>{' '}
        in the site{' '}
        <span onClick={goToSite} className='activity-card-link'>
          {props.n.siteName}
        </span>{' '}
        for an observation on {dayjs(props.n.observationDate / 1000000).format('DD MMMM YYYY')}
      </p>
    )
  }

  return (
    <p>
      An{' '}
      <span onClick={goToInsight} className='activity-card-link'>
        insight
      </span>{' '}
      was created in the site{' '}
      <span onClick={goToSite} className='activity-card-link'>
        {props.n.siteName}
      </span>{' '}
      for an observation on {dayjs(props.n.observationDate / 1000000).format('DD MMMM YYYY')}
    </p>
  )
}

interface NotificationCardProps {
  n: NotificationsQuery['notifications']['notifications'][0]
  reload: () => Promise<void>
}

const NotificationCard = (props: NotificationCardProps) => {
  const [markingRead, setMarkingRead] = useState(false)
  const [executeMarkReadMutation] = useMutation<NotificationMarkReadMutation, NotificationMarkReadMutationVariables>(
    NOTIFICATION_MARK_READ_MUTATION
  )

  return (
    <div className='notifications-container-feed-item'>
      <div
        className={classes({
          'notifications-card': true,
          unread: !props.n.read,
        })}
      >
        <div className='notifications-card-timestamp' title={props.n.createdAt ? fullDate(props.n.createdAt) : ''}>
          <div>{timeSince(props.n.createdAt)}</div>
          {!props.n.read && (
            <div
              className={markingRead ? 'disabled' : ''}
              title='Dismiss'
              onClick={() => {
                setMarkingRead(true)
                executeMarkReadMutation({
                  variables: {
                    input: {
                      notificationID: props.n.id,
                    },
                  },
                })
                  .then(() => {
                    return props.reload()
                  })
                  .finally(() => {
                    setMarkingRead(false)
                  })
              }}
            >
              <i className='material-icons'>check</i>
            </div>
          )}
        </div>
        <div className='notifications-card-avatar-row'>
          <Avatar className='notifications-card-avatar' width={32} height={32} title='' />
          <div className='notifications-card-activity'>
            {props.n.data.type === NotificationData.NotificationDataCommentTagged && (
              <NotificationCardCommentTagged n={props.n.data.value as NotificationDataCommentTaggedT} />
            )}
            {props.n.data.type === NotificationData.NotificationDataCommentReplied && (
              <NotificationCardCommentReplied n={props.n.data.value as NotificationDataCommentRepliedT} />
            )}
            {props.n.data.type === NotificationData.NotificationDataTaskScheduledAndAssigned && (
              <NotificationCardTaskScheduled n={props.n.data.value as NotificationDataTaskScheduledAndAssignedT} />
            )}
            {props.n.data.type === NotificationData.NotificationDataInsightCreated && (
              <NotificationCardInsightCreated n={props.n.data.value as NotificationDataInsightCreatedT} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export const Notifications = () => {
  const { notifications } = useAppState()

  useEffect(() => {
    notifications.reload()
  }, [])

  return (
    <div className='notifications-container'>
      <div className='notifications-container-feed'>
        <h6 className='notifications-container-feed-title'>Notifications</h6>
        <SimpleBar>
          <div className='notifications-container-feed-items'>
            {notifications.notifications.map((n) => {
              return <NotificationCard key={n.id} n={n} reload={notifications.reload} />
            })}
            {notifications.notifications.length === 0 && (
              <div style={{ height: 'auto', cursor: 'default', marginBottom: 10 }} className='no-item-tag disabled'>
                No notifications are available.
              </div>
            )}
          </div>
        </SimpleBar>
      </div>
    </div>
  )
}
