import { useQuery } from '~/components'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from '~/components'
import {
  ImageTimelineQuery,
  ImageTimelineQueryVariables,
  QueryImageTimelineBySiteItemT,
  ReconstructionState,
  SiteQueryImageTimelineItem,
  SiteQueryLidar,
  SiteQueryObservation,
  SiteQueryRecon,
  SiteQuerySatelliteTimelineItem,
  SiteQuerySurvey,
} from '~/models'
import { useExtendedSite } from './use-extended-site'
import IMAGE_TIMELINE_QUERY from './query-image-timeline.gql'
import { useCesium } from './use-cesium'

function getMonths(
  surveys: SiteQuerySurvey[],
  images: Pick<QueryImageTimelineBySiteItemT, 'day' | 'numImages' | 'hashes'>[],
  observations: SiteQueryObservation[]
) {
  const allDates = surveys
    .map((s) => s.startDate / 1000 / 1000)
    .concat(
      images.map((i) => {
        return dayjs(i.day, {
          format: 'YYYYMMDD',
        }).valueOf()
      })
    )
    .concat(
      observations.map((i) => {
        return i.date / 1000 / 1000
      })
    )

  allDates.sort((a, b) => {
    return a < b ? -1 : 1
  })

  const startDate = dayjs(allDates[0]).startOf('month')
  const endDate = dayjs().endOf('month')

  const months = []
  let currentDate = startDate
  while (currentDate <= endDate) {
    months.push(currentDate)
    currentDate = currentDate.add(1, 'month')
  }

  return months
}

export function useTimeline(
  surveyID?: string,
  observationID?: string,
  lidarHash?: string,
  site?: ReturnType<typeof useExtendedSite>,
  map?: ReturnType<typeof useCesium>,
  issueID?: string
) {
  const [visible, setVisible] = useState(false)
  const [hidden, setHidden] = useState(false)
  const [entrySize, setEntrySize] = useState<number>(60)
  const [months, setMonths] = useState<Dayjs[]>([])
  const [activeSurvey, setActiveSurvey] = useState<SiteQuerySurvey | undefined>()
  const [activeObservation, setActiveObservation] = useState<SiteQueryObservation | undefined>()
  const [activeImagesBySurveyID, setActiveImagesBySurveyID] = useState<{
    [id: string]: SiteQueryImageTimelineItem[]
  }>({})
  const [surveys, setSurveys] = useState<SiteQuerySurvey[]>([])
  const [observations, setObservations] = useState<SiteQueryObservation[]>([])
  const [imageTimeline, setImageTimeline] = useState<SiteQueryImageTimelineItem[]>([])
  const [satelliteTimeline, setSatelliteTimeline] = useState<SiteQuerySatelliteTimelineItem[]>([])
  const [lidarTimelime, setLidarTimeline] = useState<SiteQueryLidar[]>([])
  const location = useLocation()
  const [activeSatellite, setActiveSatellite] = useState<SiteQuerySatelliteTimelineItem>()
  const [activeLidar, setActiveLidar] = useState<SiteQueryLidar>()
  const [activeInteriorModel, setActiveInteriorModel] = useState<SiteQueryRecon>()

  const imageTimelineQuery = useQuery<ImageTimelineQuery, ImageTimelineQueryVariables>(IMAGE_TIMELINE_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!site.site || !map.initialized) {
      return
    }

    imageTimelineQuery
      .refetch({
        input: {
          siteID: site.site.id,
        },
        input2: {
          siteID: site.site.id,
        },
      })
      .then((res) => {
        if (!res.data) {
          console.log(JSON.stringify(res, null, 2))
        }
        const images = res.data.queryImageTimeline.days.reduce((a, b) => {
          const inAIndex = a.findIndex((x) => x.day === b.day)
          if (inAIndex !== -1) {
            a[inAIndex] = { ...a[inAIndex] }
            a[inAIndex].numImages += b.numImages
            a[inAIndex].hashes = [...a[inAIndex].hashes, ...b.hashes]
          } else {
            return [...a, b]
          }

          return a
        }, [])

        setImageTimeline(
          images.sort((a, b) => {
            return dayjs(a.day).isBefore(dayjs(b.day)) ? -1 : 1
          })
        )
        setSatelliteTimeline(
          [...res.data.satelliteImagesForSite].sort((a, b) => {
            return a.date >= b.date ? 1 : -1
          })
        )
        setLidarTimeline(
          [...site.site.lidar].sort((a, b) => {
            return a.dateTaken >= b.dateTaken ? 1 : -1
          })
        )
      })

    return () => {
      setImageTimeline([])
      setSatelliteTimeline([])
      setLidarTimeline([])
    }
  }, [site.site?.id, map.initialized])

  const reset = () => {
    setSurveys([])
    setObservations([])
    setMonths([])
    setActiveSurvey(undefined)
    setActiveObservation(undefined)
    setVisible(false)
  }

  // Sets the surveys when the site loads.
  const setSurveysOnDataLoad = () => {
    if (!site.site) {
      reset()
      return
    }

    // Sort the surveys.
    const unsortedSurveys = [...(site.site.surveys || [])]
    const surveys = unsortedSurveys.sort((a, b) => {
      return a.endDate >= b.endDate ? 1 : -1
    })

    if (surveys.length === 1 && surveys[0].startDate === 0 && surveys[0].endDate === 0) {
      if (Array.isArray(imageTimeline) && imageTimeline.length > 0) {
        surveys[0] = {
          ...surveys[0],
          startDate: dayjs(imageTimeline[0].day).unix() * 1000 * 1000 * 1000,
          endDate: dayjs(imageTimeline[0].day).unix() * 1000 * 1000 * 1000,
        }
      } else {
        surveys[0] = {
          ...surveys[0],
          startDate: dayjs().unix() * 1000 * 1000 * 1000,
          endDate: dayjs().unix() * 1000 * 1000 * 1000,
        }
      }
    }

    // If we don't have surveys or images, hide the timeline.
    if (unsortedSurveys.length === 0 && imageTimeline.length === 0) {
      reset()
      return
    }

    const unsortedObservations = [...(site.site.observations || [])]
    const observations = unsortedObservations
      .sort((a, b) => {
        return a.date >= b.date ? 1 : -1
      })
      .map((o) => ({ ...o }))

    observations.forEach((o) => {
      o.insights = [...o.insights]
      o.insights.sort((a, b) => {
        if (a.archived && !b.archived) {
          return 1
        }
        if (!a.archived && b.archived) {
          return -1
        }
        if (a.updatedAt > b.updatedAt) {
          return 1
        }

        return -1
      })
    })

    const months = getMonths(surveys, imageTimeline, observations)
    setEntrySize(Math.floor((window.innerWidth - 480 - 80) / months.length))
    setMonths(months)
    setSurveys(surveys)
    setObservations(observations)
    setVisible(true)
  }

  // Sets the active survey based on the survey in the URL.
  const setActiveSurveyBasedOnLocation = () => {
    if (!surveys || surveys.length === 0 || !site.site) {
      return
    }

    // Set the survey based on the view state.
    const survey = surveys.find((s) => s.id === surveyID)
    const observation = observations.find((o) => o.id === observationID)
    if (surveyID && survey) {
      if (JSON.stringify(survey) !== JSON.stringify(activeSurvey)) {
        setActiveSurvey(survey)
      }
      if (JSON.stringify(observation) !== JSON.stringify(activeObservation)) {
        setActiveObservation(observation)
      }
      if (!location.location.includes(survey.id)) {
        if (observationID) {
          if (!location.location.includes(survey.id)) {
            location.setLocation(
              location.location.replace(
                `/${site.site.id}/${surveyID}/observation/${observationID}`,
                `/${site.site.id}/${survey.id}/observation/${observation.id}`
              )
            )
          }
        } else {
          location.setLocation(
            location.location.replace(`/${site.site.id}/${surveyID}`, `/${site.site.id}/${survey.id}`)
          )
        }
      }

      return
    }

    // By default, set the active survey to the latest survey.
    let lastSurvey = surveys[site.site.surveys.length - 1]
    if (lastSurvey && surveys.length > 1) {
      if (lastSurvey.reconstructions.findIndex((x) => x.ortho?.state === ReconstructionState.Processed) === -1) {
        lastSurvey = surveys[site.site.surveys.length - 2]
        setActiveSurvey(lastSurvey)
      }
    } else {
      setActiveSurvey(lastSurvey)
    }
    if (!window.location.pathname.includes('_reports')) {
      location.setLocation(`/${site.site.id}/${lastSurvey.id}`)

      if (lidarHash && lidarHash !== '') {
        const lidar = site.site.lidar.find((l) => l.hash === lidarHash)
        if (lidar) {
          setActiveLidar(lidar)
          setTimeout(() => {
            map.setShowLidar(true)
          }, 500)
        }
      }
    }
  }

  useEffect(setSurveysOnDataLoad, [site.site, imageTimeline, satelliteTimeline])
  useEffect(setActiveSurveyBasedOnLocation, [surveyID, observationID, observations, surveys, site.site])
  useEffect(() => {
    if (!surveys || surveys.length === 0 || !site.site) {
      return
    }

    const setImagesFromActiveSurvey = (survey: SiteQuerySurvey) => {
      if (!survey) {
        return []
      }

      const unsortedImages = [...(imageTimeline || [])]
      const sortedImages = unsortedImages.sort((a, b) => {
        return dayjs(a.day).isBefore(dayjs(b.day)) ? -1 : 1
      })

      const surveyStartDate = dayjs(survey.startDate / 1000 / 1000).startOf('day')
      const surveyEndDate = dayjs(survey.endDate / 1000 / 1000).startOf('day')
      const surveyIndex = surveys.findIndex((s) => s.id === survey.id)
      const isLastSurvey = surveyIndex === surveys.length - 1

      const filteredImages = sortedImages.filter((i) => {
        const imageDate = dayjs(i.day).startOf('day')

        const isBeforeSurvey = imageDate.isBefore(surveyEndDate)
        const isSameAsSurvey = imageDate.isSame(surveyEndDate)
        const isAfterSurvey = imageDate.isAfter(surveyEndDate)

        if (isSameAsSurvey) {
          return true
        } else if (isBeforeSurvey) {
          if (imageDate.isSame(surveyStartDate) || imageDate.isAfter(surveyStartDate)) {
            return true
          }
          return false
        } else if (isAfterSurvey) {
          if (isLastSurvey) {
            return true
          }

          const nextSurveyDate = dayjs(surveys[surveyIndex + 1].startDate / 1000 / 1000).startOf('day')
          if (imageDate.isSame(nextSurveyDate)) {
            return false
          }
          return imageDate.isBefore(nextSurveyDate)
        }

        return false
      })

      return filteredImages.reduce((a, b) => {
        const inAIndex = a.findIndex((x) => x.day === b.day)
        if (inAIndex !== -1) {
          a[inAIndex] = { ...a[inAIndex] }
          a[inAIndex].numImages += b.numImages
          a[inAIndex].hashes = [...a[inAIndex].hashes, ...b.hashes]
        } else {
          return [...a, b]
        }

        return a
      }, [])
    }

    const newActiveImagesBySurvey: {
      [id: string]: SiteQueryImageTimelineItem[]
    } = {}

    for (const survey of surveys) {
      const filteredImages = setImagesFromActiveSurvey(survey)
      if (filteredImages.length === 0) {
        newActiveImagesBySurvey[survey.id] = undefined
      } else if (JSON.stringify(filteredImages) !== JSON.stringify(activeImagesBySurveyID[survey.id])) {
        newActiveImagesBySurvey[survey.id] = filteredImages
      }
    }

    if (Object.keys(newActiveImagesBySurvey).length !== 0) {
      setActiveImagesBySurveyID(newActiveImagesBySurvey)
    }
  }, [surveys, imageTimeline])

  const { isLastSurvey, isFirstSurvey, previousSurvey, previousSurveys } = useMemo(() => {
    if (surveys.length === 0 || !activeSurvey) {
      return {
        isLastSurvey: false,
        isFirstSurvey: false,
        previousSurvey: undefined,
        previousSurveys: [],
      }
    }

    const isFirstSurvey = activeSurvey.id === surveys[0].id
    const isLastSurvey = activeSurvey.id === surveys[surveys.length - 1].id
    let previousSurvey: SiteQuerySurvey = undefined
    let previousSurveys: SiteQuerySurvey[] = []

    if (!isFirstSurvey) {
      const activeSurveyIndex = surveys.findIndex((x) => x.id === activeSurvey.id)
      previousSurvey = surveys[activeSurveyIndex - 1]
      previousSurveys = [...surveys].splice(0, activeSurveyIndex)
    }

    return {
      isLastSurvey,
      isFirstSurvey,
      previousSurvey,
      previousSurveys,
    }
  }, [activeSurvey])

  return {
    visible: visible && !hidden,
    months,
    setActiveDate: (d: dayjs.Dayjs) => {
      const observation = [...observations].reverse().find((s) => {
        const startDate = dayjs(s.date / 1000 / 1000)
        return d.isSame(startDate) || d.isAfter(startDate)
      })

      const survey = [...surveys].reverse().find((s) => {
        const startDate = dayjs(s.startDate / 1000 / 1000)
        return d.isSame(startDate) || d.isAfter(startDate)
      })
      if (observation) {
        if (survey) {
          const surveyDate = dayjs(survey.startDate / 1000 / 1000)
          const observationDate = dayjs(observation.date / 1000 / 1000)
          if (surveyDate.isAfter(observationDate)) {
            location.setLocation(`/${site.site.id}/${survey.id}`)
          } else {
            location.setLocation(`/${site.site.id}/${surveyID}/observation/${observation.id}`)
          }

          return
        }
      }
      if (survey) {
        if (survey.id !== activeSurvey?.id) {
          location.setLocation(
            location.location.replace(`/${site.site.id}/${surveyID}`, `/${site.site.id}/${survey.id}`)
          )
        }
      } else {
        //location.setLocation(location.location.replace(`/${site.site.id}/${surveyID}`, `/${site.site.id}`))
      }
    },
    activeSurvey,
    surveys,
    imageTimeline,
    setImageTimeline,
    satelliteTimeline,
    lidarTimelime,
    activeImages: activeImagesBySurveyID[activeSurvey?.id],
    activeImagesBySurveyID,
    entrySize,
    setEntrySize,
    setHidden,
    isLastSurvey,
    isFirstSurvey,
    previousSurvey,
    previousSurveys,
    activeSatellite,
    setActiveSatellite,
    observations,
    activeObservation,
    setActiveObservation,
    activeLidar,
    setActiveLidar,
    activeInteriorModel,
    setActiveInteriorModel: (r: SiteQueryRecon) => {
      if (r) {
        const longitude = r.matterport.originX + Cesium.Math.toDegrees(r.matterport.translationX)
        const latitude = r.matterport.originY + Cesium.Math.toDegrees(r.matterport.translationY)
        const carto = Cesium.Cartographic.fromDegrees(longitude, latitude)

        let assetID = ''
        for (const asset of activeSurvey.assets) {
          for (const b of asset.boundaries) {
            const rect = Cesium.Rectangle.fromCartographicArray(
              b.points.map((p) => Cesium.Cartographic.fromDegrees(p.longitude, p.latitude))
            )
            if (Cesium.Rectangle.contains(rect, carto)) {
              assetID = asset.id
              break
            }
          }

          if (assetID !== '') {
            break
          }
        }

        if (assetID !== '') {
          if (issueID !== '') {
            location.setLocation(`/${site.site.id}/${activeSurvey.id}/assets/${assetID}/issue/${issueID}`)
          } else {
            location.setLocation(`/${site.site.id}/${activeSurvey.id}/assets/${assetID}`)
          }
        }
      }

      setActiveInteriorModel(r)
    },
  }
}
