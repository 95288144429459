import React, { useState } from 'react'
import { useAppState } from '~/state'

import {
  RemoveBoundaryMutation,
  RemoveBoundaryMutationVariables,
  SiteQueryBoundary,
  SurveyBasedOperation,
} from '~/models'
import REMOVE_BOUNDARY from './mutation-boundary-remove.gql'
import { OperationTypeSelect } from '../operation-type'
import { useMutation } from '../data'
import { getTracking } from '../tracking'

interface BoundariesRemoveProps {
  boundary: SiteQueryBoundary
  refetch: () => Promise<void>
  close: () => void
}

export const BoundariesRemove = (props: BoundariesRemoveProps) => {
  const { timeline, view } = useAppState()
  const [deleting, setDeleting] = useState(false)
  const [operationType, setOperationType] = useState(SurveyBasedOperation.GoingForward)
  const [removeBoundary] = useMutation<RemoveBoundaryMutation, RemoveBoundaryMutationVariables>(REMOVE_BOUNDARY)

  if(!props.boundary) {
    return null
  }
  
  return (
    <div className='boundaries-draw'>
      <div
        className='item-tag-delete-confirm'
        style={{ backgroundColor: 'rgb(43, 39, 40)', justifyContent: 'unset', paddingTop: '2rem' }}
      >
        <h6>Delete {props.boundary.name}?</h6>
        <OperationTypeSelect
          name='operationType'
          selectedValue={operationType}
          onChange={(e) => {
            setOperationType((e.target as any).value)
          }}
          survey={timeline.activeSurvey}
        />
        <button
          className='item-tag-delete-confirm-delete btn'
          disabled={deleting}
          onClick={() => {
            setDeleting(true)
            removeBoundary({
              variables: {
                input: {
                  boundaryID: props.boundary.id,
                  surveyID: timeline.activeSurvey.id,
                  operationType,
                  assetID: view.assetID || '',
                  siteID: view.siteID,
                },
              },
            })
              .then(() => {
                getTracking().event({
                  category: 'Boundary',
                  action: `User Deleted Boundary`,
                  label: 'Boundary',
                })
                return props.refetch().then(() => {
                  props.close()
                })
              })
              .finally(() => {
                setDeleting(false)
              })
          }}
        >
          {deleting ? 'Deleting...' : 'Yes, delete'}
        </button>
        <button
          className='item-tag-delete-confirm-cancel btn'
          disabled={deleting}
          onClick={props.close}
        >
          Cancel
        </button>
        <p>Caution: This cannot be undone.</p>
      </div>
      <div></div>
    </div>
  )
}
