import OpenSeadragon, { Point, Rect } from 'openseadragon'
import { PolygonShape } from '~/map/image-tagger-shape'
import { DrawEndCallback, FrontCanvas } from '../canvases/front-canvas'
import { BaseShape } from '../shapes/base-shape'
import { SelectionBase } from './selection-base'

export class ShapeSelection extends SelectionBase<BaseShape> {
  private unsubscribe?: () => void
  constructor(private frontCanvas: FrontCanvas, onSelection: (rect: Rect, shape: BaseShape) => void) {
    super(onSelection)
  }

  enable(sel: (rect: Rect, shape: BaseShape) => void) {
    this.frontCanvas.drawer.shapeChanged = sel
    super.enable(sel)
    this.unsubscribe = this.frontCanvas.onDrawEnd(this.onDrawDone.bind(this) as DrawEndCallback)
    this.frontCanvas.activate()
  }

  enableWithPoints(sel: (rect: Rect, shape: BaseShape) => void, points: Point[], viewer: OpenSeadragon.Viewer) {
    this.enable(sel)
    const ps = new PolygonShape(viewer, sel)
    ps.setEditing()
    ps.setPoints(points)

    this.frontCanvas.add(ps)
    this.frontCanvas.drawer.activeShape = ps
    this.frontCanvas.drawer.activeShape.startDrawing()
    this.frontCanvas.requestUpdate()
  }

  disable() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
    super.disable()
    this.frontCanvas.deactivate()
  }

  onDrawDone(shape: BaseShape) {
    this.disable()
    if (this.onSelection) {
      this.onSelection(shape.boundingBox, shape)
    }
  }
}
