import React from 'react'
import { ClientPermission, useUser } from '~/base'
import { useAppState } from '~/state'

interface CommentsIconProps {
  count: number
}

export const CommentsIcon = (props: CommentsIconProps) => {
  const { comments } = useAppState()
  const user = useUser()
  const canSeeComments = user.hasPermission(ClientPermission.CommentsRead) && !user.org.isBasic
  if (!canSeeComments) {
    return null
  }

  return (
    <span className='comments-icon' onClick={comments.toggle}>
      <i className='material-icons'>comment</i>
      <span>{typeof props.count === 'undefined' ? ' ' : props.count}</span>
    </span>
  )
}
