import { useState } from 'react'
import { DrawerState } from '../components/drawer'

interface CombinedDrawerState {
  left: DrawerState
  right: DrawerState
}

export enum ExpandedPanel {
  None,
  Asset,
  Issues,
  Insights,
  Photos,
  Annotations,
  Boundaries,
  MonitoringZones,
  Components,
  Analysis,
}

export enum ViewStateType {
  Activity = 'activity',
  Notifications = 'notifications',
  Analytics = 'analytics',
  Support = 'support',
  Account = 'account',
  Weather = 'weather',
}

export function useDrawerState() {
  const [drawerState, setDrawerState] = useState<CombinedDrawerState>({
    left: DrawerState.Normal,
    right: DrawerState.Normal,
  })
  const [expandedPanel, setExpandedPanel] = useState<ExpandedPanel>(ExpandedPanel.None)
  const [rightView, setRightView] = useState<ViewStateType>(ViewStateType.Activity)

  const leftExpanded = drawerState.left === DrawerState.Expanded
  const rightExpanded = drawerState.right === DrawerState.Expanded
  const leftClosed = drawerState.left === DrawerState.Hidden
  const rightClosed = drawerState.right === DrawerState.Hidden

  return {
    ...drawerState,
    set: (left: DrawerState, right: DrawerState) => {
      setDrawerState({
        left,
        right,
      })
    },
    toggleLeft: () => {
      if (leftExpanded) {
        setDrawerState({
          left: DrawerState.Normal,
          right: DrawerState.Normal,
        })
      } else {
        setDrawerState({
          left: DrawerState.Expanded,
          right: DrawerState.Normal,
        })
      }
    },
    toggleRight: () => {
      if (rightExpanded) {
        setDrawerState({
          left: DrawerState.Normal,
          right: DrawerState.Normal,
        })
      } else {
        setDrawerState({
          left: DrawerState.Normal,
          right: DrawerState.Expanded,
        })
      }
    },
    leftExpanded,
    rightExpanded,
    leftClosed,
    rightClosed,
    expandedPanel,
    setExpandedPanel,
    closeExpandedPanel: () => {
      setExpandedPanel(ExpandedPanel.None)
    },
    rightView, 
    setRightView,
  }
}
