import { useRouteMatch } from 'react-router'

export function useRoute<Params extends { [K in keyof Params]?: string } = {}>(location: string) {
  const match = useRouteMatch<Params>(location)

  return {
    match: match !== null,
    params: match?.params || {} as Params,
  }
}
