import { ulid } from 'ulid'
import { Issue, IssueStatus, IssueType, Photo, RiskType, SurveyBasedOperation } from '~/models'

export type IssueItem = Issue

export type IssueItemWithIndex = IssueItem & {
  photoIndex: number
  creationStatus: IssueCreationStatus
  operationType?: SurveyBasedOperation
}

export type IssueItemWithPrevious = {
  currentIssue: IssueItem & {
    photoIndex: number
    creationStatus: IssueCreationStatus
  }
  previousIssue: IssueItem
}

export function newEmptyIssue(
  imageID: string,
  assetID: string,
  issue: IssueItemWithIndex,
  defectTypeID: string,
  photoIndex: number
): IssueItemWithIndex {
  return {
    id: ulid(new Date().getDate()),
    assetID: issue?.assetID || assetID || '',
    assetTypeID: issue?.assetTypeID || '',
    assetTypeName: issue?.assetTypeName || '',
    name: '',
    number: 0,
    notes: '',
    priority: issue?.priority || 5,
    riskType: issue?.riskType || RiskType.Low,
    issueType: IssueType.IssueTypeManuallyTaggedImage,
    status: issue?.status || IssueStatus.AwaitingAction,
    component: issue?.component,
    componentTypeID: issue?.componentTypeID,
    componentTypeName: issue?.componentTypeName,
    componentTypeFullName: issue?.componentTypeFullName,
    location: {
      altitude: 0,
      latitude: 0,
      longitude: 0,
    },
    imageID: imageID,
    imageHash: '',
    imageDate: 0,
    coordinates: [],
    repairEstimate: 0,
    defectTypeID: issue?.defectTypeID || defectTypeID,
    defectType: issue?.defectSubType,
    defectSubTypeID: issue?.defectSubTypeID,
    defectSubType: issue?.defectType,
    defectCostToRepair: 0,
    defectCostPriorityScalar: 0,
    photoIndex,
    creationStatus: IssueCreationStatus.New,
    defectDescription: '',
    defectSuggestedRemedy: '',
    numComments: 0,
    surveyID: '',
    operationType: SurveyBasedOperation.GoingForward,
    materialTypeID: issue?.materialTypeID || '',
    materialTypeName: issue?.materialTypeName || '',
    isInternal: false,
    internalPoint: undefined,
    internalReconstructionID: '',
  }
}

export interface PhotoItem extends Photo {
  index: number
}

export enum IssueCreationStatus {
  New,
  Existing,
  Updated,
}
