import { useState } from 'react'
import { InsightDtoT } from '~/models'
import { useExtendedSite } from './use-extended-site'
import { useViewState } from './view-state'

export function useInsights(site?: ReturnType<typeof useExtendedSite>, view?: ReturnType<typeof useViewState>) {
  const drawInsights = localStorage.getItem('asseti-insights-draw')
  const [insightToUpdate, setInsightToUpdate] = useState<InsightDtoT>()
  const [selectedInsight, setSelectedInsight] = useState<InsightDtoT>()
  const [draw, setDraw] = useState<boolean>(drawInsights === 'true' || drawInsights === null)

  return {
    insightToUpdate,
    setInsightToUpdate: (insight: InsightDtoT) => {
      setInsightToUpdate(insight)
    },
    draw,
    setDraw: (b: boolean) => {
      localStorage.setItem('asseti-insights-draw', b + '')
      setDraw(b)
    },
    selectedInsight,
    setSelectedInsight,
  }
}

