import { useEffect, useState } from 'react'
import { useLocation } from '~/components'
import { CommentTarget } from '~/models'
import { useViewState } from './view-state'

export function useComments(view: ReturnType<typeof useViewState>) {
  const [visible, setVisible] = useState<boolean>(false)
  const [targetType, setTargetType] = useState<CommentTarget>(CommentTarget.Issue)
  const [targetID, setTargetID] = useState<string | undefined>()
  const location = useLocation()

  useEffect(() => {
    if (!view.showingComments) {
      if (visible) {
        setVisible(false)
      }
      return
    }

    if (!visible) {
      setVisible(true)
    }

    if (view.issueID) {
      setTargetType(CommentTarget.Issue)
      setTargetID(view.issueID)
    } else if (view.assetID) {
      setTargetType(CommentTarget.Asset)
      setTargetID(view.assetID)
    } else if (view.boundaryID) {
      setTargetType(CommentTarget.Boundary)
      setTargetID(view.boundaryID)
    } else {
      setTargetType(CommentTarget.Site)
      setTargetID(view.siteID)
    }
  }, [view])

  return {
    visible,
    targetType,
    targetID,
    toggle: () => {

      if(view.showingComments) {
        if(view.issueID) {
          if(view.assetID) {
            location.setLocation(`/${view.siteID}/${view.surveyID}/assets/${view.assetID}/issue/${view.issueID}`)
          } else {
            location.setLocation(`/${view.siteID}/${view.surveyID}/issue/${view.issueID}`)
          }
        } else if(view.assetID) {
          location.setLocation(`/${view.siteID}/${view.surveyID}/assets/${view.assetID}`)
        } else {
          if(view.surveyID) {
            if(view.boundaryID) {
              location.setLocation(`/${view.siteID}/${view.surveyID}/boundary/${view.boundaryID}`)
            }
          } else {
            location.setLocation(`/${view.siteID}`)
          }
        }
      } else {
        if(view.issueID) {
          if(view.assetID) {
            location.setLocation(`/${view.siteID}/${view.surveyID}/assets/${view.assetID}/issue/${view.issueID}/comments`)
          } else {
            location.setLocation(`/${view.siteID}/${view.surveyID}/issue/${view.issueID}/comments`)
          }
        } else if(view.assetID) {
          location.setLocation(`/${view.siteID}/${view.surveyID}/assets/${view.assetID}/comments`)
        } else {
          if(view.surveyID) {
            if(view.boundaryID) {
              location.setLocation(`/${view.siteID}/${view.surveyID}/boundary/${view.boundaryID}/comments`)
            } else {
              location.setLocation(`/${view.siteID}/${view.surveyID}/comments`)
            }
          } else {
            location.setLocation(`/${view.siteID}/comments`)
          }
        }
      }
    },
  }
}

