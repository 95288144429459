import React from 'react'
import { SimpleBar } from '../simple-bar'

interface DrawerListProps {
  children?: any
}

export const DrawerList = (props: DrawerListProps) => {
  return (
    <div className='drawer-list'>
      <SimpleBar>{props.children}</SimpleBar>
    </div>
  )
}

export const DrawerListDivider = () => <hr className='drawer-list-divider' />
