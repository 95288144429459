import React, { useEffect, useMemo, useState } from 'react'
import { Helmet, useLocation, useToasts } from '~/components'
import { SitesHeader } from './sites-header'
import { Marker, useAppState } from '~/state'
import { SitesListCards } from './sites-list-cards'
import { SitesListViewType } from './sites-list-types'
import { SiteCreate } from './site-create'
import { SitesQuerySiteWithExtra } from '~/models'
import { ClientPermission, useUser } from '~/base'
import { SitesListIA } from './sites-list-ia'

interface SitesListProps {
  sites: SitesQuerySiteWithExtra[]
}

export const SitesList = (props: SitesListProps) => {
  const { drawer, sites, search, map } = useAppState()
  const toasts = useToasts()
  const location = useLocation()
  const [isSearching, setIsSearching] = useState(false)
  const [showCreateSite, setShowCreateSite] = useState<boolean>(false)
  const [visibleSites, setVisibleSites] = useState<string[]>((sites?.data?.sites || []).map((s) => s.id))
  const user = useUser()

  // Site visibility based on map view.
  useEffect(() => {
    if (!sites.data || !map.initialized) {
      return
    }

    const sortedSites = [...props.sites].sort((a, b) => {
      const c1 = Cesium.Cartographic.fromCartesian(a.extra.center)
      const c2 = Cesium.Cartographic.fromCartesian(b.extra.center)
      return c1.longitude >= c2.longitude ? -1 : 1
    })

    const markers: Marker[] = []
    for (const site of sortedSites) {
      markers.push(
        map.addClusteredMarker(site.s.id, site.extra.center, site.s.name, site.s.lastSurveyID, () => {
          location.setLocation('/' + site.s.id + '/' + site.s.lastSurveyID)
        })
      )
    }

    function moveListener() {
      if (sites.editing.current) {
        return
      }

      const visibleMarkers = markers.filter((m) => {
        if (m.billboard) {
          const screenPos = m.billboard.computeScreenSpacePosition(map.viewer.scene)

          if (
            screenPos &&
            Cesium.Math.greaterThanOrEquals(screenPos.x, 0, Cesium.Math.EPSILON1) &&
            Cesium.Math.lessThanOrEquals(screenPos.x, window.innerWidth, Cesium.Math.EPSILON1) &&
            Cesium.Math.greaterThanOrEquals(screenPos.y, 0, Cesium.Math.EPSILON1) &&
            Cesium.Math.lessThanOrEquals(screenPos.y, window.innerHeight, Cesium.Math.EPSILON1)
          ) {
            return true
          }
        }

        return false
      })

      if (visibleMarkers.length === 1 && map.viewer.camera.positionCartographic.height <= 1e4) {
        location.setLocation('/' + visibleMarkers[0].id + '/' + visibleMarkers[0].surveyID)
      }

      if (visibleMarkers.length == 0) {
        setVisibleSites(() => markers.map((m) => m.id))
      } else {
        setVisibleSites(() => visibleMarkers.map((m) => m.id))
      }
    }

    map.viewer.camera.moveEnd.addEventListener(moveListener)
    map.setShowSatellite(false)

    return () => {
      for (const marker of markers) {
        map.removeClusteredMarker(marker)
      }

      map.viewer.camera.moveEnd.removeEventListener(moveListener)
    }
  }, [sites.data, map.initialized])

  // Filter sites based on search.
  const filteredSites = useMemo(() => {
    if (!sites?.data?.sites) {
      return undefined
    }

    if (isSearching && !drawer.leftExpanded) {
      return props.sites.filter(function (obj) {
        return obj.s.name.toLowerCase().includes(search.search.toLowerCase())
      })
    } else {
      return props.sites.filter((s) => visibleSites.includes(s.s.id))
    }
  }, [props.sites, isSearching, search.search, visibleSites, drawer.leftExpanded])

  // On a new site created.
  const siteCreated = (created: boolean, siteID: string) => {
    if (created) {
      toasts.addTopLeft('Site successfully created')
      sites.refetch()
      location.setLocation('/' + siteID)
    }

    setShowCreateSite(false)
  }

  const haveError = sites.error
  const isLoading = sites.loading
  const haveSites = !haveError && sites.data && sites.data?.sites.length > 0
  const showEmpty = !haveSites && !isLoading && !haveError
  const showFullList = drawer.leftExpanded

  const isIA = user.isInstantAssessmentOrg()

  return (
    <>
      <Helmet title='Sites' />
      <>
        <SitesHeader
          haveSites={haveSites}
          onCreateSite={() => setShowCreateSite(true)}
          filterSites={(value: string) => {
            search.setSearch(value || '')
            if (value.length >= 1) {
              setIsSearching(true)
            } else {
              setIsSearching(false)
            }
          }}
          siteResults={filteredSites}
          viewType={SitesListViewType.Sites}
          extraLinks={(sites.groups || []).map((g) => {
            return {
              name: g.group.name,
              description: `${g.group.siteIDs.length} sites`,
              url: '/groups/' + g.group.id,
            }
          })}
          permission={ClientPermission.SitesCreate}
        />
        <div className='sites-list' id='sites-list'>
          {!isIA && (
            <SitesListCards
              filter={visibleSites}
              onCreateSite={() => setShowCreateSite(true)}
              sites={filteredSites}
              isLoading={isLoading}
              showEmpty={showEmpty}
              haveSites={haveSites}
              showFullList={showFullList}
            />
          )}
          {isIA && (
            <SitesListIA
              filter={visibleSites}
              onCreateSite={() => setShowCreateSite(true)}
              sites={filteredSites}
              isLoading={isLoading}
              showEmpty={showEmpty}
              haveSites={haveSites}
              showFullList={showFullList}
            />
          )}
        </div>
      </>
      {showCreateSite && <SiteCreate onComplete={siteCreated} />}
    </>
  )
}
