import React from 'react'
import { useMutation } from '~/components'
import { getTracking } from '~/components'
import { useAppState } from '~/state'
import ARCHIVE_INSIGHT from './mutation-insight-archive.gql'
import { InsightArchiveMutation, InsightArchiveMutationVariables } from '~/models'

interface InsightArchiveProps {
  onArchive: () => void
  hide: () => void
}

export const InsightArchive = (props: InsightArchiveProps) => {
  const { view } = useAppState()
  const [archiveInsight] = useMutation<InsightArchiveMutation, InsightArchiveMutationVariables>(ARCHIVE_INSIGHT)

  const doArchiveInsight = () =>
    archiveInsight({
      variables: {
        input: {
          siteID: view.siteID,
          insightID: view.insightID,
          observationID: view.observationID,
        },
      },
    }).then(props.onArchive)

  return (
    <div className='site-delete'>
      <div className='site-delete-confirm'>
        <h6>Archive this insight?</h6>
        <button
          className='site-delete-confirm-delete btn'
          onClick={() => {
            doArchiveInsight()
            getTracking().event({
              category: 'Button',
              action: `User archived an insight`,
              label: 'Insight',
            })
          }}
        >
          Yes, archive
        </button>
        <button
          className='site-delete-confirm-cancel btn'
          onClick={() => {
            props.hide()
            getTracking().event({
              category: 'Button',
              action: `User canceled archive insight`,
              label: 'Insight',
            })
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}
