import React, { useMemo, useState } from 'react'
import { ClientPermission, useUser } from '~/base'
import { OverviewCard, useLocation, useToasts } from '~/components'
import { useAppState } from '~/state'
import { IssueCountsT, RiskType } from '~/models'
import { AssetRemove } from './asset-remove'

interface AssetInfoProps {
  updateAsset: () => void
  issueCounters: IssueCountsT
  refetch: () => Promise<any>
  isInternal?: boolean
}

export const AssetInfo = (props: AssetInfoProps) => {
  const user = useUser()
  const { view, site, asset, sites } = useAppState()
  const isDemoSite = site.site?.isDemoSite
  const canListIssues = user.hasPermission(ClientPermission.IssuesList)
  const canUpdateAsset = !props.isInternal && !isDemoSite && user.hasPermission(ClientPermission.AssetsUpdate)
  const canRemoveAsset = !props.isInternal && !isDemoSite && user.hasPermission(ClientPermission.AssetsRemove)
  const [showDelete, setShowDelete] = useState(false)
  const location = useLocation()
  const toasts = useToasts()


  const assetInfoProps = useMemo(() => {
    const lastUploadTime = (sites.summaryQuery?.data?.queryImageSummaryByOrg?.sites || []).find(
      (x) => x.siteID === view.siteID
    )?.lastUpload

    let numHighIssues = 0
    let numMediumIssues = 0
    let numLowIssues = 0

    for (const i of asset.asset?.issues || []) {
      if (i.riskType === RiskType.High) {
        numHighIssues++
      } else if (i.riskType === RiskType.Medium) {
        numMediumIssues++
      } else if (i.riskType === RiskType.Low) {
        numLowIssues++
      }
    }

    return {
      lastUploadTime,
      numAssets: (asset.asset?.components || []).length,
      numHighIssues,
      numLowIssues,
      numMediumIssues,
    }
  }, [asset.asset])

  return (
    <>
      <OverviewCard
        {...assetInfoProps}
        isDemo={isDemoSite}
        number={asset.asset?.number}
        type='Asset'
        title={asset.asset?.name}
        canUpdate={canUpdateAsset}
        canRemove={canRemoveAsset}
        onClickEdit={props.updateAsset}
        onClickDelete={() => setShowDelete(true)}
        numComments={asset.asset?.numComments}
        haveAddress={false}
        canListOverview={!user.isInstantAssessmentOrg() && canListIssues}
      />
      {showDelete && (
        <AssetRemove
          hide={() => setShowDelete(false)}
          onRemove={() => {
            toasts.addTopLeft('Asset successfully deleted.')
            props.refetch().then(() => {
              location.setLocation('/' + view.siteID + '/' + view.surveyID)
            })
          }}
        />
      )}
    </>
  )
}
