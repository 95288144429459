import React, { useState, useEffect } from 'react'
import { SitesList } from './sites-list'
import { useAppState, ProjectionType, BaseLayerType } from '~/state'
import { SitesListViewType } from './sites-list-types'

export const Sites = () => {
  const { sites, map } = useAppState()
  const [viewType, setViewType] = useState(SitesListViewType.Loading)

  useEffect(() => {
    if (!sites.sites || !sites.groups || viewType !== SitesListViewType.Loading) {
      return
    }

    setViewType(SitesListViewType.Sites)
  }, [sites.sites, sites.groups, viewType])

  useEffect(() => {
    if (map.projectionType === ProjectionType.Projection3D) {
      map.toggleProjection()
    }
  }, [map.projectionType])

  // Jump to all sites.
  useEffect(() => {
    if (!sites.data || viewType === SitesListViewType.Loading) {
      return
    }

    map.setBaseLayerType(BaseLayerType.Satellite)
    map.jumpTo(sites.getBounds())
  }, [sites.sites, viewType])

  return (
    <div className='sites'>{sites && viewType === SitesListViewType.Sites && <SitesList sites={sites.sites} />}</div>
  )
}
