import React from 'react'
import { useMutation } from '~/components'
import { getTracking } from '~/components'
import { useAppState } from '~/state'
import REMOVE_ISSUE from './mutation-issues-remove.gql'
import { RemoveIssueMutation, RemoveIssueMutationVariables, SurveyBasedOperation } from '~/models'

interface IssueRemoveProps {
  onRemove: () => void
  hide: () => void
}

export const IssueRemove = (props: IssueRemoveProps) => {
  const { view } = useAppState()

  // const [operationType, setOperationType] = useState(SurveyBasedOperation.GoingForward)
  const [removeIssue] = useMutation<RemoveIssueMutation, RemoveIssueMutationVariables>(REMOVE_ISSUE)

  const doRemoveIssue = () =>
    removeIssue({
      variables: {
        input: {
          operationType: SurveyBasedOperation.Singular,
          siteID: view.siteID,
          surveyID: view.surveyID,
          ids: [view.issueID],
        },
      },
    }).then(props.onRemove)

  return (
    <div className='boundaries-draw'>
      <div
        className='item-tag-delete-confirm'
        style={{ backgroundColor: 'rgb(43, 39, 40)', justifyContent: 'unset', paddingTop: '2rem' }}
      >
        <h6>Delete this issue?</h6>
        {/* <OperationTypeSelect
          name='operationType'
          selectedValue={operationType}
          onChange={(e) => {
            setOperationType((e.target as any).value)
          }}
          survey={timeline.activeSurvey}
        /> */}
        <button
          className='item-tag-delete-confirm-delete btn'
          onClick={() => {
            doRemoveIssue()
            getTracking().event({
              category: 'Button',
              action: `User removed a issue`,
              label: 'Issue',
            })
          }}
        >
          Yes, delete
        </button>
        <button
          className='item-tag-delete-confirm-cancel btn'
          onClick={() => {
            props.hide()
            getTracking().event({
              category: 'Button',
              action: `User canceled remove issue`,
              label: 'Issue',
            })
          }}
        >
          Cancel
        </button>
        <p>Caution: This cannot be undone.</p>
      </div>
    </div>
  )
}
