import constate from 'constate'
import { useViewState } from './view-state'
import { useDrawerState } from './use-drawer-state'
import { useSites } from './use-sites'
import { useCesium } from './use-cesium'
import { useImageTaggerState } from './use-image-tagger-state'
import { useBoundaryState } from './use-boundary-state'
import { useMonitoringZoneState } from './use-monitoring-zone-state'
import { useWindowHeight } from './use-window-height'
import { useHubspotChat } from './use-hubspot-chat'
import { useTimeline } from './use-timeline'
import { useNotifications } from './use-notifications'
import { useExtendedSite } from './use-extended-site'
import { useIssues } from './use-issues'
import { useAsset } from './use-asset'
import { useComments } from './use-comments-state'
import { useTasks } from './use-tasks-state'
import { useComponentState } from './use-component-state'
import { useInsights } from './use-insight-state'
import { useAnnotations } from './use-annotations'
import { useEntityContext } from './use-entity-context'
import { useAnalysis } from './use-analysis'
import { useSearch } from './use-search'

function useAppStateImpl() {
  const view = useViewState()
  const drawer = useDrawerState()
  const map = useCesium()
  const sites = useSites()
  const imageTagger = useImageTaggerState()
  const monitoringZoneState = useMonitoringZoneState()
  const windowHeight = useWindowHeight()
  const hubspotChat = useHubspotChat()
  const extendedSite = useExtendedSite(view.siteID)
  const timeline = useTimeline(view.surveyID, view.observationID, view.lidarHash, extendedSite, map, view.issueID)
  const boundaryState = useBoundaryState(view.assetID, view.boundaryID, timeline.activeSurvey)
  const notifications = useNotifications()
  const issues = useIssues(imageTagger, timeline.activeSurvey, timeline.activeInteriorModel, view.assetID, view.issueID)
  const insights = useInsights(extendedSite, view)
  const asset = useAsset(view.assetID, timeline.activeSurvey)
  const comments = useComments(view)
  const tasks = useTasks(view, sites, timeline)
  const components = useComponentState()
  const annotations = useAnnotations(view.surveyID, view.siteID, view.assetID)
  const search = useSearch()
  const analysis = useAnalysis(view.surveyID, view.siteID, view.assetID)
  const entityContext = useEntityContext({
    components,
    annotations,
    monitoringZones: monitoringZoneState,
    view,
  })

  return {
    view,
    drawer,
    map,
    sites,
    site: extendedSite,
    imageTagger,
    boundaryState,
    monitoringZoneState,
    windowHeight,
    hubspotChat,
    timeline,
    notifications,
    issues,
    asset,
    comments,
    tasks,
    components,
    insights,
    annotations,
    entityContext,
    analysis,
    search,
  }
}

export const [AppStateProvider, useAppState] = constate(useAppStateImpl)
