import React, { useEffect, useState } from 'react'
import { ClientPermission, useUser } from '~/base'

let CommentsListImpl: React.FC = undefined

const CommentsList = () => {
  const [loading, setLoading] = useState(CommentsListImpl === undefined)

  useEffect(() => {
    if (loading) {
      import('./comments-list').then((res) => {
        CommentsListImpl = res.default
        setLoading(false)
      })
    }
  }, [])

  if (CommentsListImpl) {
    return <CommentsListImpl />
  }

  return <div></div>
}

export const CommentsDrawer = () => {
  const user = useUser()
  const canSeeComments = user.hasPermission(ClientPermission.CommentsRead)
  if(!canSeeComments) {
    return null
  }
  
  return <div className='comments-drawer'>
    <CommentsList />
  </div>
}