import { AuthLayout, AuthLayoutHeadingType } from './auth-layout'
import React, { useEffect, useState } from 'react'
import { SignInAsStaffMutation, SignInAsStaffMutationVariables } from '~/models/gql-types'
import { useMutation } from '~/components'
import { AuthError } from './auth-error'
import { useUser } from '~/base'
import SIGN_IN_MUTATION from './mutation-sign-in-as-staff.gql'

interface AuthSwitchProps {
  switchingToken: string
  orgId: string
  supplierId: string
}

export const AuthSwitch = (props: AuthSwitchProps) => {
  const user = useUser()
  const [execute] = useMutation<SignInAsStaffMutation, SignInAsStaffMutationVariables>(SIGN_IN_MUTATION)
  const [error, setError] = useState('')
  const urlParams = new URLSearchParams(window.location.search)

  useEffect(() => {
    user.setToken(props.switchingToken)

    execute({
      variables: {
        input: {
          orgID: props.orgId || '',
          supplierID: props.supplierId || '',
          token: props.switchingToken,
        },
      },
    })
      .then((res) => {
        localStorage.setItem('asseti_switch', '1')
        if(window.location.host === 'localhost:1234') {
          localStorage.setItem('asseti_old_data', localStorage.getItem('asseti_auth'))
          localStorage.setItem('asseti_old_token', localStorage.getItem('asseti_token'))
        }
        user.setSignIn(res.data.signInAsStaff)
        if (urlParams.has('r')) {
          const r = urlParams.get('r')
          window.location.href = r
        } else {
          window.location.href = '/'
        }
      })
      .catch(() => {
        // TODO: Implement proper error codes and messages when standardized.
        setError('There has been an error, please try again.')
      })
  }, [])

  return (
    <AuthLayout headingType={AuthLayoutHeadingType.NONE}>
      <div style={{ minWidth: 300 }} className='subheader-dark mt-5 mb-5 p-5'>
        Signing in...
      </div>
      {error && <AuthError className='alert alert-warning mb-4' error={error} />}
    </AuthLayout>
  )
}
