import React from 'react'
import { useUser } from '~/base'
import { IssueCountsT } from '~/models'
import { handleCurrencyFormat, handleNumberFormat } from '../helpers'

interface AnalyticsPanelProps {
  title: string
  counts: IssueCountsT
  isCurrency?: boolean
}

export const AnalyticsPanel = (props: AnalyticsPanelProps) => {
  const user = useUser()
  return (
    <div className='analytics-panel'>
      <div className='analytics-panel-title'>{props.title}</div>
      <div className='analytics-panel-container'>
        <div className='analytics-panel-container-inner'>
          <div className='analytics-panel-container-inner-container-data-oval high'>
            {props.isCurrency ? handleCurrencyFormat(props.counts.high, user) : handleNumberFormat(props.counts.high)}
          </div>
          <div className='analytics-panel-container-inner-container-title high'>High</div>
        </div>
        <div className='analytics-panel-container-inner'>
          <div className='analytics-panel-container-inner-container-data-oval medium'>
            {props.isCurrency ? handleCurrencyFormat(props.counts.medium, user) : handleNumberFormat(props.counts.medium)}
          </div>
          <div className='analytics-panel-container-inner-container-title medium'>Medium</div>
        </div>
        <div className='analytics-panel-container-inner'>
          <div className='analytics-panel-container-inner-container-data-oval  low'>
            {props.isCurrency ? handleCurrencyFormat(props.counts.low, user) : handleNumberFormat(props.counts.low)}
          </div>
          <div className='analytics-panel-container-inner-container-title low'>Low</div>
        </div>
      </div>
    </div>
  )
}
