import { ClientPermission, usePollingQuery, useUser } from '~/base'
import {
  ImageSummaryByOrgQuery,
  InstantAssessmentSummaryAllSitesQuery,
  InstantAssessmentSummaryAllSitesQueryVariables,
  MeasurementSystem,
  SiteGropupsQuery,
  SiteQueryAsset,
  SiteQuerySite,
  SitesQuery,
  SitesQuerySite,
  SitesQuerySiteGroupWithExtra,
  SitesQuerySiteWithExtra,
  WeatherSummaryForSitesQuery,
} from '~/models'

import { useRefState } from './use-cesium'
import { expandRect } from './utils'
import SITES_QUERY from './query-sites.gql'
import SITE_GROUPS_QUERY from './query-site-groups.gql'
import IMAGE_SUMMARY_BY_ORG_QUERY from './images-query.gql'
import WEATHER_QUERY from './query-sites-weather.gql'
import { useMemo } from 'react'
import { makeHull, useQuery } from '~/components'
import { Config } from '~/config'
import IA_SUMMARY_QUERY from './query-ia-summaries.gql'

type Data = {
  sites: SitesQuerySiteWithExtra[]
  groups: SitesQuerySiteGroupWithExtra[]
}

export function useSites() {
  const user = useUser()
  const query = useQuery<SitesQuery>(SITES_QUERY)
  const siteGroupsQuery = useQuery<SiteGropupsQuery>(SITE_GROUPS_QUERY)
  const summaryQuery = useQuery<ImageSummaryByOrgQuery>(IMAGE_SUMMARY_BY_ORG_QUERY)
  const iaQuery = useQuery<InstantAssessmentSummaryAllSitesQuery, InstantAssessmentSummaryAllSitesQueryVariables>(
    IA_SUMMARY_QUERY,
    {
      variables: {
        input: {
          measurementSystem: MeasurementSystem.Imperial,
        }
      },
      fetchPolicy: user.isInstantAssessmentOrg() ? 'network-only' : 'standby',
    }
  )
  const weatherQuery = usePollingQuery<WeatherSummaryForSitesQuery>(WEATHER_QUERY, {
    pollInterval: 1000 * 60 * 5,
  })
  const [editing, setEditing] = useRefState(false)

  const data: Data = useMemo(() => {
    if (!query?.data?.sites || !query?.data?.siteGroups) {
      return undefined
    }

    const sitesExtra = summaryQuery.data?.queryImageSummaryByOrg?.sites || []
    const weatherExtra = weatherQuery.data?.weatherSummaryForSites || []
    const siteList = query.data.sites

    const sitesWithExtra = siteList.map((s) => {
      const center = getCenterForSite(s)
      const siteWithExtra: SitesQuerySiteWithExtra = {
        s: s,
        extra: {
          siteID: s.id,
          lastUpload: sitesExtra.find((i) => i.siteID === s.id)?.lastUpload || 0,
          center,
          weather: weatherExtra.find((i) => i.siteID === s.id),
        },
      }
      return siteWithExtra
    })

    const groups = query.data.siteGroups
      .map((sg) => {
        let lastUploadTime = 0
        let numAssets = 0
        let numHighIssues = 0
        let numMediumIssues = 0
        let numLowIssues = 0
        const sitesInGroup = []

        const center = new Cesium.Cartesian3()
        // const points: Cesium.Cartesian3[] = []
        const pointsForHull: Cesium.Cartographic[] = []

        for (const siteID of sg.siteIDs) {
          const site = sitesWithExtra.find((s) => s.s.id === siteID)
          if (site) {
            sitesInGroup.push(site)
            numAssets += site.s.assetCount
            numHighIssues += site.s.highRiskIssues
            numMediumIssues += site.s.mediumRiskIssues
            numLowIssues += site.s.lowRiskIssues
            lastUploadTime = Math.max(lastUploadTime, site.extra.lastUpload)
            Cesium.Cartesian3.add(center, site.extra.center, center)
            pointsForHull.push(Cesium.Cartographic.fromCartesian(site.extra.center))
          }
        }

        if (sitesInGroup.length > 0) {
          Cesium.Cartesian3.divideByScalar(center, sitesInGroup.length, center)
        }

        const g: SitesQuerySiteGroupWithExtra = {
          group: sg,
          sites: sitesInGroup.sort((a, b) => (a.s.number > b.s.number ? 1 : -1)),
          lastUploadTime,
          numAssets,
          numHighIssues,
          numMediumIssues,
          numLowIssues,
          center: sitesInGroup.length > 0 ? center : undefined,
          hull:
            pointsForHull.length > 2
              ? makeHull(pointsForHull).map((p) => Cesium.Cartographic.toCartesian(p))
              : undefined,
        }

        return g
      })
      .sort((a, b) => (a.group.name < b.group.name ? -1 : 1))

    const data: Data = {
      sites: sitesWithExtra,
      groups,
    }

    return data
  }, [query?.data?.sites, query?.data?.siteGroups, weatherQuery?.data?.weatherSummaryForSites])

  return {
    ...query,
    siteGroupsQuery,
    sites: data?.sites,
    groups: data?.groups,
    refetch: () => {
      return Promise.all([query.refetch(), summaryQuery.refetch()])
    },
    summaryQuery,
    getBounds: () => {
      const sites = query.data?.sites || []
      const normalSites = sites.filter((x) => !x.isDemoSite)
      return getBoundsForSites(normalSites.length === 0 ? sites : normalSites)
    },
    getBoundsForGroups: () => {
      return getBoundsForGroups(data?.groups)
    },
    getBoundsForSite,
    getBoundsForSiteWithPadding,
    getBoundsForAssetWithPadding,
    getCenterForSite,
    getBoundsForSites,
    editing,
    setEditing,
    canSeeGroups: user.hasPermission(ClientPermission.ManageSiteGroups) || (data?.groups || []).length > 0,
    sortSites: (sites: SitesQuerySiteWithExtra[]): SitesQuerySiteWithExtra[] =>
      sites.sort((a, b) => (a.s.name < b.s.name ? -1 : 1)),
    sortGroups: (sites: SitesQuerySiteGroupWithExtra[]): SitesQuerySiteGroupWithExtra[] =>
      sites.sort((a, b) => (a.group.name < b.group.name ? -1 : 1)),
    iaQuery,
  }
}

function getBoundsForSites(sites: SitesQuerySite[]): Cesium.Rectangle {
  if (sites.length === 0) {
    return Config.DefaultMapRectangle.clone()
  }

  const points: Cesium.Cartographic[] = []
  for (const site of sites) {
    points.push(Cesium.Cartographic.fromDegrees(site.maxLng, site.maxLat))
    points.push(Cesium.Cartographic.fromDegrees(site.maxLng, site.minLat))
    points.push(Cesium.Cartographic.fromDegrees(site.minLng, site.maxLat))
    points.push(Cesium.Cartographic.fromDegrees(site.minLng, site.minLat))
  }

  const rect = Cesium.Rectangle.fromCartographicArray(points)
  return expandRect(rect, 0.0025)
}

function getBoundsForGroups(groups: SitesQuerySiteGroupWithExtra[]): Cesium.Rectangle {
  if (!groups || groups.length === 0) {
    return Config.DefaultMapRectangle.clone()
  }

  const points: Cesium.Cartographic[] = []
  for (const group of groups) {
    if (group.group.siteIDs.length === 0) {
      continue
    }
    if (group.hull) {
      for (const p of group.hull) {
        points.push(Cesium.Cartographic.fromCartesian(p))
      }
    } else if (group.center) {
      points.push(Cesium.Cartographic.fromCartesian(group.center))
    }
  }

  if (points.length === 0) {
    return Config.DefaultMapRectangle.clone()
  }

  const rect = Cesium.Rectangle.fromCartographicArray(points)
  return expandRect(rect, 0.0025)
}

export function getBoundsForSite(site: SiteQuerySite) {
  const points: Cesium.Cartographic[] = []

  const boundaries = site.surveys.reduce((a, b) => [...a, ...b.boundaries], [])

  // Add the address center.
  if (Array.isArray(site.address.center) && site.address.center.length === 2 && boundaries.length === 0) {
    points.push(Cesium.Cartographic.fromDegrees(site.address.center[0], site.address.center[1]))
  }

  // Add all the boundary points.
  for (const boundary of boundaries) {
    for (const p of boundary.points) {
      points.push(Cesium.Cartographic.fromDegrees(p.longitude, p.latitude))
    }
  }

  // If we only have the address center, add some padding.
  if (points.length === 1) {
    points.push(new Cesium.Cartographic(points[0].longitude + 0.00005, points[0].latitude + 0.00005))
    points.push(new Cesium.Cartographic(points[0].longitude - 0.00005, points[0].latitude - 0.00005))
  }

  // Create a rectangle to containing all the points.
  const rect = Cesium.Rectangle.fromCartographicArray(points)
  return expandRect(rect)
}

export function getBoundsForSiteWithPadding(site: SiteQuerySite, padding: number) {
  const points: Cesium.Cartographic[] = []

  const boundaries = site.surveys.reduce((a, b) => [...a, ...b.boundaries], [])
  if (Array.isArray(site.address.center) && site.address.center.length === 2 && boundaries.length === 0) {
    points.push(Cesium.Cartographic.fromDegrees(site.address.center[0], site.address.center[1]))
  }

  for (const boundary of boundaries) {
    for (const p of boundary.points) {
      points.push(Cesium.Cartographic.fromDegrees(p.longitude, p.latitude))
    }
  }

  if (points.length === 1) {
    points.push(new Cesium.Cartographic(points[0].longitude + 0.00005, points[0].latitude + 0.00005))
    points.push(new Cesium.Cartographic(points[0].longitude - 0.00005, points[0].latitude - 0.00005))
  }

  const rect = Cesium.Rectangle.fromCartographicArray(points)
  return expandRect(rect, 0, padding)
}

export function getBoundsForAssetWithPadding(site: SiteQuerySite, asset: SiteQueryAsset, padding: number) {
  const points: Cesium.Cartographic[] = []

  for (const boundary of asset.boundaries) {
    for (const p of boundary.points) {
      points.push(Cesium.Cartographic.fromDegrees(p.longitude, p.latitude))
    }
  }

  if (points.length === 1) {
    points.push(new Cesium.Cartographic(points[0].longitude + 0.00005, points[0].latitude + 0.00005))
    points.push(new Cesium.Cartographic(points[0].longitude - 0.00005, points[0].latitude - 0.00005))
  } else if (points.length === 0) {
    return getBoundsForSiteWithPadding(site, padding)
  }

  const rect = Cesium.Rectangle.fromCartographicArray(points)
  return expandRect(rect, 0, padding)
}

function getCenterForSite(site: SitesQuerySite): Cesium.Cartesian3 {
  const points: Cesium.Cartographic[] = []
  points.push(Cesium.Cartographic.fromDegrees(site.maxLng, site.maxLat))
  points.push(Cesium.Cartographic.fromDegrees(site.maxLng, site.minLat))
  points.push(Cesium.Cartographic.fromDegrees(site.minLng, site.maxLat))
  points.push(Cesium.Cartographic.fromDegrees(site.minLng, site.minLat))
  const rect = Cesium.Rectangle.fromCartographicArray(points)
  return Cesium.Ellipsoid.WGS84.cartographicToCartesian(Cesium.Rectangle.center(rect))
}
