import React, { useEffect, useState } from 'react'
import { classes, Helmet } from '~/components'
import {
  ActionHover,
  DrawerForm,
  DrawerFormBreadcrumbItem,
  DrawerFormBreadcrumbs,
  DrawerFormContent,
  Transition,
  useLocation,
  useToasts,
} from '~/components'
import { useAppState } from '~/state'
import { useUser } from '~/base'
import CHEVRONS from '../images/chevrons-right.svg'
import { SystemRole } from '~/models'
import { InsightRemove } from './insight-remove'
import { InsightArchive } from './insight-archive'
import { ExpandedPanel } from '~/state/use-drawer-state'

export const InsightDetail = () => {
  const location = useLocation()
  const toasts = useToasts()
  const { view, map, site, timeline, asset, insights: insightState, drawer } = useAppState()
  const [showRemoveInsight, setShowRemoveInsight] = useState(false)
  const [showArchiveInsight, setShowArchiveInsight] = useState(false)
  const title = 'Insight'

  const insights = timeline.activeObservation?.insights || []

  const insightIdx = insights.findIndex((i) => i.id === view.insightID)
  const insight = insightIdx === -1 ? undefined : insights[insightIdx]
  const user = useUser()

  useEffect(() => {
    if (!insight) {
      return
    }

    setTimeout(() => {
      if (!insight) {
        return
      }
      const rect = Cesium.Rectangle.fromCartographicArray(
        insight.aoi.map((p) => Cesium.Cartographic.fromDegrees(p.longitude, p.latitude, 10))
      )
      map.viewer.camera.flyTo({
        destination: rect,
        duration: 0,
      })

      //map.setShowSatellite(true)
      timeline.setActiveSatellite(timeline.satelliteTimeline.find((s) => s.satelliteID === insight.satelliteImageID))
    }, 0)
  }, [insight?.aoi, timeline.satelliteTimeline, map.projectionType])

  const isDemoSite = site.site?.isDemoSite
  const canUpdate = false //!isDemoSite && user.hasPermission(ClientPermission.InsightsUpdate)
  const canRemove = !isDemoSite && user.systemRole === SystemRole.Staff
  const canArchive = !isDemoSite && !insight?.archived
  const insightAsset = (timeline.activeSurvey?.assets || []).find((a) => a.id === insight?.assetID)

  if (insightState.insightToUpdate) {
    return null
  }

  return (
    <DrawerForm>
      <Helmet title={title} />
      <DrawerFormBreadcrumbs>
        <DrawerFormBreadcrumbItem
          title={asset.asset?.name || site.site?.name}
          onClick={() => {
            if (view.assetID) {
              location.setLocation(`/${site.site.id}/${view.surveyID}/assets/${view.assetID}`)
            } else {
              location.setLocation(`/${site.site.id}/${view.surveyID}`)
            }
          }}
        />
        <DrawerFormBreadcrumbItem title={title} />
      </DrawerFormBreadcrumbs>

      <DrawerFormContent noPaddingRight>
        <div className='issue-detail'>
          <div className='issue-detail-issue-number'>
            INSIGHT {insight?.number}
            {/* <CommentsIcon count={insight?.numComments} /> */}
            <Transition width={120} in={!!insight}>
              <div className='issue-detail-next-prev-container'>
                <div
                  onClick={() => {
                    if (insightIdx > 0) {
                      if (view.assetID) {
                        // location.setLocation(
                        //   `/${site.site.id}/${view.surveyID}/assets/${view.assetID}/insight/${
                        //     insights.insights[insightIdx - 1].id
                        //   }`
                        // )
                      } else {
                        location.setLocation(
                          `/${site.site.id}/${view.surveyID}/observation/${view.observationID}/insight/${
                            insights[insightIdx - 1].id
                          }`
                        )
                      }
                    }
                  }}
                  className={classes({
                    disabled: insightIdx === 0,
                  })}
                >
                  <i className='material-icons'>arrow_left</i>
                </div>
                <div
                  onClick={() => {
                    if (insightIdx < insights.length - 1) {
                      if (view.assetID) {
                        // location.setLocation(
                        //   `/${site.site.id}/${view.surveyID}/assets/${view.assetID}/insight/${
                        //     insights.insights[insightIdx + 1].id
                        //   }`
                        // )
                      } else {
                        location.setLocation(
                          `/${site.site.id}/${view.surveyID}/observation/${view.observationID}/insight/${
                            insights[insightIdx + 1].id
                          }`
                        )
                      }
                    }
                  }}
                  className={classes({
                    disabled: insightIdx === insights.length - 1,
                  })}
                >
                  <i className='material-icons'>arrow_right</i>
                </div>
              </div>
            </Transition>
          </div>
          <div className='issue-detail-name'>
            <Transition width={120} in={!!insight}>
              <div className='issue-detail-content'>
                <h1>{title}</h1>
                {(canUpdate || canRemove || canArchive) && (
                  <div style={{ position: 'relative' }}>
                    <ActionHover
                      withContainer
                      canEdit={canUpdate}
                      canRemove={canRemove}
                      canArchive={canArchive}
                      onClickDelete={() => {
                        setShowRemoveInsight(true)
                      }}
                      onClickArchive={() => {
                        setShowArchiveInsight(true)
                      }}
                      onClickEdit={() => {
                        insightState.setInsightToUpdate(insight)
                      }}
                    />
                  </div>
                )}
              </div>
            </Transition>
          </div>
          {showRemoveInsight && (
            <InsightRemove
              onRemove={() => {
                let p: Promise<any>
                if (view.assetID) {
                  // p = site.refetch().then(() => {
                  //   location.setLocation(`/${site.site.id}/${view.surveyID}/assets/${view.assetID}`)
                  // })
                } else {
                  p = site.refetch().then(() => {
                    location.setLocation(`/${site.site.id}/${view.surveyID}/observation//${view.observationID}`)
                  })
                }
                p.then(() => {
                  toasts.addTopLeft('Insight successfully removed')
                })
              }}
              hide={() => {
                setShowRemoveInsight(false)
              }}
            />
          )}
          {showArchiveInsight && (
            <InsightArchive
              onArchive={() => {
                toasts.addTopLeft('Insight successfully archived')
              }}
              hide={() => {
                setShowArchiveInsight(false)
              }}
            />
          )}
          <div className='issue-detail-badges'>
            <div className={`issue-detail-risk ${insight?.riskType.toLowerCase()}`}>
              {insight?.riskType ? insight.riskType + ' Risk' : ''}
            </div>
            {insight?.archived && (
              <div className='issue-detail-risk archived'>Archived</div>
            )}
            <div className='issue-detail-badges-filler'></div>
          </div>

          {insight?.description && (
            <div className='issue-detail-section'>
              <div className='issue-detail-subheading'>Description</div>
              <div className='issue-detail-content'>{insight.description || '-'}</div>
            </div>
          )}
          {insightAsset && (
            <>
              <div className='issue-detail-section'>
                <div className='issue-detail-subheading'>Asset</div>
                <div className='issue-detail-content'>{insightAsset?.name || '-'}</div>
              </div>
              <div className='issue-detail-section'>
                <div className='issue-detail-subheading'>Asset Type</div>
                <div className='issue-detail-content'>
                  {insightAsset?.assetType?.name || insight?.assetTypeName || '-'}
                </div>
              </div>
            </>
          )}
          <div className='issue-detail-section'>
            <div className='issue-detail-subheading'>Defect Type</div>
            <div className='issue-detail-content'>
              {insight?.defectType}
              {insight?.defectSubType ? ` - ${insight?.defectSubType}` : ''}
            </div>
          </div>
          <br />
          <br />
          {drawer.expandedPanel !== ExpandedPanel.Insights ? (
            <div
              onClick={() => {
                drawer.setExpandedPanel(ExpandedPanel.Insights)
              }}
              className='drawer-list-content-more'
            >
              View all insights
              <img src={CHEVRONS} title='View more' />
            </div>
          ) : (
            <div
              onClick={() => {
                drawer.closeExpandedPanel()
              }}
              className='drawer-list-content-more flipped'
            >
              Hide all insights <img src={CHEVRONS} title='View more' />
            </div>
          )}
        </div>
      </DrawerFormContent>
    </DrawerForm>
  )
}
