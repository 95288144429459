import React, { useEffect, useState } from 'react'
import { DemoViewType, useUser } from '~/base'
import { classes } from '~/components'
import { Config } from '~/config'
import ASSETI_LOGO from '../images/asseti-logo.svg'
import { useAppState } from '~/state'
import { ToolsContainer, ToolsIcon } from '~/tools'

interface HomeLayoutProps {
  children?: any
}

export const HomeLayout = (props: HomeLayoutProps) => {
  const isFromSwitch = localStorage.getItem('asseti_switch') === '1'
  const user = useUser()
  const currentOrg = user.org
  const requiresSubscription = currentOrg?.requiresSubscription
  const [showRequiresSubscription, setShowRequiresSubscription] = useState<boolean>(false)
  const { hubspotChat, site } = useAppState()
  const [showTools, setShowTools] = useState(false)

  useEffect(() => {
    if (requiresSubscription && !isFromSwitch) {
      setShowRequiresSubscription(true)
    }
  }, [])

  const canShowOpenSky = Array.isArray(site.site?.address?.center)

  return (
    <div
      className={classes({
        'home-layout': true,
      })}
    >
      <div className='switching-banner'>
        <div>
          Customer Support Session {requiresSubscription ? '(requires subscription)' : ''}
          <ToolsIcon setShowTools={() => setShowTools(!showTools)} />
        </div>
        <div>
          {canShowOpenSky && (
            <>
              <a
                className={`btn btn-sm active`}
                href={`https://opensky.wing.com/visitor/map?lat=${site.site.address.center[1]}&lng=${site.site.address.center[0]}&zoom=13`}
                target='_blank'
              >
                View on OpenSky
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </>
          )}
          <span>View:</span>
          <button
            className={`btn btn-sm ${user.ignoreIA ? 'active' : ''}`}
            onClick={() => {
              user.setIgnoreIA(!user.ignoreIA)
            }}
          >
            Toggle Full
          </button>
          <button
            className={`btn btn-sm ${user.demoViewType == DemoViewType.Basic ? 'active' : ''}`}
            onClick={() => {
              user.setBasicDemoRole()
            }}
          >
            Basic
          </button>
          <button
            className={`btn btn-sm ${user.demoViewType == DemoViewType.Medium ? 'active' : ''}`}
            onClick={() => {
              user.setMediumDemoRole()
            }}
          >
            Moderate
          </button>
          <button
            className={`btn btn-sm ${user.demoViewType == DemoViewType.Advanced ? 'active' : ''}`}
            onClick={() => {
              user.setAdvancedDemoRole()
            }}
          >
            Advanced
          </button>
          <button
            className='btn btn-sm'
            onClick={() => {
              localStorage.removeItem('asseti_switch')
              if (window.location.host === 'localhost:1234') {
                localStorage.setItem('asseti_auth', localStorage.getItem('asseti_old_data'))
                localStorage.setItem('asseti_token', localStorage.getItem('asseti_old_token'))
              } else {
                user.endSupportSession()
              }
              window.location.href = Config.BaseUrl
            }}
          >
            End Session
          </button>
        </div>
      </div>
      <ToolsContainer show={showTools} />
      {props.children}
      {showRequiresSubscription && (
        <div className='requires-subscription-popup'>
          <div className='requires-subscription-popup-content'>
            <div className='requires-subscription-popup-content-header'>
              <img src={ASSETI_LOGO} alt='Asseti Logo' /> <span>Asseti</span>
            </div>
            <div className='requires-subscription-popup-content-text'>
              <h5>A subscription is required to continue using Asseti</h5>
            </div>
            <div className='requires-subscription-popup-content-buttons'>
              <button className='btn btn-secondary' onClick={() => setShowRequiresSubscription(false)}>
                Still evaluating
              </button>
              <button
                className='btn btn-primary'
                onClick={() => {
                  setShowRequiresSubscription(false)
                  hubspotChat.openHandler()
                }}
              >
                Contact sales
              </button>
            </div>
            <br />
          </div>
          <div className='requires-subscription-popup-overlay'></div>
        </div>
      )}
    </div>
  )
}
