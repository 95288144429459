import React, { useState } from 'react'
import { useUser } from '~/base'
import { classes, DrawerState, useLocation } from '~/components'
import LOGO from '~/images/asseti_icon_red.png'
import FORECASTING from '~/images/main-bar/forecasting.png'
import { useAppState } from '~/state'
import { ViewStateType } from '~/state/use-drawer-state'

enum PopoutType {
  None,
  Forecasting,
  WorkOrders,
  VendorPortal,
  Messaging,
  Automation,
  Alerts,
}

export const MainBar = () => {
  const { drawer } = useAppState()
  const location = useLocation()
  const user = useUser()
  const [popout, setPopout] = useState<PopoutType>(PopoutType.None)

  let showForecasting = true
  if (
    user.currentOrgId === '01FP9D6098B274FG92W3MS6JFM' ||
    user.currentOrgId === '01GH7MJXR83WYFSJA7QQY2FJ8E' ||
    user.currentOrgId === '01F5FGNHAW61N3W8PE2G51CYNB' ||
    user.currentOrgId === '01HWVS3FM1DA0J7811NBFF07BF'
  ) {
    showForecasting = false
  }

  return (
    <>
      <div className='main-bar'>
        <div className='main-bar-logo'>
          <img src={LOGO} />
        </div>
        <div className='main-bar-nav'>
          <MainBarNavItem
            name='Portfolio'
            icon='fa-city'
            active={popout === PopoutType.None}
            onClick={() => {
              location.setLocation('/')
              setPopout(PopoutType.None)
            }}
          />
          <MainBarNavItem
            name='Insights'
            icon='fa-chart-simple'
            onClick={() => {
              location.setLocation('/dashboards')
              setPopout(PopoutType.None)
            }}
          />
          {showForecasting && (
            <MainBarNavItem
              name='Forecasts'
              icon='fa-chart-bar'
              active={popout === PopoutType.Forecasting}
              onClick={() => {
                setPopout(PopoutType.Forecasting)
              }}
            />
          )}
          <MainBarNavItem
            name='Work Orders'
            icon='fa-bars-staggered'
            active={popout === PopoutType.WorkOrders}
            onClick={() => {
              setPopout(PopoutType.WorkOrders)
            }}
          />
          <MainBarNavItem
            name='Vendors'
            icon='fa-users'
            active={popout === PopoutType.VendorPortal}
            onClick={() => {
              setPopout(PopoutType.VendorPortal)
            }}
          />
          <MainBarNavItem
            name='Messages'
            icon='fa-message'
            active={popout === PopoutType.Messaging}
            onClick={() => {
              setPopout(PopoutType.Messaging)
            }}
          />
          <MainBarNavItem
            name='Automation'
            icon='fa-layer-group'
            active={popout === PopoutType.Automation}
            onClick={() => {
              setPopout(PopoutType.Automation)
            }}
          />
        </div>
        <div className='main-bar-spacer'></div>
        <div className='main-bar-nav'>
          <MainBarNavItem
            name='Alerts'
            icon='fa-bell'
            active={popout === PopoutType.Alerts}
            onClick={() => {
              setPopout(PopoutType.Alerts)
            }}
          />
          <MainBarNavItem name='Upload' icon='fa-upload' />
          <MainBarNavItem
            name='Help'
            icon='fa-question'
            onClick={() => {
              drawer.setRightView(ViewStateType.Support)
            }}
          />
          <MainBarNavItem
            name='Account'
            icon='fa-user'
            onClick={() => {
              drawer.setRightView(ViewStateType.Account)
              drawer.rightExpanded ? null : drawer.toggleRight()
              drawer.rightClosed ? drawer.set(drawer.left, DrawerState.Expanded) : null
            }}
          />
        </div>
      </div>

      {popout === PopoutType.Forecasting && <MainBarPopoutForecasting clickAway={() => setPopout(PopoutType.None)} />}
      {popout === PopoutType.WorkOrders && <MainBarPopoutWorkOrders clickAway={() => setPopout(PopoutType.None)} />}
      {popout === PopoutType.VendorPortal && <MainBarPopoutVendors clickAway={() => setPopout(PopoutType.None)} />}
      {popout === PopoutType.Messaging && <MainBarPopoutMessaging clickAway={() => setPopout(PopoutType.None)} />}
      {popout === PopoutType.Automation && <MainBarPopoutAutomation clickAway={() => setPopout(PopoutType.None)} />}
      {popout === PopoutType.Alerts && <MainBarPopoutAlerts clickAway={() => setPopout(PopoutType.None)} />}
    </>
  )
}

interface MainBarNavItemProps {
  name: string
  icon: string
  active?: boolean
  onClick?: () => void
}

const MainBarNavItem = (props: MainBarNavItemProps) => {
  return (
    <div
      className={classes({
        'main-bar-nav-item': true,
        active: props.active,
      })}
      onClick={props.onClick}
    >
      <div>
        <span className={'fa-solid ' + props.icon}></span>
      </div>
      <div>{props.name}</div>
    </div>
  )
}

interface MainBarPopoutProps {
  title: string
  icon?: string
  children?: any
  clickAway: () => void
}

const MainBarPopout = (props: MainBarPopoutProps) => {
  return (
    <>
      <div
        className='main-bar-popout-cover'
        style={{ top: 0, bottom: 0, right: 0, left: 0 }}
        onClick={props.clickAway}
      ></div>
      <div className='main-bar-popout'>
        <div className='main-bar-popout-icon'>
          <img src={props.icon} />
        </div>
        <div className='main-bar-popout-title'>
          <span>{props.title}</span> coming soon
        </div>
        {props.children}
        <div className='main-bar-popout-button'>
          <button className='btn btn-main-bar-popout'>Click here to join the waitlist</button>
        </div>
      </div>
    </>
  )
}

interface MainBarPopoutItemProps {
  clickAway: () => void
}

const MainBarPopoutForecasting = (props: MainBarPopoutItemProps) => {
  return (
    <MainBarPopout title='Forecasting' icon={FORECASTING} clickAway={props.clickAway}>
      Our Forecasting feature will transform your CapEx and budget planning, offering predictive insights to help you
      plan future expenses, allocate resources more effectively, and maximize your financial strategy
    </MainBarPopout>
  )
}

const MainBarPopoutWorkOrders = (props: MainBarPopoutItemProps) => {
  return (
    <MainBarPopout title='Work Orders' icon={FORECASTING} clickAway={props.clickAway}>
      Streamlines the management of all asset-related tasks. Easily track progress from request to completion, ensuring
      efficient workflows and seamless coordination across your entire portfolio
    </MainBarPopout>
  )
}

const MainBarPopoutVendors = (props: MainBarPopoutItemProps) => {
  return (
    <MainBarPopout title='Vendor Portal' icon={FORECASTING} clickAway={props.clickAway}>
      Streamlines the management of all asset-related tasks. Easily track progress from request to completion, ensuring
      efficient workflows and seamless coordination across your entire portfolio
    </MainBarPopout>
  )
}

const MainBarPopoutMessaging = (props: MainBarPopoutItemProps) => {
  return (
    <MainBarPopout title='Messaging' icon={FORECASTING} clickAway={props.clickAway}>
      Effortlessly communicate and track all conversations with contractors and vendors in one place. Keep your team in
      sync, ensuring nothing gets lost and every task stays on track.
    </MainBarPopout>
  )
}

const MainBarPopoutAutomation = (props: MainBarPopoutItemProps) => {
  return (
    <MainBarPopout title='Automation' icon={FORECASTING} clickAway={props.clickAway}>
      Automate your workflows and set custom rules to streamline processes, saving time and ensuring faster outcomes.
      From task assignments to notifications, let automation do the heavy lifting while you focus on strategic
      decisions.
    </MainBarPopout>
  )
}

const MainBarPopoutAlerts = (props: MainBarPopoutItemProps) => {
  return (
    <MainBarPopout title='Alerts' icon={FORECASTING} clickAway={props.clickAway}>
      From tags and job status changes to weather warnings, our system keeps you informed in real-time, ensuring you're
      always in control and able to respond swiftly to any changes or risks.
    </MainBarPopout>
  )
}
