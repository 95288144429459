query tasksLatest {
  tasksLatest {
    id
    archived
    currentBlueprint {
      id
      name
      description
      target
      excludedParentIDs
      excludedTargetIDs
      automaticallyIncludeNewTargets
      assignedToByDefault {
        id
        name
        email
      }
      watchedByDefault {
        id
        name
        email
      }
      assignments {
        id
        targetID
        assignedTo {
          id
          name
          email
        }
        watchedBy {
          id
          name
          email
        }
      }
      schedule {
        ... on TaskScheduleOnceOffDtoT {
          id
          date
        }
        ... on TaskScheduleRecurringDtoT {
          id
          interval
          startDate
          endDate
          multiplier
        }
      }
      items {
        id
        text
      }
      createdBy
      createdAt
    }
    lastScheduled {
      id
      taskID
      taskBlueprintID
      date
      dueBy
      targets {
        id
        scheduledTaskID
        targetType
        targetID
        targetName
        targetParentID
        targetParentName
        assignedTo {
          id
          name
          email
        }
        watchedBy {
          id
          name
          email
        }
        items {
          id
          text
          scheduledTaskTargetID
          taskBlueprintID
          taskBlueprintItemID
          completed
          completedBy
          completedAt
        }
      }
    }
  }
}