import { Avatar, DrawerState, classes, getTracking } from '~/components'
import CHEVRONS from '../images/chevrons-right.svg'
import React from 'react'
import { useAppState } from '~/state'
import { ClientPermission, useUser } from '~/base'
import { ViewStateType } from '~/state/use-drawer-state'

interface RightDrawerNavProps {
  view: ViewStateType
  setView: React.Dispatch<React.SetStateAction<ViewStateType>>
  haveUnread: boolean
}

export const RightDrawerNav = (props: RightDrawerNavProps) => {
  const { drawer, notifications } = useAppState()
  const user = useUser()
  const canSeeDashboards = user.hasPermission(ClientPermission.DashboardsList) && !user.org.isBasic
  const canSeeNotifications = !user.org.isBasic
  const mustShowChevrons = user.org.isBasic
    ? props.view === ViewStateType.Support || props.view === ViewStateType.Account
    : drawer.rightExpanded && props.view

  return (
    <nav className='right-drawer-nav'>
      <div className='right-drawer-nav-items'>
        <div className='right-drawer-nav-items-item'>
          {mustShowChevrons ? (
            <img
              src={CHEVRONS}
              className='right-drawer-nav-items-item-expand expanded'
              alt='Expand right drawer'
              onClick={() => {
                props.setView(ViewStateType.Activity)
                if (user.org.isBasic) {
                  setTimeout(() => {
                    drawer.set(drawer.left, DrawerState.Hidden)
                  }, 100)
                } else {
                  setTimeout(() => {
                    drawer.toggleRight()
                  }, 100)
                }
              }}
            />
          ) : null}
          {props.view === ViewStateType.Weather && <h3 className='weather-table-title'>Hourly Weather Forecast</h3>}
        </div>
        {!user.org.isBasic && (
          <div
            className={`right-drawer-nav-items-item ${props.view === ViewStateType.Activity ? 'active' : ''}`}
            onClick={() => {
              getTracking().event({
                category: 'Button',
                action: `User clicked activity button`,
                label: 'Activity',
              })
              props.setView(ViewStateType.Activity)
              drawer.rightExpanded ? drawer.toggleRight() : null
            }}
          >
            <i className='material-icons'>dynamic_feed</i>
          </div>
        )}
        {canSeeNotifications && (
          <div
            className={classes({
              'right-drawer-nav-items-item': true,
              active: props.view === ViewStateType.Notifications,
              alerting: notifications.haveUnread,
            })}
            onClick={() => {
              getTracking().event({
                category: 'Button',
                action: `User clicked activity button`,
                label: 'Activity',
              })
              props.setView(ViewStateType.Notifications)
              drawer.rightExpanded ? drawer.toggleRight() : null
            }}
          >
            <i className='material-icons'>{notifications.haveUnread ? 'notifications_active' : 'notifications'}</i>
          </div>
        )}
        {canSeeDashboards && (
          <div
            className={`right-drawer-nav-items-item ${props.view === ViewStateType.Analytics ? 'active' : ''}`}
            onClick={() => {
              getTracking().event({
                category: 'Button',
                action: `User clicked analytics button`,
                label: 'Analytics',
              })
              props.setView(ViewStateType.Analytics)
              drawer.rightExpanded ? drawer.toggleRight() : null
              //drawer.rightExpanded ? null : drawer.toggleRight()
            }}
          >
            <i className='material-icons'>bar_chart</i>
          </div>
        )}
        <div
          className={`right-drawer-nav-items-item ${props.view === ViewStateType.Support ? 'active' : ''}`}
          onClick={() => {
            getTracking().event({
              category: 'Button',
              action: `User clicked support button`,
              label: 'Support',
            })
            props.setView(ViewStateType.Support)
            drawer.rightExpanded ? drawer.toggleRight() : null
            drawer.rightClosed ? drawer.set(drawer.left, DrawerState.Normal) : null
          }}
        >
          <i className='material-icons'>help</i>
        </div>
        <div
          className={`right-drawer-nav-items-item ${props.view === ViewStateType.Account ? 'active' : ''}`}
          onClick={() => {
            getTracking().event({
              category: 'Button',
              action: `User clicked account button`,
              label: 'Account',
            })
            props.setView(ViewStateType.Account)
            drawer.rightExpanded ? null : drawer.toggleRight()
            drawer.rightClosed ? drawer.set(drawer.left, DrawerState.Expanded) : null
          }}
        >
          <Avatar title='Account' className='right-drawer-nav-items-item-avatar avatar' width={40} height={40} />
        </div>
      </div>
    </nav>
  )
}
