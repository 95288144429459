import { Currency, MeasurementSystem } from './models'

interface Configuration {
  // Display.
  DefaultCurrency: Currency
  DefaultMeasurementSystem: MeasurementSystem
  DefaultMapRectangle: Cesium.Rectangle

  // URLs.
  GraphQLBaseURL: string
  BaseUrl: string
  BaseFileUrlWithSubdomains: string
  BaseUploadUrl: string
  BaseUploadUrlOrder: string
  RegionalDomain: string
  RegionalSubdomain: string

  // Demo.
  DemoDataKeyID: string
  DemoDataPolicy: string
  DemoDataSignature: string
  DemoOrgID: string

  // Matterport.
  MatterportClientID: string
  MatterportRedirectURL: string
  MatterportScope: string

  // Analytics.
  HubspotPortalId: string

  // Mapbox.
  MapBoxToken: string

  // Cesium.
  CesiumAPIKey: string

  // Feature toggles.
  UseTasks: boolean
  UseAnalysis: boolean
  UseNotifications: boolean
}

const DeploymentDefaults: Configuration = {
  DefaultCurrency: Currency.Aud,
  DefaultMeasurementSystem: MeasurementSystem.Imperial,
  DefaultMapRectangle: Cesium.Rectangle.fromDegrees(108.032977, -4.99484, 160.846313, -46.975809),

  GraphQLBaseURL: process.env.GQL_BASE_URL || 'https://test.asseti.co',
  BaseUrl: process.env.BASE_URL || 'https://test.asseti.co',
  BaseFileUrlWithSubdomains: process.env.BASE_FILE_URL_WITH_SUBDOMAINS || 'https://{s}-test.asseti.co/',
  BaseUploadUrl: process.env.BASE_UPLOAD_URL || 'https://upload-test.asseti.co',
  BaseUploadUrlOrder: process.env.BASE_UPLOAD_URL_ORDERS || 'http://localhost:8097',
  RegionalDomain: process.env.REGIONAL_DOMAIN || 'asseti.co',
  RegionalSubdomain: process.env.REGIONAL_SUBDOMAIN || '',

  DemoDataKeyID: process.env.DEMO_DATA_KEY_ID || '',
  DemoDataPolicy: process.env.DEMO_DATA_POLICY || '',
  DemoDataSignature: process.env.DEMO_DATA_SIGNATURE || '',
  DemoOrgID: process.env.DEMO_ORG_ID || '',

  MatterportClientID: process.env.MATTERPORT_CLIENT_ID || 'nfthc9ctdzwk9c637msuwsc7d',
  MatterportRedirectURL: process.env.MATTERPORT_REDIRECT_URL || 'http://localhost:1234/matterport-auth-success',
  MatterportScope: process.env.MATTERPORT_SCOPE || 'ViewDetails DownloadAssets PurchaseAssets',

  HubspotPortalId: '7556667',

  MapBoxToken: 'pk.eyJ1IjoiYXNzZXRpIiwiYSI6ImNrbGtnb3B4bzA0ZGkyb2p3bHJhNWtxdWoifQ.LZqVW-BKGajnURmqCkLErA',
  CesiumAPIKey:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwNjQxYzY0NC1lMzdjLTQ5YjktOGRiMC0xZjQ2OGM0NGYwYzIiLCJpZCI6NDMyMjAsImlhdCI6MTYxMjMzMjU2NH0.fb83K1E2PHZDd37UzjQrur18rQcJ1JxY0xdD5Y8JYv4',

  UseTasks: process.env.USE_TASKS !== 'false',
  UseAnalysis: process.env.USE_ANALYSIS !== 'false',
  UseNotifications: process.env.USE_NOTIFICATIONS !== 'false',
}

const DeploymentAU: Partial<Configuration> = {
  DefaultCurrency: Currency.Aud,
  DefaultMeasurementSystem: MeasurementSystem.Metric,
  DefaultMapRectangle: Cesium.Rectangle.fromDegrees(108.032977, -4.99484, 160.846313, -46.975809),
}

const DeploymentUS: Partial<Configuration> = {
  DefaultCurrency: Currency.Usd,
  DefaultMeasurementSystem: MeasurementSystem.Imperial,
  DefaultMapRectangle: Cesium.Rectangle.fromDegrees(108.032977, -4.99484, 160.846313, -46.975809),
}

const deploymentToUse: Partial<Configuration> = process.env.DEPLOYMENT === 'us' ? DeploymentUS : DeploymentAU
const finalDeployment: Configuration = { ...DeploymentDefaults, ...deploymentToUse }
export const Config: Configuration = finalDeployment
