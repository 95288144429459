import React, { useEffect, useState } from 'react'
import { useUser } from '~/base'
import {
  FloatingTextArea,
  PolygonColorRed,
  PolygonRenderer,
  PolygonRendererCreate,
  PolygonRendererEdit,
  shortDate,
  useMutation,
  useToasts,
} from '~/components'
import { useDefectTypes } from '~/issues/components'
import { DefectTypeSelect, RiskTypeSelect } from '~/issues/selects'
import {
  InsightCreateMutation,
  InsightCreateMutationVariables,
  InsightCreateRequestT,
  PointInputT,
  RiskType,
  SiteQueryMonitoringZone,
} from '~/models'
import { useAppState } from '~/state'
import CREATE_INSIGHT_MUTATION from './mutation-insight-create.gql'

export const ToolsInsightTagging = () => {
  const { map, timeline, view, site } = useAppState()
  const activeSurvey = timeline.activeSurvey
  const [saving, setSaving] = useState<boolean>(false)
  const [mzs] = useState([...(activeSurvey?.monitoringZones || [])].sort((a, b) => (a.name < b.name ? 1 : -1)))
  const [selectedMZ, setSelectedMZ] = useState<SiteQueryMonitoringZone>(mzs.length > 0 ? mzs[0] : undefined)
  const defectTypes = useDefectTypes()
  const toasts = useToasts()
  const user = useUser()
  const [saved, setSaved] = useState<boolean>(false)
  const [input, setInput] = useState<InsightCreateRequestT>({
    orgID: user.org.id,
    siteID: view.siteID,
    surveyID: view.surveyID,
    assetID: view.assetID,
    satelliteImageID: '',
    aoi: [],
    defectSubTypeID: '',
    defectTypeID: '',
    description: '',
    monitoringZoneID: '',
    riskType: RiskType.Low,
  })
  const [points, setPoints] = useState<PointInputT[]>([])

  const [executeSave] = useMutation<InsightCreateMutation, InsightCreateMutationVariables>(CREATE_INSIGHT_MUTATION)

  useEffect(() => {
    if (!map.showSatellite) {
      map.setShowSatellite(true)
    }
  }, [map.showSatellite])

  useEffect(() => {
    if(!selectedMZ) {
      return
    }
    if (selectedMZ.points.length > 0) {
      const points: Cesium.Cartographic[] = []

      for (const p of selectedMZ.points) {
        points.push(
          Cesium.Cartographic.fromDegrees(p.longitude, p.latitude, map.viewer.camera.positionCartographic.height)
        )
      }

      map.viewer.camera.flyTo({
        destination: Cesium.Rectangle.fromCartographicArray(points),
        duration: 0,
      })
    }
  }, [selectedMZ])

  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      const key = e.key.toLocaleLowerCase()
      if (key === 'n') {
        if (selectedMZ) {
          const assetIndex = mzs.findIndex((a) => a.id === selectedMZ.id)
          if (assetIndex === mzs.length - 1) {
            setSelectedMZ(mzs[0])
          } else {
            setSelectedMZ(mzs[assetIndex + 1])
          }
        } else {
          if (mzs.length > 0) {
            setSelectedMZ(mzs[0])
          }
        }
      } else if (key === 'p') {
        if (selectedMZ) {
          const assetIndex = mzs.findIndex((a) => a.id === selectedMZ.id)
          if (assetIndex === 0) {
            setSelectedMZ(mzs[mzs.length - 1])
          } else {
            setSelectedMZ(mzs[assetIndex - 1])
          }
        } else {
          if (mzs.length > 0) {
            setSelectedMZ(mzs[0])
          }
        }
      }
    }

    window.addEventListener('keydown', onKeyDown, false)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [selectedMZ, mzs])

  const mzIndex = mzs.findIndex((x) => x.id === selectedMZ?.id)

  useEffect(() => {
    if(saved) {
      setSaved(false)
      return
    }
    map.setShowControls(false)
    let renderer: PolygonRenderer

    renderer = new PolygonRendererCreate(map, PolygonColorRed, (points) => {
      if (!points) {
        return
      }
      renderer.destroy()
      renderer = new PolygonRendererEdit(
        points.map((p) => {
          const pos = Cesium.Cartographic.fromCartesian(p)
          return {
            latitude: (pos.latitude / Math.PI) * 180,
            longitude: (pos.longitude / Math.PI) * 180,
          }
        }),
        map,
        PolygonColorRed,
        (points) => {
          setPoints(points.map((p) => {
            const pos = Cesium.Cartographic.fromCartesian(p)
            return {
              latitude: (pos.latitude / Math.PI) * 180,
              longitude: (pos.longitude / Math.PI) * 180,
            }
          }))
        }
      )
    })

    return () => {
      renderer.destroy()
      map.setShowControls(true)
    }
  }, [saved])

  const save = () => {
    setSaving(true)

    executeSave({
      variables: {
        input: {
          ...input,
          aoi: points,
          satelliteImageID: timeline.activeSatellite.satelliteID,
          monitoringZoneID: selectedMZ.id,
        },
      },
    })
      .then(() => {
        setSaved(true)
        setInput({
          ...input,
          aoi: [],
          description: '',
        })
        setPoints([])
        toasts.addTopLeft('Insight saved')
        site.refetch()

      })
      .finally(() => {
        setSaving(false)
      })
  }

  const isValid = () => {
    if(!input.description || input.description.length < 3) {
      return false
    }

    if(points.length < 3) {
      return false
    }

    if(!selectedMZ) {
      return false
    }

    if(!input.defectTypeID || input.defectTypeID === '') {
      return false
    }

    return true
  }  

  return (
    <>
      <br />
      <div style={{ fontSize: '12px', paddingLeft: '0.5rem' }}>
        Satellite: <b>{shortDate(timeline.activeSatellite?.date) || 'None'}</b>
      </div>
      <div style={{ fontSize: '12px', paddingLeft: '0.5rem' }}>
        Survey: <b>{shortDate(timeline.activeSurvey?.startDate) || 'None'}</b>
      </div>
      <div style={{ fontSize: '12px', paddingLeft: '0.5rem' }}>
        MZ: <b>{selectedMZ?.name || '-'}</b>
      </div>
      <div style={{ fontSize: '12px', paddingLeft: '0.5rem' }}>
        Type: <b>{selectedMZ?.targetName + ' - ' + selectedMZ?.subTargetName}</b>
      </div>
      <div style={{ fontSize: '12px', paddingLeft: '0.5rem' }}>
        Desc: <b>{selectedMZ?.description || '-'}</b>
      </div>
      <div style={{ fontSize: '12px', paddingLeft: '0.5rem' }}>
        MZ number{' '}
        <b>
          {mzIndex === -1 ? '-' : mzIndex + 1} of {mzs.length}
        </b>
      </div>
      <div style={{ fontSize: '12px', paddingLeft: '0.5rem', opacity: 0.75 }}>
        <br />
        <small>n = next</small>
        <br />
        <small>p = previous</small>
      </div>
      <br />
      <div style={{ paddingLeft: '8px' }}>
        <FloatingTextArea
          label='Description'
          id='description'
          value={input.description}
          onChange={(e) => {
            setInput({
              ...input,
              description: e.target.value,
            })
          }}
          helperText={''}
          rows={3}
        />
        <DefectTypeSelect
          defectTypeID={input.defectTypeID}
          defectSubTypeID={input.defectSubTypeID}
          defectTypes={defectTypes}
          onChange={(d, s) => {
            setInput({
              ...input,
              defectTypeID: d.id,
              defectSubTypeID: s.id,
            })
          }}
        />
        <RiskTypeSelect
          selectedValue={RiskType.Low}
          onChange={(v) => {
            setInput({
              ...input,
              riskType: v,
            })
          }}
        />
        <button className='btn btn-primary' disabled={saving || !isValid()} onClick={save}>
          {saving ? 'Saving' : 'Save'}
        </button>
        <br />
        <br />
      </div>
    </>
  )
}
