import { useHistory } from 'react-router'

export interface UseLocation {
  location: string
  setLocation: (location: string) => void
  replaceLocation: (location: string) => void
}

export function useLocation() {
  const history = useHistory()

  const dummy = () => {
    // Do nothing.
  }

  return {
    location: history?.location?.pathname || window.location.pathname,
    setLocation: (location: string) => {
      ;(history?.push || dummy)(location)

      // console.trace('Set location to ' + location)
    },
    replaceLocation: (location: string) => {
      ;(history?.replace || dummy)(location)
    },
  }
}
