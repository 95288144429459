import { AuthButton, Drawer, DrawerPosition, DrawerState, useLocation } from '~/components'
import React, { useEffect, useState } from 'react'

import { Account } from '../account'
import { Activity } from './activity'
import { RightDrawerNav } from './right-drawer-nav'
import { Support } from './support'
import { useAppState } from '~/state'
import { Notifications } from './notifications'
import { useUser } from '~/base'
import { Weather } from './weather'
import { WeatherForSiteQuery } from '~/models'
import { ViewStateType } from '~/state/use-drawer-state'

export const RightDrawer = () => {
  const { drawer, hubspotChat, notifications, view: viewState } = useAppState()
  const user = useUser()
  const [weather, setWeather] = useState<WeatherForSiteQuery['weatherForSite']>()

  useEffect(() => {
    return () => {
      drawer.setRightView(ViewStateType.Activity)
      setWeather(undefined)
      drawer.set(drawer.left, DrawerState.Normal)
    }

  }, [viewState.siteID])

  if (hubspotChat?.isOpen) {
    const widget: HTMLDivElement = document.querySelector('.widget-align-right')

    if (widget) {
      widget.classList.remove('expanded')
    }

    if (widget && drawer.rightExpanded) {
      widget.classList.add('expanded')
    }
  }

  return (
    <Drawer
      state={drawer.right}
      position={DrawerPosition.Right}
      transparent={user.org.isBasic && drawer.rightView !== ViewStateType.Support && drawer.rightView !== ViewStateType.Account}
    >
      <RightDrawerNav view={drawer.rightView} haveUnread={notifications.haveUnread} setView={drawer.setRightView} />
      {drawer.rightView === ViewStateType.Activity && !drawer.rightExpanded && !user.org.isBasic && (
        <Activity setView={drawer.setRightView} weather={weather} setWeather={setWeather} />
      )}
      {drawer.rightView === ViewStateType.Notifications && !drawer.rightExpanded && <Notifications />}
      {drawer.rightView === ViewStateType.Analytics && !drawer.rightExpanded && !user.org.isBasic && <Dashboards />}
      {drawer.rightView === ViewStateType.Support && !drawer.rightExpanded && <Support />}
      {drawer.rightView === ViewStateType.Account && drawer.rightExpanded && <Account />}
      {drawer.rightView === ViewStateType.Weather && drawer.rightExpanded && <Weather weather={weather} />}
    </Drawer>
  )
}

function Dashboards() {
  const location = useLocation()
  return (
    <div className='dashboards-list-empty'>
      <div className='dashboards-list-empty-spacer-top'>&nbsp;</div>
      <div className='dashboards-list-empty-container' style={{ padding: '1rem' }}>
        <i className='material-icons'>bar_chart</i>
        <h5 className='dashboards-list-empty-title'>Dashboards</h5>
        <p className='dashboards-list-empty-body2'>
          Flexible and concise analysis
          <br />
          of your asset data
        </p>
        <AuthButton
          style={{ width: 'auto', paddingLeft: '2rem', paddingRight: '2rem', backgroundColor: '#f00' }}
          onClick={() => {
            location.setLocation('/dashboards')
          }}
        >
          Go to dashboards
        </AuthButton>
      </div>
      <div className='dashboards-list-empty-spacer-bottom'>&nbsp;</div>
    </div>
  )
}
