import React from 'react'
import { formatCurrency } from '~/components'
import { Currency, InstantAssessmentSummaryAllSitesQuery, MeasurementSystem } from '~/models'
import { formatArea2 } from '~/reports/ia'
import { useAppState } from '~/state'

type Asset = InstantAssessmentSummaryAllSitesQuery['instantAssessmentSummaryAllSites']['sites'][0]['assets'][0]
type Component =
  InstantAssessmentSummaryAllSitesQuery['instantAssessmentSummaryAllSites']['sites'][0]['assets'][0]['components'][0]
type WithAssets = { assets: Asset[] }

interface SiteComponentsProps {}

export const SiteComponents = (props: SiteComponentsProps) => {
  const { site, asset, sites, view } = useAppState()
  const summary = sites.iaQuery?.data?.instantAssessmentSummaryAllSites

  const siteSummary = summary && site.site && summary.sites.find((s) => s.siteName === site.site.name)
  const assetSummary = summary && asset.asset && siteSummary.assets.find((a) => a.assetName === asset.asset.name)

  const summaryToUse: WithAssets = (view.assetID ? assetSummary && { assets: [assetSummary] } : siteSummary) || {
    assets: [],
  }
  const components: Component[] = summaryToUse.assets
    .reduce((a, b) => {
      for (const c of b.components) {
        const existingIndex = a.findIndex((x) => x.componentName === c.componentName)
        if (existingIndex === -1) {
          a.push(c)
        } else {
          a[existingIndex].materialCost += c.materialCost
          a[existingIndex].quantity += c.quantity
        }
      }

      return a
    }, [])
    .sort((a: Component, b: Component) => {
      if (a.materialCost === b.materialCost) {
        return 0
      }
      return a.materialCost > b.materialCost ? -1 : 1
    })

  return (
    <div className='drawer-list-content'>
      <h5>Components</h5>
      {components.map((c) => {
        return <ComponentCard2 key={c.componentName} component={c} />
      })}
    </div>
  )
}

interface ComponentCardProps {
  component: Component
}

// const ComponentCard = (props: ComponentCardProps) => {
//   const c = props.component
//   return (
//     <div className='ia-component-card'>
//       <div className='ia-component-card-name'>{c.componentName}</div>
//       <div className='ia-component-card-stats'>
//         <div className='ia-component-card-stat'>
//           <div className='ia-component-card-stat-title'>Qty</div>
//           <div className='ia-component-card-stat-value'>{Math.floor(c.quantity)}</div>
//         </div>
//         <div className='ia-component-card-stat'>
//           <div className='ia-component-card-stat-title'>Type</div>
//           <div className='ia-component-card-stat-value'>{c.unit}</div>
//         </div>
//         <div className='ia-component-card-stat'>
//           <div className='ia-component-card-stat-title'>Cost (USD)</div>
//           <div className='ia-component-card-stat-value'>{formatCurrency(c.materialCost, 0, Currency.Usd)}</div>
//         </div>
//         <div className='ia-component-card-stat'>
//           <div className='ia-component-card-stat-title'>Condition</div>
//           <div className='ia-component-card-stat-value'>{c.conditionString}</div>
//         </div>
//         <div className='ia-component-card-stat'>
//           <div className='ia-component-card-stat-title'>Remaining useful life</div>
//           <div className='ia-component-card-stat-value'>{Math.floor(100 - c.conditionPercent)}%</div>
//         </div>
//       </div>
//     </div>
//   )
// }

const ComponentCard2 = (props: ComponentCardProps) => {
  const c = props.component
  const nameParts = c.componentName.split(' - ')
  let name = nameParts[nameParts.length - 1]
  nameParts.splice(nameParts.length - 1)
  if (name === 'Other') {
    name = nameParts[nameParts.length - 1]
    nameParts.splice(nameParts.length - 1)
  }
  const type = nameParts.join(' - ')

  let qty = c.quantity + ' units'
  if (c.unit === 'sqft') {
    qty = formatArea2(c.quantity, 0, MeasurementSystem.Imperial) + ' sqft'
    if(qty.includes('M')) {
      qty = formatArea2(c.quantity, 2, MeasurementSystem.Imperial)
    }
  } else if (c.unit === 'ft') {
    qty = formatArea2(c.quantity, 0, MeasurementSystem.Imperial) + ' ft'
  }

  let cost = formatCurrency(c.materialCost, 0, Currency.Usd)
  if(cost.includes('M')) {
    cost = formatCurrency(c.materialCost, 2, Currency.Usd)
  }

  return (
    <div className='ia-component-card2'>
      <div className='ia-component-card2-split'>
        <div className='ia-component-card2-type'>{type}</div>
        <div className='ia-component-card2-qty'>{qty}</div>
      </div>
      <div className='ia-component-card2-split'>
        <div className='ia-component-card2-name'>{name}</div>
        <div className='ia-component-card2-cost'>{cost}</div>
      </div>
      <ProgressBar condition={c.conditionString} percent={c.conditionPercent} />

      {/* <div className='ia-component-card-stats'>
        <div className='ia-component-card-stat'>
          <div className='ia-component-card-stat-title'>Qty</div>
          <div className='ia-component-card-stat-value'>{Math.floor(c.quantity)}</div>
        </div>
        <div className='ia-component-card-stat'>
          <div className='ia-component-card-stat-title'>Type</div>
          <div className='ia-component-card-stat-value'>{c.unit}</div>
        </div>
        <div className='ia-component-card-stat'>
          <div className='ia-component-card-stat-title'>Cost (USD)</div>
          <div className='ia-component-card-stat-value'>{formatCurrency(c.materialCost, 0, Currency.Usd)}</div>
        </div>
        <div className='ia-component-card-stat'>
          <div className='ia-component-card-stat-title'>Condition</div>
          <div className='ia-component-card-stat-value'>{c.conditionString}</div>
        </div>
        <div className='ia-component-card-stat'>
          <div className='ia-component-card-stat-title'>Remaining useful life</div>
          <div className='ia-component-card-stat-value'>{Math.floor(100 - c.conditionPercent)}%</div>
        </div>
      </div> */}
    </div>
  )
}

interface ProgressBarProps {
  percent: number
  condition: string
}

const ProgressBar = (props: ProgressBarProps) => {
  const score = Math.floor(props.percent)
  let color = '#FF0000'
  if (score > 90) {
    color = '#00BF63'
  } else if (score >= 80) {
    color = '#3DB856'
  } else if (score >= 70) {
    color = '#79B048'
  } else if (score >= 60) {
    color = '#BCB751'
  } else if (score >= 50) {
    color = '#FFBD59'
  } else if (score >= 40) {
    color = '#FEA249'
  } else if (score >= 30) {
    color = '#FC8738'
  } else if (score >= 20) {
    color = '#F85117'
  } else if (score >= 10) {
    color = '#FC290C'
  } else if (score >= 0) {
    color = '#FF0000'
  }

  return (
    <div className='sites-list-ia-site-bar'>
      <div className='sites-list-ia-site-bar-left'>
        <div className='sites-list-ia-site-bar-background'></div>
        <div
          className='sites-list-ia-site-bar-foreground'
          style={{
            backgroundColor: color,
            width: (score / 100) * 140 + 'px',
          }}
        ></div>
        <div
          className='sites-list-ia-site-bar-percent'
          style={{
            left: (score / 100) * 140 + 2 + 'px',
          }}
        >
          {Math.floor(props.percent) + '%'}
        </div>
      </div>
      <div
        className='sites-list-ia-site-bar-right'
        style={{
          color: color,
          textAlign: 'right',
        }}
      >
        {props.condition.replace('Average', 'AVG').toLocaleUpperCase()}
      </div>
    </div>
  )
}
