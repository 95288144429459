import React, { useEffect, useState } from 'react'
import { UploadPanel } from '~/components/upload-panel'
import { Drawer, DrawerPosition, DrawerState, useLocation } from '~/components'
import { Sites, Site } from '../sites'
import { useAppState } from '~/state'
import { useUser } from '~/base'
import { SiteGroups, SiteGroup } from '~/sites/groups'

enum State {
  Loading,
  Loaded,
}

export const LeftDrawer = () => {
  const user = useUser()
  const { view, drawer, sites } = useAppState()
  const [state, setState] = useState(State.Loading)
  const location = useLocation()

  useEffect(() => {
    if (user.org.isBasic) {
      drawer.set(drawer.left, DrawerState.Hidden)
    }
  }, [user.org.isBasic])

  useEffect(() => {
    if (!sites.sites || !sites.groups || state === State.Loaded) {
      return
    }

    const isIA = user.isInstantAssessmentOrg()
    if(isIA) {
      setState(State.Loaded)
      return
    }

    if (sites.groups.length > 0) {
      if(!view.showingGroups && !view.siteID && !view.showingTasks && !view.showingComments && sites.canSeeGroups) {
        location.setLocation('/groups')
      } else {
        setState(State.Loaded)
      }
    } else {
      setState(State.Loaded)
    }
  }, [sites.sites, sites.groups, state, view.showingGroups])

  return (
    <Drawer state={drawer.left} position={DrawerPosition.Left}>
      {state === State.Loaded && !view.showingGroups && !view.siteID && <Sites />}
      {state === State.Loaded && !view.showingGroups && view.siteID && <Site />}
      {state === State.Loaded && view.showingGroups && !view.groupID && <SiteGroups />}
      {state === State.Loaded && view.showingGroups && view.groupID && <SiteGroup />}
      {!user.isInstantAssessmentOrg() && <UploadPanel />}
    </Drawer>
  )
}
