import React from 'react'
import { TasksIcon } from '~/tasks'
import { CommentsIcon } from '../comments'
import { Transition } from '../helpers/transition'
import { ActionHover } from '../item-tag'
import { OverviewStatsCard } from './overview-stats-card'
import { SitesQuerySiteGroupWithExtra } from '~/models'
import { useLocation } from '../helpers'
import { useUser } from '~/base'

interface OverviewCardProps {
  isDemo?: boolean
  number?: number
  title?: string
  type: 'Site' | 'Asset' | 'Group'
  numHighIssues: number
  numMediumIssues: number
  numLowIssues: number
  numAssets: number
  lastUploadTime?: number
  address?: string
  haveAddress: boolean
  canUpdate: boolean
  canRemove: boolean
  onClickEdit?: () => void
  onClickDelete?: () => void
  numComments?: number
  canListOverview: boolean
  groups?: SitesQuerySiteGroupWithExtra[]
}

export const OverviewCard = (props: OverviewCardProps) => {
  const location = useLocation()
  const user = useUser()
  const isIA = user.isInstantAssessmentOrg()

  return (
    <div className='overview-card'>
      {!props.isDemo && (
        <div className='overview-card-header'>
          {isIA && (
            <>
              <div
                className='overview-card-header-number'
                style={{
                  border: '1px solid rgba(255,255,255,0.4)',
                  borderRadius: '4px',
                  flex: '0 0 auto',
                }}
              >
                Instant Assessment
              </div>
              <div style={{ flex: 1 }}></div>
            </>
          )}
          {!isIA && (
            <div className='overview-card-header-number'>
              {props.type} {props.number}
            </div>
          )}
          <div className='overview-card-header-actions'>
            {!props.isDemo && (
              <>
                {!isIA && <TasksIcon />}
                {!isIA && <CommentsIcon count={props.numComments} />}
                {(props.canUpdate || props.canRemove) && (
                  <div className='overview-card-header-actions-container'>
                    <ActionHover
                      withContainer
                      canEdit={props.canUpdate}
                      canRemove={props.canRemove}
                      onClickEdit={props.onClickEdit}
                      onClickDelete={props.onClickDelete}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
      <div className='overview-card-title'>
        {props.title}{' '}
        {props.isDemo && <span className='overview-card-title-demo'>DEMO {props.type.toLocaleUpperCase()}</span>}
      </div>
      {props.canListOverview && (
        <OverviewStatsCard
          numHighIssues={props.numHighIssues}
          numMediumIssues={props.numMediumIssues}
          numLowIssues={props.numLowIssues}
          numAssets={props.numAssets}
          lastUploadTime={props.lastUploadTime}
          alwaysShowIssueCounts
          canListIssues={props.canListOverview}
        />
      )}
      {props.haveAddress && (
        <div className='overview-card-address'>
          <i className='material-icons'>place</i>{' '}
          <Transition width={120} in={typeof props.address !== undefined}>
            <div className='overview-card-address-address'>{props.address}</div>
          </Transition>
        </div>
      )}
      {Array.isArray(props.groups) && props.groups.length > 0 && (
        <div className='overview-card-groups'>
          <i className='material-icons'>workspaces</i>{' '}
          <div className='overview-card-groups-container'>
            {props.groups.map((g) => {
              return (
                <div
                  key={g.group.id}
                  className='overview-card-group'
                  title={g.group.name}
                  onClick={() => {
                    location.setLocation('/groups/' + g.group.id)
                  }}
                >
                  {g.group.name}
                </div>
              )
            })}
          </div>
        </div>
      )}
      <div className='overview-card-bottom-spacer'></div>
    </div>
  )
}
