import React, { useMemo } from 'react'
import { ClientPermission, useUser } from '~/base'
import { TaskTarget } from '~/models'
import { useAppState } from '~/state'

interface TasksIconProps {
  disabled?: boolean
}

export const TasksIcon = (props: TasksIconProps) => {
  const { view, sites, tasks, site } = useAppState()
  const user = useUser()
  const canSeeTasks = user.hasPermission(ClientPermission.TasksRead) && !user.org.isBasic
  if (!canSeeTasks) {
    return null
  }

  const numTasks = useMemo(() => {
    if (view.assetID) {
      return tasks.tasks.filter(
        (x) =>
          x.currentBlueprint.target === TaskTarget.Asset &&
          x.currentBlueprint.assignments.findIndex((y) => y.targetID === view.assetID) !== -1
      ).length
    } else if (view.siteID) {
      return tasks.tasks.filter(
        (x) =>
          x.currentBlueprint.assignments.findIndex(
            (y) => y.targetID === view.siteID || (site.site && site.site.surveys[site.site.surveys.length-1].assets.findIndex((a) => a.id === y.targetID) !== -1)
          ) !== -1
      ).length
    }
    return tasks.tasks.length
  }, [view, tasks, sites?.data?.sites])

  return (
    <span className='tasks-icon' onClick={() => {
      if(!props.disabled) {
        tasks.toggle()
      }
    }}>
      <i className='material-icons'>checklist</i>
      <span>{numTasks}</span>
    </span>
  )
}
