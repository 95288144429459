import React, { useState } from 'react'
import { TabTitle } from './tab-title'
import { Profile } from './profile'
import { Team } from './team'
import { ClientPermission, useUser } from '~/base'
import { Uploads } from './uploads'

enum AccountViewStateType {
  Account = 'account',
  Team = 'team',
  Uploads = 'uploads',
}

export const Account = () => {
  const user = useUser()
  const [accountView, setAccountView] = useState<AccountViewStateType>(AccountViewStateType.Account)
  const canSeeTeam = user.hasPermission(ClientPermission.TeamList)
  
  return (
    <>
      {canSeeTeam && <div className='account'>
        <ul className='account-tabs'>
          <li
            className={`account-tabs-item ${accountView === AccountViewStateType.Account ? 'active' : ''}`}
            onClick={() => setAccountView(AccountViewStateType.Account)}
          >
            Account
          </li>
          <li
            className={`account-tabs-item ${accountView === AccountViewStateType.Team ? 'active' : ''}`}
            onClick={() => setAccountView(AccountViewStateType.Team)}
          >
            Team
          </li>
          <li
            className={`account-tabs-item ${accountView === AccountViewStateType.Uploads ? 'active' : ''}`}
            onClick={() => setAccountView(AccountViewStateType.Uploads)}
          >
            Uploads
          </li>
        </ul>
      </div>}
      <TabTitle title={accountView} />
      {accountView === AccountViewStateType.Account && <Profile />}
      {accountView === AccountViewStateType.Team && <Team />}
      {accountView === AccountViewStateType.Uploads && <Uploads />}
    </>
  )
}
