import { useEffect, useState } from 'react'
import { usePollingQuery } from '~/base'
import {
  NotificationsHaveQuery,
  NotificationsHaveQueryVariables,
  NotificationsQuery,
  NotificationsQueryVariables,
} from '~/models'
import NOTIFICATIONS_QUERY from './query-notifications.gql'
import HAVE_NOTIFICATIONS_QUERY from './query-have-notifications.gql'
import { useQuery } from '~/components'
import { Config } from '~/config'

export function useNotifications() {
  const [notifications, setNotifications] = useState<NotificationsQuery['notifications']['notifications']>([])
  const notificationsHaveQuery = usePollingQuery<NotificationsHaveQuery, NotificationsHaveQueryVariables>(
    HAVE_NOTIFICATIONS_QUERY,
    {
      fetchPolicy: 'standby',
      nextFetchPolicy: 'network-only',
    }
  )

  const notificationsQuery = useQuery<NotificationsQuery, NotificationsQueryVariables>(NOTIFICATIONS_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (Config.UseNotifications) {
      notificationsHaveQuery.refetch()
    }
  }, [])

  return {
    notifications,
    reload: () => {
      return notificationsQuery.refetch().then((res) => {
        setNotifications([...(res?.data?.notifications?.notifications || [])].reverse())
      })
    },
    haveUnread:
      (notifications.length > 0 && notifications.findIndex((x) => !x.read) !== -1) ||
      notificationsHaveQuery?.data?.notificationsHave?.haveNotifications === true,
  }
}
