// Original code from TerriaJS
// https://github.com/TerriaJS/terriajs/blob/master/lib/ReactViews/Map/Navigation/Compass.jsx
import React from 'react'
import { ProjectionType } from '~/state'
import { CompassSVG } from './compass-svg'

const vectorScratch = new Cesium.Cartesian2()
const clickLocationScratch = new Cesium.Cartesian2()
const oldTransformScratch = new Cesium.Matrix4()
// const windowPositionScratch = new Cesium.Cartesian2()
// const centerScratch = new Cesium.Cartesian3()
// const newTransformScratch = new Cesium.Matrix4()
// const pickRayScratch = new Cesium.Ray()
// const nominalTotalRadius = 145
// const nominalGyroRadius = 50

interface CesiumCompassProps {
  scene: Cesium.Scene
  clock: Cesium.Clock
  projectionType: ProjectionType
  offset: boolean
  forBasic: boolean
}

interface CesiumCompassState {
  ready: boolean
  resetSpeed: number
  rotateClick: any
  unlistenFromPostRender: any
  unlistenFromClockTick: any
  orbitCursorOpacity: any
  handleRotatePointerMoveFunction: any
  handleRotatePointerUpFunction: any
  handleOrbitPointerMoveFunction: any
  handleOrbitPointerUpFunction: any
  handleOrbitTickFunction: any
  context: any
  heading: number
  orbitCursorAngle: number
}

export class CesiumCompass extends React.Component<CesiumCompassProps, CesiumCompassState> {
  constructor(props: CesiumCompassProps) {
    super(props)

    this.state = {
      ready: false,
      resetSpeed: Math.PI / 100,
      rotateClick: undefined,
      unlistenFromPostRender: null,
      unlistenFromClockTick: null,
      orbitCursorOpacity: 0,
      handleRotatePointerMoveFunction: this.handleRotatePointerMove.bind(this),
      handleRotatePointerUpFunction: this.handleRotatePointerUp.bind(this),
      handleOrbitPointerMoveFunction: this.handleOrbitPointerMove.bind(this),
      handleOrbitPointerUpFunction: this.handleOrbitPointerUp.bind(this),
      handleOrbitTickFunction: this.handleOrbitTick.bind(this),
      context: {},
      heading: 0,
      orbitCursorAngle: 0,
    }
  }

  componentDidMount() {
    if (this.props.scene && this.props.clock) {
      this.setState({
        unlistenFromPostRender: this.props.scene.postRender.addEventListener(() => {
          this.setState({
            heading: this.props.scene.camera.heading,
          })
        }),
        ready: true,
      })
    }
  }

  get outerRingStyle() {
    return {
      transform: `rotate(-${this.state.heading}rad)`,
    }
  }

  get rotationMarkerStyle() {
    return {
      transform: `rotate(-${this.state.orbitCursorAngle}rad)`,
      opacity: `${this.state.orbitCursorOpacity}`,
    }
  }

  componentWillUnmount() {
    if (this.state.unlistenFromPostRender) {
      this.state.unlistenFromPostRender()
    }
  }

  /**
   * @param {PointerEvent} event
   */
  // handlePointerDown(event: any) {
  //   const camera = this.props.scene.camera
  //   const compassElement = /** @type {HTMLDivElement} */ event.currentTarget
  //   this.context.compassRectangle = compassElement.getBoundingClientRect()
  //   this.context.compassCenter = new Cesium.Cartesian2(
  //     (this.context.compassRectangle.right - this.context.compassRectangle.left) / 2,
  //     (this.context.compassRectangle.bottom - this.context.compassRectangle.top) / 2
  //   )
  //   clickLocationScratch.x = event.clientX - this.context.compassRectangle.left
  //   clickLocationScratch.y = event.clientY - this.context.compassRectangle.top
  //   const vector = Cesium.Cartesian2.subtract(clickLocationScratch, this.context.compassCenter, vectorScratch)
  //   const distanceFromCenter = Cesium.Cartesian2.magnitude(vector)

  //   windowPositionScratch.x = this.props.scene.canvas.clientWidth / 2
  //   windowPositionScratch.y = this.props.scene.canvas.clientHeight / 2
  //   const ray = camera.getPickRay(windowPositionScratch, pickRayScratch)
  //   this.context.viewCenter = this.props.scene.globe.pick(ray, this.props.scene, centerScratch)

  //   this.context.frame = Cesium.Transforms.eastNorthUpToFixedFrame(
  //     this.context.viewCenter ? this.context.viewCenter : camera.positionWC,
  //     Cesium.Ellipsoid.WGS84,
  //     newTransformScratch
  //   )

  //   const maxDistance = this.context.compassRectangle.width / 2
  //   const distanceFraction = distanceFromCenter / maxDistance

  //   if (distanceFraction < nominalGyroRadius / nominalTotalRadius) {
  //     this.orbit(vector)
  //   } else if (distanceFraction < 1) {
  //     this.rotate(vector)
  //   }
  //   event.stopPropagation()
  //   event.preventDefault()
  // }

  /**
   * @param {Cartesian2} cursorVector
   */
  rotate(cursorVector: Cesium.Cartesian2) {
    const camera = this.props.scene.camera

    this.context.rotateInitialCursorAngle = Math.atan2(-cursorVector.y, cursorVector.x)

    const oldTransform = Cesium.Matrix4.clone(camera.transform, oldTransformScratch)

    camera.lookAtTransform(this.context.frame)
    this.context.rotateInitialCameraAngle = Math.atan2(camera.position.y, camera.position.x)
    camera.lookAtTransform(oldTransform)

    this.setState({
      rotateClick: true,
    })

    document.addEventListener('pointermove', this.state.handleRotatePointerMoveFunction, false)
    document.addEventListener('pointerup', this.state.handleRotatePointerUpFunction, false)
  }

  handleRotatePointerMove(event: MouseEvent) {
    const camera = this.props.scene.camera
    clickLocationScratch.x = event.clientX - this.context.compassRectangle.left
    clickLocationScratch.y = event.clientY - this.context.compassRectangle.top
    const vector = Cesium.Cartesian2.subtract(clickLocationScratch, this.context.compassCenter, vectorScratch)
    const angle = Math.atan2(-vector.y, vector.x)

    const angleDifference = angle - this.context.rotateInitialCursorAngle
    const newCameraAngle = Cesium.Math.zeroToTwoPi(this.context.rotateInitialCameraAngle - angleDifference)

    const oldTransform = Cesium.Matrix4.clone(camera.transform, oldTransformScratch)
    camera.lookAtTransform(this.context.frame)
    const currentCameraAngle = Math.atan2(camera.position.y, camera.position.x)
    camera.rotateRight(newCameraAngle - currentCameraAngle)
    camera.lookAtTransform(oldTransform)

    this.setState({
      rotateClick: false,
    })
  }

  handleRotatePointerUp(event: MouseEvent) {
    document.removeEventListener('pointermove', this.state.handleRotatePointerMoveFunction, false)
    document.removeEventListener('pointerup', this.state.handleRotatePointerUpFunction, false)

    if (this.state.rotateClick) {
      this.resetToNorth()
    }
  }

  resetToNorth() {
    const camera = this.props.scene.camera
    const oldTransform = Cesium.Matrix4.clone(camera.transform, oldTransformScratch)
    camera.lookAtTransform(this.context.frame)
    const newCameraAngle = Cesium.Math.negativePiToPi(
      Cesium.Math.PI_OVER_TWO + Math.atan2(camera.position.y, camera.position.x)
    )
    const duration = Math.abs(newCameraAngle) / this.state.resetSpeed

    let prevProgress = 0
    const start = performance.now()
    const step = () => {
      const elapsed = performance.now() - start
      const progress = Cesium.Math.clamp(elapsed / duration, 0, 1)

      camera.rotateLeft((progress - prevProgress) * newCameraAngle)

      prevProgress = progress
      if (progress < 1) {
        window.requestAnimationFrame(step)
      } else {
        camera.lookAtTransform(oldTransform)
      }
    }
    window.requestAnimationFrame(step)
  }

  orbit(cursorVector: Cesium.Cartesian2) {
    this.context.orbitIsLook = !this.context.viewCenter
    this.context.orbitLastTimestamp = performance.now()

    document.addEventListener('pointermove', this.state.handleOrbitPointerMoveFunction, false)
    document.addEventListener('pointerup', this.state.handleOrbitPointerUpFunction, false)

    this.setState({
      unlistenFromClockTick: this.props.clock.onTick.addEventListener(this.state.handleOrbitTickFunction),
    })

    this.updateAngleAndOpacity(cursorVector, this.context.compassRectangle.width)
  }

  handleOrbitTick() {
    const camera = this.props.scene.camera
    const timestamp = performance.now()

    const deltaT = timestamp - this.context.orbitLastTimestamp
    const rate = ((this.state.orbitCursorOpacity - 0.5) * 2.5) / 1000
    const distance = deltaT * rate

    const angle = this.state.orbitCursorAngle + Cesium.Math.PI_OVER_TWO
    const x = Math.cos(angle) * distance
    const y = Math.sin(angle) * distance

    const oldTransform = Cesium.Matrix4.clone(camera.transform, oldTransformScratch)
    camera.lookAtTransform(this.context.frame)
    if (this.context.orbitIsLook) {
      camera.look(Cesium.Cartesian3.UNIT_Z, -x)
      camera.look(camera.right, -y)
    } else {
      camera.rotateLeft(x)
      camera.rotateUp(y)
    }
    camera.lookAtTransform(oldTransform)

    this.context.orbitLastTimestamp = timestamp
  }

  /**
   * @param {Cartesian2} vector
   * @param {number} compassWidth
   */
  updateAngleAndOpacity(vector: Cesium.Cartesian2, compassWidth: number) {
    const angle = Math.atan2(-vector.y, vector.x)

    const distance = Cesium.Cartesian2.magnitude(vector)
    const maxDistance = compassWidth / 2.0
    const distanceFraction = Math.min(distance / maxDistance, 1.0)

    this.setState({
      orbitCursorAngle: Cesium.Math.zeroToTwoPi(angle - Cesium.Math.PI_OVER_TWO),
      orbitCursorOpacity: 0.5 * distanceFraction * distanceFraction + 0.5,
    })
  }

  /**
   * @param {PointerEvent} event
   */
  handleOrbitPointerMove(event: any) {
    clickLocationScratch.x = event.clientX - this.context.compassRectangle.left
    clickLocationScratch.y = event.clientY - this.context.compassRectangle.top
    const cursorVector = Cesium.Cartesian2.subtract(clickLocationScratch, this.context.compassCenter, vectorScratch)
    this.updateAngleAndOpacity(cursorVector, this.context.compassRectangle.width)
  }

  /**
   * @param {PointerEvent} event
   */
  handleOrbitPointerUp(event: any) {
    document.removeEventListener('pointermove', this.state.handleOrbitPointerMoveFunction, false)
    document.removeEventListener('pointerup', this.state.handleOrbitPointerUpFunction, false)
    this.state.unlistenFromClockTick()

    this.setState({
      orbitCursorOpacity: 0,
    })
  }

  render() {
    if (!this.state.ready || this.props.projectionType !== ProjectionType.Projection3D) {
      return null
    }

    return (
      <div
        className='compass'
        style={{
          right: this.props.offset ? '280px' : this.props.forBasic ? '20px' : '40px',
          top: this.props.forBasic ? '64px' : undefined,
        }}
      >
        <div className='outer-ring' style={this.outerRingStyle}>
          <CompassSVG />
        </div>
      </div>
    )
  }
}
